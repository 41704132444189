import MaturixStrengthUnit from '../../enums/MaturixStrengthUnit'
import MaturixTemperatureUnit from '../../enums/MaturixTemperatureUnit'
import { TagType } from '../../enums/TagType'
import { getBandTitleByTagType } from '../../utils/TagHelper'
import LanguageTreeType from '../LanguageTreeType'
import { brandName, brandShortName } from '../brand'
import countries from './countries'
import timezones from './timezones'

const ending = (x: number, single: string = '', plural: string = 's') =>
  x === 1 ? single : plural

const deliveries = (x: number) => `Livraison${ending(x)}`
const plans = (x: number) => `Plan${ending(x)}`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const workflows = (x: number) => 'Flux de travail'
const users = (x: number) => `Utilisateur${ending(x)}`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const activities = (x: number) => `Activité${ending(x)}`
const forms = (x: number) => `Formulaire${ending(x)}`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const announcements = (x: number) => `Annonce${ending(x)}`
const sensors = (x: number) => `Capteur${ending(x)}`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const closures = (x: number) => `Fermeture${ending(x)}`

const xHours = (x: number) => `${x} heure${ending(x)}`
const xUsers = (x: number) => `${x} utilisateur${ending(x)}`
const xFiles = (x: number) => `${x} fichier${ending(x)}`
const xDates = (x: number) => `${x} date${ending(x)}`
const xForms = (x: number) => `${x} Formulaire${ending(x)}`

const FrenchTree: LanguageTreeType = {
  _brandName: brandName,
  _brandShortName: brandShortName,

  avatar: 'Avatar',
  ok: 'OK',
  cancel: 'Annuler',
  open: 'Ouvrir',
  opened: 'Ouvert',
  close: 'Fermer',
  closed: 'Fermé',
  contacts: 'Contacts',
  loading: 'Chargement',
  language: 'Langue',
  notSpecified: 'Non spécifié',
  default: 'Par défaut',
  done: 'Terminé',
  save: 'Sauvegarder',
  apply: 'Appliquer',
  edit_verb: 'Éditer',
  merge_verb: 'Fusionner',
  link_verb: 'Lier',
  re_link_verb: 'Re-lier',
  unlink_verb: 'Dissocier',
  unlinking: 'Déconnexion',
  delete: 'Supprimer',
  initials: 'Initiales',
  invite: 'Inviter',
  search: 'Rechercher',
  selectAll_people: 'Sélectionner tout',
  selectAll_items: 'Sélectionner tout',
  selectNone_people: 'Ne rien sélectionner',
  selectNone_items: 'Ne rien sélectionner',
  clearAll_items: 'Effacer tout',
  clear: 'Effacer',
  uploading: 'Téléchargement',
  noResults: 'Aucun résultat',
  add: 'Ajouter',
  update_verb: 'Mettre à jour',
  updating: 'Mise à jour',
  error: 'Erreur',
  goBack: 'Retourner',
  confirm: 'Confirmer',
  select: 'Sélectionner',
  selected: 'Sélectionné',
  start_verb: 'Commencer',
  finish_verb: 'Finir',
  followers: 'Abonnés',
  view_verb: 'Voir',
  download: 'Télécharger',
  downloadingStatus: {
    starting: 'Démarrage',
    fetching: 'Récupération',
    compiling: 'Compilation',
    generating: 'Génération',
    downloading: 'Téléchargement',
  },
  searchCompany: 'Rechercher une entreprise',
  permit: 'Permis',
  permitAll: 'Permettre tout',
  thereIsNoCompanies: "Il n'y a pas d'entreprises",
  thereIsNoObjectOfSelectedType: "Il n'y a pas d'objet du type sélectionné",
  restrict: 'Restreindre',
  restrictAccess: "Restreindre l'accès",
  allCompanies: 'Toutes les entreprises',
  addCompany: 'Ajouter une entreprise',
  addHours: 'Ajouter des heures',
  addNumber: 'Ajouter un numéro',
  addDuration: 'Ajouter une durée',
  closureHistory: 'Historique de fermeture',
  addClosureDate: 'Ajouter une date de fermeture',
  addSiteAnnouncement: 'Ajouter une annonce de site',
  locationClosure: 'Fermeture de site',
  ifDeliveryHasTheFollowingTags: 'Si la livraison a les étiquettes suivantes',
  ifEntityHasTheFollowingTags: (entityName: string) =>
    `Si ${entityName} a les étiquettes suivantes`,
  thenDoAction: 'Ensuite, faire une action',
  toUserTeamRoleOrCompany: "À l'utilisateur, l'équipe, le rôle ou l'entreprise",
  addRule: 'Ajouter une règle',
  tag: 'Étiquette',
  shouldDeleteRule: 'Devrait supprimer la règle',
  searchForOrSelectTag: 'Rechercher ou sélectionner une étiquette',
  addTag: 'Ajouter une étiquette',
  addUserTeamCompany: 'Ajouter un utilisateur, une équipe, une entreprise',
  tagCreation: "Création d'étiquette",
  deliveryStatus: 'Statut de livraison',
  sendNotification: 'Envoyer une notification',
  notificationRules: 'Règles de notification',
  mention: 'Mentionner',
  gotToDirectory: 'Aller au répertoire',
  goToProjectHome: "Aller à l'accueil du projet",
  daily: 'Quotidien',
  monSat: 'Lun-Sam',
  d_daysShort: 'j',
  yesterday: 'Hier',
  today: "Aujourd'hui",
  tomorrow: 'Demain',
  loggingIn: 'Connexion',
  loggingOut: 'Déconnexion',
  loginFailed: 'Échec de la connexion',
  login: 'Connexion',
  logInWith: 'Se connecter avec',
  logo: 'Logo',
  procoreLogin: 'Connexion Procore',
  changePassword: 'Changer le mot de passe',
  forgotPassword: 'Mot de passe oublié ?',
  reset: 'Réinitialiser',
  resetPassword: 'Réinitialiser le mot de passe',
  logOut: 'Déconnexion',
  hideAll: 'Cacher tout',
  hideGroup: 'Cacher le groupe',
  hideObject: "Cacher l'objet",
  showAll: 'Montrer tout',
  showGroup: 'Montrer le groupe',
  showObject: "Montrer l'objet",
  contactHelpAtMail: (email: string) => `Contacter l'aide à ` + email,
  contactHelp: "Contacter l'aide",
  userNotFound: 'Utilisateur non trouvé',
  noUserMatchingYourSearch: 'Aucun utilisateur ne correspond à votre recherche',
  allUsersAlreadyAssigned: 'Tous les utilisateurs sont déjà affectés',
  noTagMatchingYourSearch: 'Aucune étiquette ne correspond à votre recherche',
  emailCannotBeEmpty: "L'email ne peut pas être vide",
  enterYourEmailBelowAndStruxHubWillSendAResetLink: `Entrez votre email ci-dessous et ${brandName} vous enverra un lien de réinitialisation`,
  pleaseOpenTheLinkFromTheLetterAgain:
    'Veuillez ouvrir le lien de la lettre à nouveau',
  passwordResetLinkIsSentToMailbox:
    'Le lien de réinitialisation du mot de passe est envoyé à la boîte aux lettres',
  invalidEmailError: "L'adresse email est mal formatée.",
  userNotFoundError:
    "Aucun enregistrement utilisateur ne correspond à cet identifiant. L'utilisateur a peut-être été supprimé.",
  enterANewPasswordBelow: 'Entrez un nouveau mot de passe ci-dessous',
  setYourPassword: 'Définissez votre mot de passe',
  tooManyUnsuccessfulLoginAttempts:
    'Trop de tentatives de connexion infructueuses.',
  passwordErrors: {
    weakPassword:
      'Le mot de passe doit comporter au moins 8 caractères, des majuscules et des minuscules, un chiffre et un caractère spécial (!@#$%&)',
    doNotMatch: 'Les mots de passe ne correspondent pas',
    empty: 'Le mot de passe ne peut pas être vide',
    invalid:
      "Le mot de passe est invalide ou l'utilisateur n'a pas de mot de passe",
  },
  chat: 'Chat',
  activities: 'Activités',
  deliveries: 'Livraisons',
  closures: 'Fermetures',
  sensors: 'Capteurs',
  logistics: 'Logistique',
  logisticObject: 'Objet logistique',
  site: 'Site',
  siteNews: 'Actualités du chantier',
  home: 'Accueil',
  siteLogistics: 'Logistique du site',
  materials: 'Matériaux',
  photos: 'Photos',
  analytics: 'Analyses',
  analyticsSetting: 'Configuration des analyses',
  showInReports: 'Afficher dans les rapports',
  dashboards: 'Tableaux de bord',
  reports: 'Rapports',
  filedToGenerateReport: 'Échec de la génération du rapport',
  objectProperties: "Propriétés de l'objet",
  notifications: 'Notifications',
  userSettings: 'Paramètres utilisateur',
  projectSettings: 'Paramètres du projet',
  settings: 'Paramètres',
  messages: 'Messages',
  signIn: 'Se connecter',
  signUp: "S'inscrire",
  signUpTo: "S'inscrire à",
  signedInAs: 'Connecté en tant que',
  account: 'Compte',
  profile: 'Profil',
  editProfile: 'Modifier le profil',
  notificationPreferences: 'Préférences de notification',
  administration: 'Administration',
  projectDetails: 'Détails du projet',
  integrations: 'Intégrations',
  userDirectory: 'Annuaire des utilisateurs',
  team: 'Équipe',
  teams: 'Équipes',
  defaultTeams: 'Équipes par défaut',
  presentations: 'Présentations',
  tracker: 'Suivi',
  uploadSchedule: 'Téléverser le planning',
  filterBy: 'Filtrer par',
  filter: 'Filtre',
  filters: 'Filtres',
  createNewFilter: 'Créer un nouveau filtre',
  addScheduleData: 'Ajouter des données de planning',
  editScheduleData: 'Modifier les données de planning',
  struxhubObject: `Objet ${brandName}`,
  selectObject: 'Sélectionner un objet',
  selectWorkForm: 'Sélectionner un formulaire de travail',
  hierarchies: 'Hiérarchies',
  dataToSchedule: 'Données à planifier',
  sitemaps: 'Plans de site',
  searchUserOrGroup: 'Rechercher un utilisateur ou un groupe',
  tool: 'Outil',
  workflow: 'Flux de travail',
  workflows: 'Flux de travail',
  sitemapSetup: 'Configuration du plan de site',
  templateUpdating: 'Mise à jour du modèle',
  publishTo: 'Publier sur',
  geoReferencing: 'Géo-référencement',
  tags: 'Étiquettes',
  permittedCompanies: 'Entreprises autorisées',
  associatedForms: 'Formulaires associés',
  associatedAnnouncements: 'Annonces associées',
  hoursOfOperation: "Heures d'ouverture",
  dateClosures: 'Fermetures par date',
  maxBookingDuration: 'Durée maximale de réservation',
  maxOverlappingBookings: 'Réservations chevauchantes maximales',
  object: 'Objet',
  objects: 'Objets',
  objectName: "Nom de l'objet",
  objectNestedUnder: 'Objet imbriqué sous',
  iconColor: "Couleur de l'icône",
  image: 'Image',
  groupBy: 'Regrouper par',
  basemap: 'Carte de base',
  createNewView: 'Créer une nouvelle vue',
  createNewTag: 'Créer une nouvelle étiquette',
  viewBasemap: 'Voir la carte de base',
  viewBookings: 'Voir les réservations',
  thereIsNoInstanceInTheSelectedCategory:
    "Il n'y a pas d'instance dans la catégorie sélectionnée",
  name: 'Nom',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  phoneNumber: 'Téléphone',
  globalRole: 'Rôle global',
  employeeIDNumber: `Numéro d'identification de l'employé`,
  company: 'Entreprise',
  companyDirectory: 'Annuaire des entreprises',
  common: 'Commun',
  myCompany: 'Mon entreprise',
  companyType: "Type d'entreprise",
  companyName: "Nom de l'entreprise",
  user: 'Utilisateur',
  users: 'Utilisateurs',
  userTags: 'Étiquettes utilisateur',
  allUsers: 'Tous les utilisateurs',
  role: 'Rôle',
  projectRole: 'Rôle de projet',
  projectRoles: 'Rôles de projet',
  projectTrades: 'Métiers du projet',
  address: 'Adresse',
  email_noun: 'email',
  phone: 'Téléphone',
  phoneVerification: 'Vérification téléphonique',
  call_verb: 'Appeler',
  email_verb: 'Envoyer un email',
  sendDirectMessageInStruxHub: `Envoyer un message direct dans ${brandName}`,
  password: 'Mot de passe',
  currentPassword: 'Mot de passe actuel',
  newPassword: 'Nouveau mot de passe',
  confirmNewPassword: 'Confirmer le nouveau mot de passe',
  group: 'Groupe',
  reportsTo: 'Rapporte à',
  reportOf: (type: string) => `Rapport de ${type}`,
  createReport: 'Créer un rapport',
  deliveryReport: 'Rapport de livraison',
  formReport: 'Rapport de formulaire',
  materialReport: 'Rapport de matériel',
  aReportWillBeGeneratedWithTheAboveFilters:
    'Un rapport sera généré avec les filtres ci-dessus',
  aReportWillBeSentToYourEmailShortly:
    'Un rapport sera envoyé à votre adresse email sous peu',
  reportDetails: 'Détails du rapport',
  officeField: 'Bureau/Champ',
  accountType: 'Type de compte',
  status: 'Statut',
  monitoringStatus: 'Statut de surveillance',
  castStatus: 'Statut de moulage',
  levelsToClose: 'Niveaux à fermer',
  isDockMaster: 'Est chef de quai',
  isInspector: 'Est inspecteur',
  invalidUser: 'Utilisateur invalide',
  unknownUser: 'Utilisateur inconnu',
  unknownDeletedObject: 'Objet supprimé inconnu',
  inviteStatus: "Statut d'invitation",
  inviteStatusValues: {
    Not_Invited: 'Non invité',
    Pending: 'En attente',
    Accepted: 'Accepté',
    Not_Added: 'Non ajouté',
    Added: 'Ajouté',
    Requested: 'Demandé',
    Not_Requested: 'Non demandé',
  },
  accountPosition: 'Position de compte',
  accountPositions: {
    office: 'Bureau',
    field: 'Champ',
    management: 'Gestion',
  },
  projectAccessTypeValues: {
    admin: 'Administrateur',
    member: 'Membre',
    owner: 'Propriétaire',
  },
  modalInfoSections: {
    personalInformation: 'Informations personnelles',
    companyInformation: "Informations sur l'entreprise",
    projectInformation: 'Informations sur le projet',
    additionalInformation: 'Informations supplémentaires',
    workflowPermissions: 'Permissions de flux de travail',
    companyContactInfo: "Coordonnées de l'entreprise",
    legalInfo: 'Informations légales',
    companyAddress: "Adresse de l'entreprise",
    users: 'Utilisateurs',
    qrCodeAccessAndBadges: 'Accès QR Code et badges',
    globalInfo: `Informations globales (niveau ${brandShortName})`,
  },
  projectWorkflows: {
    deliveryBooking: 'Réservation de livraison',
    deliveryInspection: 'Inspection de livraison',
    scheduleComment: 'Commentaire de planification',
    formsMaster: 'Maître des formulaires',
    scanMaster: 'Maître du scan',
    viewSchedule: 'Voir le planning',
    scheduleUpdating: 'Mettre à jour le planning',
    scheduleUploading: 'Télécharger le planning',
    scheduleNotes: 'Notes sur le planning',
  },
  permissions: {
    none: 'Aucun',
    readOnly: 'Lecture seule',
    standard: 'Standard',
    master: 'Maître',
  },
  userProfileRoleNames: {
    admin: 'Admin du site',
    owner: 'Propriétaire',
    docMaster: 'Maître des documents',
    inspector: 'Inspecteur',
    presentationUser: 'Utilisateur de présentation',
    formsMaster: 'Maître des formulaires',
    scheduleViewing: 'Voir le planning',
    scheduleUpdating: 'Mettre à jour le planning',
    scheduleUploading: 'Télécharger le planning',
    scheduleNotes: 'Notes sur le planning',
  },
  companyTypes: {
    GeneralContractorPrimary: 'Entrepreneur général (principal)',
    GeneralContractorSecondary: 'Entrepreneur général (secondaire)',
    Owner: 'Propriétaire',
    SubContractor: 'Sous-traitant',
    TieredSubContractor: 'Sous-traitant hiérarchisé',
  },
  saveProfile: 'Sauvegarder le profil',
  notificationSettings: 'Paramètres de notification',
  announcementNotifications: "Notifications d'annonces",
  deliveryNotifications: 'Notifications de livraisons',
  scheduleNotifications: 'Notifications de planification',
  trackerNotifications: 'Notifications de suivi',
  logisticsNotifications: 'Notifications de logistique',
  formsNotifications: 'Notifications de formulaires',
  formNotifications: 'Notifications de formulaire',
  scannerNotifications: 'Notifications du scanner',
  iWouldLikeToReceiveDeliveryTickets:
    'Je souhaite recevoir des tickets de livraison programmés',
  iWouldLikeReceiveMy: 'Je souhaite recevoir mes',
  inApp: "Dans l'application",
  sms: 'SMS',
  bySms: 'Par SMS',
  byEmail: 'Par email',
  successfullyUpdated: 'Mise à jour réussie',
  wrongPhoneNumberFormat: 'Format de numéro de téléphone incorrect',
  userProfileDescriptions: {
    name: `Votre nom sera utilisé lorsque vous communiquerez avec d'autres membres de l'équipe dans ${brandName}.`,
    projectRoles:
      'Votre rôle dans le projet est utilisé pour garantir que les notifications et les flux de travail auxquels vous participez sont pertinents. ' +
      'Une personnalisation supplémentaire est possible dans les Préférences dans les Paramètres.',
    notifications:
      `${brandName} vous enverra des notifications pour le travail dont vous faites partie de l'équipe. ` +
      "Ces notifications apparaissent dans l'application à côté de l'icône de la cloche en haut à droite de la page, " +
      'si vous avez entré votre numéro de téléphone dans votre profil par des messages SMS envoyés à votre téléphone, ' +
      'et certains résumés des notifications peuvent être envoyés à votre adresse email. ' +
      "Vous pouvez désélectionner l'une de ces options ci-dessous pour ne plus recevoir de notifications par ce moyen.",
  },
  whitelistedDomains: 'Domaines autorisés',
  projectAdminSummary: "Résumé de l'administrateur du projet",
  lastUpdated: 'Dernière mise à jour',
  dateByName: (date: string, name: string) => date + ' par ' + name,
  uploadProjectSchedule: 'Téléverser le planning du projet',
  fileUploaded: 'Fichier téléversé : ',
  totalActivities: 'Total des activités',
  totalUsers: 'Total des utilisateurs',
  uploadProcurementPlan: "Téléverser le plan d'approvisionnement",
  bpAssignedToCompanies: (amount: number, totalAmount: number) =>
    amount +
    ' sur ' +
    totalAmount +
    ` packages d'offres assignés aux entreprises.`,
  downloadCurrentValuesAsCsv: (toLink: (aText: string) => any) => [
    toLink('Télécharger'),
    ' les valeurs actuelles en tant que fichier csv.',
  ],
  uploadTeamList: "Téléverser la liste des membres de l'équipe",
  currentTeamMembers: (amount: number) =>
    amount + ` membres actuels de l'équipe.`,
  emptyTagTypes: (tags: TagType[]) =>
    `Certaines des balises de type requises (${tags
      .map(tag => getBandTitleByTagType(tag))
      .toString()}) sont vides pour certaines entreprises. Des valeurs par défaut seront définies pour eux.`,
  adminSummaryDescriptions: {
    projectDetails:
      "Définissez le nom et l'emplacement de votre projet afin de le retrouver facilement dans votre sélecteur de projet",
    projectSchedule: `Téléversez votre Calendrier Maître mis à jour depuis P6 aussi souvent que vous le souhaitez. Ne vous inquiétez pas — vous ne perdrez aucune donnée dans ${brandName}.`,
    procurementPlan:
      "Téléversez votre plan d'approvisionnement mis à jour à mesure que vous intégrez plus de sous-traitants au Projet " +
      "et que vous affinez vos valeurs attendues pour chaque package d'offres.",
    teamList:
      "Téléversez votre liste d'équipe de projet à mesure que de nouveaux membres rejoignent le projet. " +
      "L'inclusion d'informations optionnelles telles que l'affiliation au Département/Équipe interne facilite " +
      "l'attribution des ressources aux Activités.",
  },
  project: 'Projet',
  notificationsRulesSetupDescriptions: {
    helperText1: (entityType: string) =>
      `Une règle enverra des notifications à un ensemble d'utilisateurs pour toutes les futures ` +
      entityType +
      ` avec un ensemble d'étiquettes désignées`,
    helperText2:
      'Les étiquettes peuvent être utilisées seules ou combinées avec des opérateurs booléens. Par exemple :',
  },
  selectProject: 'Sélectionner un projet',
  selectBookingTime: 'Sélectionner un horaire de réservation',
  newProject: 'Nouveau projet',
  projectName: 'Nom du projet',
  projectCode: 'Code du projet',
  projectLocation: 'Emplacement du projet',
  projectLogo: 'Logo du projet',
  streetAddress: 'Adresse',
  city: 'Ville',
  state_region: 'État/Région',
  zipCode: 'Code postal',
  country: 'Pays',
  projectTimeSettingsAndCalendar: 'Paramètres de temps et calendrier du projet',
  QRCodes: 'Codes QR',
  scanStations: 'Stations de numérisation',
  projectAdmins: 'Administrateurs du projet',
  setTheTimeZoneForThisProject: 'Définir le fuseau horaire pour ce projet',
  projectTimeZone: 'Fuseau horaire du projet',
  dateFormat: 'Format de date',
  maturixProjectName: 'Nom du projet Maturix',
  maturixTemperatureUnit: 'Unité de température Maturix',
  maturixStrengthUnit: 'Unité de résistance Maturix',
  maturixStations: 'Stations Maturix',
  undefinedSection: 'Section non définie',
  setTheDateTimeFormatForThisProject:
    'Définir le format de date et heure pour ce projet',
  localTimezone: 'Fuseau horaire local',
  us: 'US',
  international: 'International',
  defaultWorkingDays: 'Jours de travail par défaut',
  customProjectClosures: 'Fermetures de projet personnalisées',
  holidaysAndSiteClosures: 'Jours fériés et fermetures de site',
  addCustomProjectClosure: 'Ajouter une fermeture de projet personnalisée',
  forTheFuture: "Pour l'avenir",
  projectType: 'Type de projet',
  apps: 'Applications',
  admins: 'Administrateurs',
  theListOfProjectsAdministrators: 'La liste des administrateurs du projet',
  addNew: 'Ajouter un nouvel',
  addNewAdmin: 'Ajouter un nouvel administrateur',
  addNewContact: 'Ajouter un nouveau contact',
  defaultWorkingHours: 'Heures de travail par défaut',

  struxHubHome: `${brandName} Accueil`,
  struxHubForms: `${brandName} Formulaires`,
  bookDemo: 'Réserver une démo',
  app: 'Application',
  myAndAppWithEmptySpaces: 'Mon    Application',
  showInNavigation: 'Afficher dans la navigation',
  setAsDefaultLandingPage: "Définir comme page d'accueil par défaut",
  projectDetailsDescriptions: {
    projectNameError:
      'Le nom ne doit pas être vide et doit contenir uniquement des lettres, des chiffres, des espaces, _ , -',
    projectCodeError:
      'Le code du projet ne peut pas être vide et ne doit contenir aucun caractère spécial. La longueur maximale pour le code du projet est de 5 caractères',

    changeProjectLocationWarning:
      'AVERTISSEMENT : Votre fuseau horaire de projet a été défini différemment ' +
      "du fuseau horaire à l'emplacement de votre projet",
  },
  integrationsSetupDescriptions: {
    invalidToken: 'Jeton invalide',
  },
  countryValues: countries,
  timezoneValues: timezones,
  timezoneWithOffsets: (name: string, offsets: string[]) =>
    `${name} (GMT ${offsets.join('/')})`,
  addCustomProjectClosures: 'Ajouter des fermetures de projet personnalisées',
  closureName: 'Nom de la fermeture',
  closureStartDate: 'Date de début de fermeture',
  closureEndDate: 'Date de fin de fermeture',
  loadingTeamMembers: "Chargement des membres de l'équipe",
  loadingMonitoringData: 'Chargement des données de surveillance',
  xTeamMembersSelected: (x: number) =>
    `${x} membre${ending(x)} de l'équipe sélectionné`,
  xCompaniesSelected: (x: number) =>
    `${x} entrepris${ending(x, 'e', 'es')} sélectionné`,
  importExportTeamMembers: "Importer/Exporter les membres de l'équipe",
  teamMembersDropdownStates: {
    downloadTeamMembers: "Télécharger les membres de l'équipe en format .csv",
    uploadTeamMembers: "Téléverser les membres de l'équipe en format .csv",
    viewCompanyDirectory: "Voir l'annuaire des entreprises",
    connectToProcore: "Connecter à l'annuaire des équipes Procore",
  },
  selectNotInvited: 'Sélectionner Non Invité',
  selectStatusRequested: 'Sélectionner STATUT Demandé',
  setNotInvited: 'Définir comme Non Invité',
  addNewProjectMember: 'Ajouter un nouveau membre du projet',
  uploadYourUpdatedProjectMemberListCsvFile:
    'Téléverser votre fichier csv de liste de membres du projet mis à jour.',
  orDragYourFileHere: 'Ou glissez votre fichier ici.',
  uploadYourScheduleFileInP6XerOrExcelXlsxXlsmFormat:
    'Téléversez votre fichier de planning au format P6 (.xer) ou Excel (.xlsx, .xlsm).',
  xIsUploaded: (file: string) => `${file} est téléversé`,
  sendInviteLinkToUser: "Envoyer le lien d'invitation à l'utilisateur",
  addUser: 'Ajouter un utilisateur',
  editUsers: (x: number) => `Éditer l'utilisateur${ending(x)}`,
  userValidationErrors: {
    firstNameRequired: 'Le prénom est requis',
    lastNameRequired: 'Le nom de famille est requis',
    emailRequired: "L'email est requis",
    invalidEmail: "L'email n'est pas valide",
    invalidPhone: "Le numéro de téléphone n'est pas valide",
    emailIsTaken: "L'email est déjà pris par un autre membre du projet",
    phoneIsTaken:
      'Le numéro de téléphone est déjà pris par un autre membre du projet',
    memberWithSuchEmailIsAlreadyAdded:
      'Un utilisateur avec cet email est déjà ajouté au projet',
    memberWithSuchPhoneNumberIsAlreadyAdded:
      'Un utilisateur avec ce numéro de téléphone est déjà ajouté au projet',
    companyRequired: "L'entreprise est requise",
  },
  companyValidationErrors: {
    nameRequired: "Le nom de l'entreprise est requis",
    emailRequired: "L'email professionnel est requis",
    invalidEmail: "L'email n'est pas valide",
    invalidPhone: "Le numéro de téléphone professionnel n'est pas valide",
    emailIsTaken: "L'email est déjà pris par une autre entreprise",
    somethingFilledIncorrectly: 'Quelque chose est mal rempli',
    companyTypeIsRequired: "Le type d'entreprise est requis",
    codeRequired: 'Le code est requis',
    codeIncorrectLength:
      'La longueur du code doit être comprise entre 3 et 5 symboles',
    codeIsNotUnique:
      'Une entreprise avec ce code existe déjà, changez la valeur du champ de code pour le rendre unique',
  },
  companyLinkingErrors: {
    anErrorOccurredWhileLinking: `Une erreur s'est produite lors de la liaison`,
    anErrorOccurredWhileUnlinking: `Une erreur s'est produite lors de la dissociation`,
    anErrorOccurredWhileFetchingGlobalStorage: `Une erreur s'est produite lors de la récupération du stockage global`,
  },
  companyMerging: {
    confirmation: (sourceCompaniesText: string, targetCompanyText: string) =>
      `Confirmez-vous la fusion de ${sourceCompaniesText} dans ${targetCompanyText}?`,
  },
  somethingWentWrongDuringAPIInteraction:
    "Quelque chose s'est mal passé pendant l'interaction API",
  confirmDeactivationOfTheFollowingXCompanies: (x: number) =>
    `Confirmer la désactivation des entreprises suivantes : ${x} entrepris${ending(
      x,
      'e',
      'es',
    )} ?`,
  confirmActivationOfTheFollowingXCompanies: (x: number) =>
    `Confirmer l'activation des entreprises suivantes : ${x} entrepris${ending(
      x,
      'e',
      'es',
    )} ?`,
  confirmDeletionOfTheFollowingXAnnouncements: (x: number) =>
    `Confirmer la suppression des annonces suivantes : ${x} annonc${ending(
      x,
    )} ?`,
  confirmDeletionOfTheFollowingXProjectMembers: (x: number) =>
    `Confirmer la suppression des membres du projet suivants : ${x} membr${ending(
      x,
    )} du projet ?`,
  confirmInvitationOfTheFollowingXProjectMembers: (x: number) =>
    `Confirmer l'invitation des membres du projet suivants : ${x} membr${ending(
      x,
    )} du projet ?`,
  confirmSettingAsNotInvitedOfTheFollowingXProjectMembers: (x: number) =>
    `Confirmer la définition comme non invité des membres du projet suivants : ${x} membr${ending(
      x,
    )} du projet ?`,
  confirmInvitation: "Confirmer l'invitation",
  confirmSettingUsersAsNotInvited:
    'Confirmer la définition des utilisateurs comme non invités',
  confirmDeletion: 'Confirmer la suppression',
  confirmActivation: "Confirmer l'activation",
  confirmDeactivation: 'Confirmer la désactivation',
  confirmDeletionOfTheFollowingXPlannedManpower: (x: number) =>
    `Confirmer la suppression de la main-d'œuvre planifiée suivante : ${x} main-d'œuvre planifié${ending(
      x,
    )} ?`,
  confirmDeletionOfTheFollowingXBidPackages: (x: number) =>
    `Confirmer la suppression des packages d'offres suivants : ${x} packag${ending(
      x,
    )} d'offres ?`,
  confirmDeletionOfActivities: (x: number) =>
    `Êtes-vous sûr de vouloir supprimer ${x} activité${ending(x, 'é', 'és')} ?`,
  selectProcoreProject: 'Sélectionner un projet Procore',
  selectProcoreCompany: 'Sélectionner une entreprise Procore',
  noUnit: `Aucune d'unité`,
  noCompanies: 'Aucune entreprise',
  noDirectoryAccess: "Aucun accès à l'annuaire",
  xUsers,
  xUsersShowing: (x: number) => `${x} utilisateurs affiché${ending(x)}`,
  xTeams: (x: number) => `${x} équipe${ending(x)}`,
  addXUsers: (x: number) => {
    if (!x) {
      return 'Ajouter'
    }
    // 'Ajouter 1 utilisateur' ou 'Ajouter x utilisateurs'
    return `Ajouter ${x} utilisateur${ending(x)}`
  },
  xFiles,
  multiLevelScreen: 'Écran multi-niveaux',
  levelsViewsWithZoneSummary: 'Vues des niveaux avec résumé de zone',
  levelsViewsWithCompanySummary: "Vues des niveaux avec résumé d'entreprise",
  gantt: 'Gantt',
  presentationMode: 'Mode présentation',
  play: 'Lecture',
  stop: 'Arrêt',
  turnOn: 'Activer',
  presentationScreens: 'Écrans de présentation',
  xMinutes: (x: number) => `${x} minute${ending(x)}`,
  order_noun: 'Ordre',
  order_number: (x: number) => {
    switch (x) {
      case 1:
        return '1er'
      case 2:
        return '2ème'
      case 3:
        return '3ème'
      default:
        return `${x}ème`
    }
  },
  display: 'Afficher',
  presentationModeDescriptions: {
    logisticsEntity: 'Annonces ouvertes',
    multiLevelScreen:
      `Affiche l'activité et les performances de la main-d'œuvre par rapport aux réels par rapport aux prévisions ` +
      'pour tous les niveaux du bâtiment. Fait défiler pour afficher tous les niveaux',
    levelsViewsWithZoneSummary:
      'Parcourt chaque niveau du bâtiment en affichant' +
      `l'activité globale et la performance de la main-d'œuvre pour chaque zone.` +
      `Défile pour afficher les données de chaque zone.`,
    levelsViewsWithCompanySummary:
      'Parcourt chaque niveau du bâtiment en affichant ' +
      `la performance globale de l'entreprise. Fait défiler pour afficher les données de chaque entreprise.`,
    gantt:
      `Affiche la vue Gantt du projet pour l'anticipation actuelle. ` +
      `Défile pour afficher toutes les activités. `,
    deliveries:
      'Affiche le calendrier hebdomadaire des livraisons pour la semaine en cours.',
    logistics: 'Affiche la carte logistique.',
    confirmText:
      'Le mode Présentation vous déconnectera et configurera cet appareil pour la lecture. À' +
      'Quittez le mode présentation, allez dans Paramètres. Vous devrez vous reconnecter une fois que vous aurez ' +
      'quitter le mode présentation.',
    header:
      `L'activation du mode Présentation vous déconnectera et connectera cet appareil ` +
      `en mode présentation uniquement.`,
    periodOfInactivity: `La présentation s'exécutera automatiquement après une période d'inactivité de`,
    stop:
      `L'arrêt du mode présentation vous ramènera à un écran de connexion ` +
      'où vous pouvez vous connecter à vos projets.',
  },
  include: 'Inclure',
  formConfiguration: 'Configuration du formulaire',
  saveConfiguration: 'Sauvegarder la configuration',
  preTaskChecklist: 'Liste de vérification pré-tâche',
  requireDailyInspections: 'Exiger des inspections quotidiennes',
  inspectionFrequency: "Fréquence d'inspection",
  deadLineForRecordingAnInspection:
    "Date limite pour l'enregistrement d'une inspection",
  checklist: 'Liste de contrôle',
  instructions: 'Instructions',
  xOptions: (x: number) => `${x} option${ending(x)}`,
  NA: 'N/D',
  remove: 'Retirer',
  typeOfList: 'Type de liste',
  addAdditionalItem: 'Ajouter un élément supplémentaire',
  enterInstruction: "Entrer l'instruction",
  enterTheXDigitCodeSentTo: (x: number) =>
    `Entrez le code à ${x} chiffres envoyé à`,
  areYouSureWantToDeleteInstructionItem:
    "Êtes-vous sûr de vouloir supprimer l'élément d'instruction ?",
  instructionListTypes: {
    enumerated: 'Énuméré',
    bulleted: 'À puces',
    none: 'Aucun',
  },
  fieldStates: {
    mandatory: 'Obligatoire',
    optional: 'Facultatif',
    hidden: 'Caché',
  },
  revertingProjectData: 'Restauration des données du projet',
  loadingUpdatingProject: 'Chargement/Mise à jour du projet',
  downloadProjectDataCsv: 'Télécharger les données du projet en format .csv',
  exploreYourScheduleInStruxHub: `Explorez votre planning dans ${brandName}`,
  scheduleIsNotUploaded: "Le planning n'est pas téléchargé",
  gettingXerProjects: 'Récupération des projets xer',
  gettingExcelSheets: 'Récupération des feuilles Excel',
  parsingTheScheduleFileStarted: "Début de l'analyse du fichier de planning",
  readingScheduleFile: 'Lecture du fichier de planning',
  extractingScheduleData: 'Extraction des données de planning',
  xEntriesInTheFile: (x: number) =>
    `${x} entrée${ending(x, 'e', 'es')} dans le fichier`,
  savingScheduleDataCollections:
    'Sauvegarde des collections de données de planning',
  xOfYEntriesSaved: (x: number, y: number) =>
    `${x} sur ${y} entrée${ending(y, 'e', 'es')} sauvegardée${ending(
      y,
      'e',
      'es',
    )}`,
  recalculatingStatusUpdates: 'Recalcul des mises à jour de statut',
  savingSchedule: 'Sauvegarde du planning',
  uploadingScheduleFailedAt: 'Échec du téléversement du planning à',
  uploadingScheduleDone: 'Téléversement du planning terminé',
  seeGanttInStruxHub: `Voir le Gantt dans ${brandName}`,
  noActivitiesFound: 'Aucune activité trouvée',
  continueToNextStep: "Continuer à l'étape suivante",
  thereAreNoProjectsInProvidedFile:
    "Il n'y a pas de projets dans le fichier fourni",
  thereAreNoInstancesToDisplay: (instancesName: string = 'Instances') =>
    `Il n'y a pas de ${instancesName.toLowerCase()} à afficher`,
  scheduleUpdateSummary: 'Résumé de la mise à jour du planning',
  current: 'Actuel',
  upload_noun: 'Téléversement',
  fileName: 'Nom du fichier',
  dateUploaded: 'Date de téléversement',
  uploadScheduleDescriptions: {
    warning: (delta: number) =>
      `Les ${delta} ${activities(delta)} qui ${ending(
        delta,
        'est',
        'sont',
      )} absente${ending(
        delta,
        's',
        's',
      )} dans le planning mis à jour seront retirée${ending(
        delta,
        's',
        's',
      )} de l'affichage. Tout le contenu associé à ce${ending(
        delta,
        's',
        's',
      )} ${delta} ${activities(
        delta,
      )} sera supprimé de ${brandName} également.`,
    chooseProject:
      "Le fichier xer P6 actuel contient des données de plusieurs projets. Veuillez sélectionner le projet à importer. Les données de ce projet doivent correspondre à celles contenues dans d'autres sources (par exemple, le plan d'approvisionnement, la liste des membres de l'équipe).",
    chooseSheet:
      'Les fichiers actuels contiennent plusieurs feuilles. Veuillez sélectionner la feuille à importer.',
    mergeData: 'Mettre à jour le planning actuel.',
    mapFields:
      'Veuillez sélectionner quel champ sera utilisé pour chaque colonne',
  },
  activityId: "ID d'activité",
  activityUniqueId: "ID unique de l'activité",
  activityName: "Nom de l'activité",
  activityCode: "Code d'activité",
  activityStatus: "Statut de l'activité",
  activityPercentComplete: "Pourcentage d'achèvement de l'activité",
  plannedActivityStart: "Début d'activité prévu",
  plannedActivityFinish: "Fin d'activité prévue",
  actualActivityStart: "Début d'activité réel",
  actualActivityFinish: "Fin d'activité réelle",
  resourceId: 'ID de ressource',
  resourceName: 'Nom de la ressource',
  trade: 'Métier',
  assignee: 'Assigné',
  primarySafetyHazard: 'Risque de sécurité primaire',
  secondarySafetyHazard: 'Risque de sécurité secondaire',
  categoryOfVariance: 'Catégorie de variance',
  categoryOfVarianceOpened: 'Catégorie de variance ouverte',
  activityCodeTypes: "Types de codes d'activité",
  resources: 'Ressources',
  uploadAnother: 'Téléverser un autre',
  next: 'Suivant',
  saveAndViewUpdatedGantt: 'Sauvegarder et voir le Gantt mis à jour',
  lastUpload: 'Dernier téléversement',
  companies: 'Entreprises',
  targetCompany: 'Entreprise cible',
  activitiesWithTheFollowingActivityCodesAreExcluded:
    "Les activités avec les codes d'activité suivants sont exclues",
  activityCodesSelected: "Codes d'activité sélectionnés",
  scheduleRestoringConfirmation: 'Confirmation de restauration du planning',
  doYouConfirmRestoringFileFromDate: (file: string, wrappedDate) => [
    `Confirmez-vous la restauration de ${file} à partir de `,
    wrappedDate,
    '?',
  ],
  doYouWantToSaveChanges: 'Voulez-vous sauvegarder les modifications ?',
  doYouAlreadyHaveAnAccount: 'Avez-vous déjà un compte ?',

  uploadHistory: 'Historique de téléversement',
  restore: 'Restaurer',
  chooseProject: 'Choisir le projet',
  chooseSheet: 'Choisir la feuille',
  mapFields: 'Mapper les champs',
  xActivities: (x: number) => `${x} ${activities(x)}`,
  xHours,
  xLocations: (x: number) => `${x} Emplacement${ending(x)}`,
  xColumns: (x: number) => `${x} Colonne${ending(x)}`,
  cancelUpload: 'Annuler le téléversement',
  selectProjectMembersLinkedToX: (x: string, isPlural: boolean) =>
    `Sélectionner le${isPlural ? 's' : ''} membre${
      isPlural ? 's' : ''
    } du projet lié${isPlural ? 's' : ''} à ${x}`,
  selectActivityCodesLinkedToX: (x: string, isPlural: boolean) =>
    `Sélectionner le${isPlural ? 's' : ''} code${
      isPlural ? 's' : ''
    } d'activité lié${isPlural ? 's' : ''} à ${x}`,
  selectionOf1ProjectMemberIsPermitted:
    "La sélection d'un membre du projet est autorisée.",
  selectionOf1ActivityCodeIsPermitted:
    "La sélection d'un code d'activité est autorisée.",
  multipleSelectionsArePermitted: 'Les sélections multiples sont autorisées.',
  excludeSectionsOfTheScheduleOptional:
    'Exclure des sections du planning (facultatif)',
  activityFilterDialogDescriptions: {
    hiddenCodes:
      "Si vous souhaitez masquer certaines sections de votre planning, cochez les codes d'activité correspondant aux activités que vous souhaitez masquer.",
  },
  only: 'Seulement',
  noCompany: 'Aucune entreprise',
  noMessage: 'Aucun message',
  submit_verb: 'Soumettre',
  showAllXActivities: (x: number) =>
    `Afficher toutes les ${x} ${activities(x)}`,
  showXOfYActivities: (x: number, y: number) =>
    `Afficher ${x} sur ${y} ${activities(x)}`,
  verification: 'Vérification',
  xActivitiesAdded: (x: number, wrappedX) => [
    wrappedX,
    ` ${activities(x)} ajoutée${ending(x, '', 's')}.`,
  ],
  seeDetails: 'Voir les détails',
  xTypeActivityCodesAdded: (x: number, wrappedX, type: string) => [
    wrappedX,
    `${type} code d'activité${ending(x)} ajouté${ending(x, '', 's')}.`,
  ],
  activityFilters: "Filtres d'activité",
  scheduleFilters: 'Filtres de planning',
  customFilter: 'Filtre personnalisé',
  filterNameCannotBeEmpty: 'Le nom du filtre ne peut pas être vide',
  suchFilterNameIsAlreadyTaken: 'Ce nom de filtre est déjà utilisé',
  struxHubData: `${brandName} Données`,
  scheduleData: 'Données de planning',
  members: 'Membres',
  projectMembers: 'Membres du projet',
  activityCodes: "Codes d'activité",
  projectMembersSelected: 'Membres du projet sélectionnés',
  renameFilterOptional: 'Renommer le filtre (facultatif)',
  filterName: 'Nom du filtre',
  xCompaniesIdentified: (x: number) =>
    `${x} Entreprise${ending(x, '', 's')} identifiée${ending(x, '', 's')}`,
  xCompaniesShowing: (x: number) =>
    `${x} Entreprise${ending(x, '', 's')} affichée${ending(x, '', 's')}`,
  xCompanies: (x: number) => `${x} entrepris${ending(x, 'e', 'es')}`,
  activityCodeSelected: "Code d'activité sélectionné",
  responsibleUser: 'Utilisateur responsable',
  responsibilityCode: 'Code de responsabilité',
  responsibilityDescription: 'Description de la responsabilité',
  contractN: 'Contrat n°',
  plannedValueS: 'Valeur prévue ($)',
  uploadAnUpdatedXlsxFile: 'Téléverser un fichier xlsx mis à jour',
  downloadCurrentListAsXlsxFile: 'Télécharger la liste actuelle au format xlsx',
  addNewEntry: 'Ajouter une nouvelle entrée',
  addNewUser: 'Ajouter un nouvel utilisateur',
  previous: 'Précédent',
  activityFiltersDescriptions: {
    confirmDeletionOfX: (x: string) =>
      `Êtes-vous sûr de vouloir supprimer le filtre ${x} ?`,
    title: `${brandName} transforme vos codes d'activité et vos données P6 en filtres pratiques pour que vos équipes puissent facilement trouver leurs activités sur le web et sur mobile. Vous pouvez configurer ces filtres ci-dessous et les mettre à jour à tout moment.`,
    selectProjectMembers:
      'Sélectionnez le(s) membre(s) du projet que vous souhaitez inclure dans ce filtre. Les sélections multiples sont permises.',
    selectActivityCodes:
      "Sélectionnez le(s) code(s) d'activité que vous souhaitez inclure dans ce filtre. Les sélections multiples sont permises.",
    company:
      'Ce filtre permet aux utilisateurs de sélectionner les entreprises responsables des activités de votre projet.',
    level:
      "Ce filtre permet aux utilisateurs de sélectionner les niveaux d'élévation de votre bâtiment.",
    zone: 'Ce filtre permet aux utilisateurs de sélectionner les différentes zones de chaque niveau du bâtiment.',
    activityCode:
      "Ce filtre permet aux utilisateurs de sélectionner des codes supplémentaires d'activités que vous souhaitez suivre.",
    projectMember:
      'Ce filtre permet aux utilisateurs de sélectionner les membres du projet que vous souhaitez suivre.',
    associateCompanies:
      'Veuillez indiquer comment vous associez les entreprises à vos activités dans le planning.',
    resourceCompanies:
      'Les noms des entreprises se trouvent dans le champ Ressource du fichier .xer. Note : ceci est la valeur par défaut si vous avez un planning chargé de ressources.',
    bpCompanies:
      "Les noms des entreprises se trouvent dans un fichier xlsx séparé et associé aux activités via un code d'activité.",
    bpStep1:
      "Sélectionnez le type de code d'activité dans votre fichier .xer qui est associé aux entreprises responsables.",
    bpStep2:
      'Téléversez un fichier Excel contenant au moins les deux en-têtes de colonne suivants : Code de responsabilité et Entreprise.',
  },
  trackingHierarchy: 'Hiérarchie de suivi',
  defaultHierarchies: 'Hiérarchies par défaut',
  defineBandsEnPermissionsForYourTrackingHierarchy:
    'Définissez les bandes et les permissions pour votre hiérarchie de suivi',
  setupDefaultHierarchiesForDifferentViews:
    'Configurez les hiérarchies par défaut pour différentes vues',
  selectBands: 'Sélectionner les bandes',
  selectControlBand: 'Sélectionner la bande de contrôle',
  band: 'Bande',
  addBand: 'Ajouter une bande',
  activityUpdatesOn: 'Mises à jour des activités activées',
  activityUpdatesOff: 'Mises à jour des activités désactivées',
  view_noun: 'Vue',
  wbs: 'Structure de découpage du travail',
  webGantt: 'Gantt Web',
  webSitemap: 'Plan du site Web',
  mobile: 'Mobile',
  tablet: 'Tablette',
  hierarchyPreview: 'Aperçu de la hiérarchie',
  unassigned: 'Non assigné',
  photo: 'Photo',
  completed: 'Terminé',
  startEarly: 'Commencer tôt',
  PComplete: '% Complet',
  unassignedActivity: 'Activité non assignée',
  trackingHierarchyDescriptions: {
    title:
      'Configurez votre hiérarchie de suivi ci-dessous pour commencer à suivre votre planning par vos équipes de terrain, par vos sous-traitants, ou par les deux.',
  },
  editCompany: "Éditer l'entreprise",
  addDataToSchedule: 'Ajouter des données au planning',
  plannedCompanyManpower: "Main-d'œuvre prévue de l'entreprise",
  dataToScheduleDescriptions: {
    title: `Avec ${brandName}, vous pouvez rapidement ajouter des données à votre planning en important des données dans des fichiers Excel.`,
  },
  formField: 'Champ de formulaire',
  showField_question: 'Afficher le champ ?',
  mandatory_question: 'Obligatoire ?',
  fieldShown: 'Champ affiché',
  mandatory: 'Obligatoire',
  addNewListElement: 'Ajouter un nouvel élément de liste',
  formPreview: 'Aperçu du formulaire',
  setThresholdForDDays: 'Définir le seuil pour ∆ Jours',
  setThresholdForDManpower: "Définir le seuil pour ∆ Main-d'œuvre",
  onTrack: 'Sur la bonne voie',
  watch: 'Surveiller',
  behind: 'En retard',
  dDays: '∆ Jours',
  days: 'Jours',
  percent: 'Pourcent',
  number: 'Nombre',
  daysBehind: 'Jours de retard',
  daysAhead: "Jours d'avance",
  XBehind: '% en retard',
  XAhead: '% en avance',
  under: 'Sous',
  over: 'Sur',
  XUnder: '% sous',
  XOver: '% sur',
  dManpower: "∆ Main-d'œuvre",
  sitemapsDescriptions: {
    title: `Avec ${brandName}, vous pouvez facilement lier votre planning à vos plans de site. Ci-dessous, vous pouvez configurer les valeurs seuils pour les métriques ∆ Jours et ∆ Main-d'œuvre qui sont utilisées pour visualiser les progrès sur le planning et la main-d'œuvre actuelle, respectivement.`,
    configure:
      "Configurer les seuils de statut pour le planning et la main-d'œuvre",
  },
  inbox: 'Boîte de réception',
  unread_2form: 'Non lu',
  read_2form: 'Lu',
  archived: 'Archivé',
  requested: 'Demandé',
  changed: 'Modifié',
  scheduled: 'Planifié',
  onSite: 'Sur site',
  denied: 'Refusé',
  passedInspection: 'Inspection réussie',
  failedInspection: 'Inspection échouée',
  done_plural: 'Fait',
  flags: 'Drapeaux',
  formSubmitted: 'Formulaire soumis',
  formAccepted: 'Formulaire accepté',
  formFinished: 'Formulaire terminé',
  formClosed: 'Formulaire fermé',
  formChanged: 'Formulaire modifié',
  formDenied: 'Formulaire refusé',
  formReadyToInspect: 'Formulaire prêt à être inspecté',
  formActivated: 'Formulaire activé',
  formReviewed: 'Formulaire révisé',
  formOnSite: 'Formulaire sur place',
  formFailed: 'Formulaire échoué',
  formPassed: 'Formulaire passé',
  formBicInspected: 'Formulaire BIC inspecté',
  formDeleted: 'Formulaire supprimé',
  formFollowed: 'Formulaire suivi',
  rfis: 'RFIs',
  scheduleComments: 'Commentaires sur le planning',
  onTime: "À l'heure",
  late: 'En retard',
  performance: 'Performance',
  parameters: 'Paramètres',
  onTimeWindowHr: "Fenêtre à l'heure (hr)",
  showLess: 'Afficher moins',
  showMore: 'Afficher plus',
  all_items: 'Tout',
  none: 'Aucun',
  unArchive: 'Désarchiver',
  archive: 'Archiver',
  read: 'Lu',
  unread: 'Non lu',
  wwpColoring: 'Coloration WWP',
  useCSIMaterialCategories: 'Utiliser les catégories de matériaux CSI', // Construction Specifications Institute
  noNotificationsPassedFilters: "Aucune notification n'a passé les filtres",
  replyToActivityFlag: "Répondre au drapeau d'activité",
  activityFlag: "Drapeau d'activité",
  replyToActivityRfi: "Répondre au RFI d'activité",
  activityRfi: "RFI d'activité",
  replyToScheduleComment: 'Répondre au commentaire sur le planning',
  replyToCategoryOfVariance: 'Répondre à la catégorie de variance',
  replyToSafetyHazard: 'Répondre au risque de sécurité',
  scheduleComment: 'Commentaire sur le planning',
  activityUpdate: "Mise à jour d'activité",
  scheduleUpdate: 'Mise à jour du planning',
  safetyHazard: 'Risque de sécurité',
  safetyHazardOpened: 'Risque de sécurité ouvert',
  bulkEdit: 'Édition en masse',
  bulkUpdate: 'Mise à jour en masse',
  replyToActivityUpdate: "Répondre à la mise à jour d'activité",
  activityFollowed: 'Activité suivie',
  deliveryFollowed: 'Livraison suivie',
  replyToScheduleUpdate: 'Répondre à la mise à jour du planning',
  replyToBulkUpdate: 'Répondre à la mise à jour en masse',
  deliveryRequested: 'Livraison demandée',
  deliveryRequestedByLocationClosure: (id: string) =>
    `La livraison (${id}) a été affectée par une fermeture de lieu et doit être reprogrammée`,
  deliveryScheduled: 'Livraison planifiée',
  deliveryOnSite: 'Livraison sur site',
  deliveryPassedInspection: 'Livraison passée inspection',
  deliveryFailedInspection: 'Livraison échouée inspection',
  deliveryInspected: 'Livraison inspectée',
  deliveryDone: 'Livraison effectuée',
  deliveryIncompletelyDone: 'Livraison incomplètement effectuée',
  deliveryUpdated: 'Livraison mise à jour',
  deliveryDeleted: 'Livraison supprimée',
  deliveryDeletedBy: (id: string, user: string, company: string) =>
    `La livraison (${id}) a été supprimée par ${user} chez ${company}`,
  deliveryDenied: 'Livraison refusée',
  deliveryCanceled: 'Livraison annulée',
  deliveryOnHold: 'Livraison en attente',
  deliveryPaused: 'Livraison en pause',
  updateMaterials: 'Mettre à jour les matériaux',
  materialsLoading: 'Chargement des matériaux',
  xMaterialsShowing: (count: number) => `${count} matériaux affichés`,
  planned: 'Planifié',
  actual: 'Réel',
  scannerDeactivated: 'Scanner désactivé',
  scheduleUpdated: 'Planning mis à jour',
  passed: 'Réussi',
  failed: 'Échoué',
  scheduling: 'Planification',
  schedule: 'Planning',
  schedule_noun: 'Planning',
  pass_verb: 'Réussir',
  deny_verb: 'Refuser',
  change_verb: 'Modifier',
  accept: 'Accepter',
  fail: 'Échouer',
  incompletelyDone: 'Incomplètement terminé',
  deleted: 'Supprimé',
  deactivated: 'Désactivé',
  activate: 'Activer',
  deactivate: 'Désactiver',
  canceled: 'Annulé',
  siteNotReady: 'Site non prêt',
  materialProblem: 'Problème de matériel',
  laborIssue: "Problème de main-d'œuvre",
  qualityIssue: 'Problème de qualité',
  clarificationNeeded: 'Clarification nécessaire',
  incorrectSpecification: 'Spécification incorrecte',
  materialQuestion: 'Question sur le matériel',
  superintendentNeeded: 'Surintendant nécessaire',
  plusXMore: (x: number) => `+ ${x} de plus`,
  submittalInReview: 'Soumission en cours de révision',
  submittalComplete: 'Soumission complète',
  inFabrication: 'En fabrication',
  fabricationComplete: 'Fabrication terminée',
  inStorage: 'En stockage',
  installed: 'Installé',
  delivery: 'Livraison',
  newRequest: 'Nouvelle demande',
  deleteDelivery: 'Supprimer la livraison',
  yesDeleteDelivery: 'Oui, supprimer la livraison',
  cancelDelivery: 'Annuler la livraison',
  yesCancelDelivery: 'Oui, annuler la livraison',
  changeDate: 'Changer la date',
  yesMoveTheDate: 'Oui, déplacer la date',
  deliveryRequestIsNotValid: "La demande de livraison n'est pas valide",
  datePassedDeadline: 'La date a dépassé la date limite',
  requestAnyway: 'Demander quand même',
  requestAccessTo: "Demander l'accès à",
  thisRequestHasPassedTheDeadline: 'Cette demande a dépassé la date limite',
  noDeadline: 'Aucune date limite',
  twentyFourHours: '24 heures',
  fortyEightHours: '48 heures',
  seventyTwoHours: '72 heures',
  fourDays: '4 jours',
  fiveDays: '5 jours',
  oneWeek: '1 semaine',
  twoWeeks: '2 semaines',
  dateAndTime: 'Date et heure',
  startDate: 'Date de début',
  finishDate: 'Date de fin',
  addFinishDate: 'Ajouter une date de fin',
  removeFinish: 'Retirer la fin',
  startTime: 'Heure de début',
  endTime: 'Heure de fin',
  selectTime: "Sélectionner l'heure",
  location: 'Emplacement',
  locationFrom: 'Emplacement de départ',
  locationTo: "Emplacement d'arrivée",
  formType: 'Type de formulaire',
  contact: 'Contact',
  offloadingEquipment: 'Équipement de déchargement',
  vehicles: 'Véhicules',
  material: 'Matériel',
  materialDeliveryLocation: 'Lieu de livraison du matériel',
  materialsCategory: 'Catégorie de matériaux',
  inspectionRequired: 'Inspection requise',
  supplierAndDriver: 'Fournisseur et conducteur',
  anySpecialInstructions: 'Instructions spéciales ?',
  deliverySitemap: 'Plan du site de livraison',
  gate: 'Porte',
  area: 'Zone',
  fromToDelivery: 'Livraison de A à Z',
  workArea: 'Zone de travail',
  installationZone: "Zone d'installation",
  deliveryVehicleType: 'Type de véhicule de livraison',
  numberOfVehicles: 'Nombre de véhicules',
  numberOfVehicleLicensePlate: "Modèle de véhicule et plaque d'immatriculation",
  equipment: 'Équipement',
  sharedResources: 'Ressources partagées',
  units: 'Unités',
  inspector: 'Inspecteur',
  vendorEmails: 'emails des fournisseurs',
  driverMobilePhoneNumbers: 'Numéros de téléphone mobile des conducteurs',
  specialInstructions: 'Instructions spéciales',
  linkedActivity: 'Activité liée',
  customActionButton: "Bouton d'action personnalisé",
  building: 'Bâtiment',
  route: 'Itinéraire',
  staging: 'Mise en scène',
  interiorPath: 'Chemin intérieur',
  interiorDoor: 'Porte intérieure',
  bookingDate: 'Date de réservation',
  requesterCompany: 'Entreprise demanderesse',
  duration: 'Durée',
  onsiteContact: 'Contact sur site',
  onsiteContacts: 'Contacts sur site',
  category: 'Catégorie',
  materialNote: 'Note sur le matériel',
  unnamedMaterial: 'Matériel non nommé',
  required: 'Requis',
  optional: 'Facultatif',
  multipleSelection: 'Sélection multiple',
  adding: 'Ajout',
  findOrAddNewEntry: 'Trouver ou ajouter une nouvelle entrée',
  findEntry: 'Trouver une entrée',
  yes: 'Oui',
  no: 'Non',
  unscheduledDelivery: 'Livraison non planifiée',
  uploadASitemapToShowGatesAndAreas:
    'Téléverser un plan pour montrer les portes et les zones',
  uploadAViewToShowGatesAndAreas:
    'Téléverser une vue pour montrer les portes et les zones',
  uploadASitemapToShowBuilding: 'Téléverser un plan pour montrer le bâtiment',
  buildingAccessMap: "Plan d'accès au bâtiment",
  uploadASitemapToShowGates: 'Téléverser un plan pour montrer les portes',
  gateAccessMap: "Plan d'accès aux portes",
  uploadASitemapToShowEquipment: "Téléverser un plan pour montrer l'équipement",
  equipmentAccessMap: "Plan d'accès à l'équipement",
  zoneAccessMap: "Plan d'accès aux zones",
  uploadASitemapToShowAreas: 'Téléverser un plan pour montrer les zones',
  routeAccessMap: "Plan d'accès à l'itinéraire",
  uploadASitemapToShowRoute: "Téléverser un plan pour montrer l'itinéraire",
  deliveryWorkflow: 'Flux de travail de livraison',
  workflowMenu: 'Menu de flux de travail',
  addANoteToThisDelivery: 'Ajouter une note à cette livraison',
  send: 'Envoyer',
  deliveryDescriptions: {
    confirmDelete:
      'La suppression de cette livraison supprimera la livraison et toutes les notes et données associées du projet.',
    confirmDelete2:
      'Faites cela uniquement si la demande de livraison était une erreur.',
    confirmCancel:
      "Les livraisons annulées resteront sur le calendrier mais seront marquées comme 'Annulées'.",
    confirmCancel2: 'Souhaitez-vous annuler cette livraison ?',
    dateChange: (days: number) =>
      `Les demandes doivent être faites ${days} jour${ending(
        days,
      )} ouvrable${ending(
        days,
      )} à l'avance par rapport à la date et à l'heure que vous demandez`,
    nonWorkTimeDateChange: (days: number) =>
      `Veuillez déplacer cette livraison. Les demandes doivent être faites ${days} jour${ending(
        days,
      )} ouvrable${ending(
        days,
      )} à l'avance par rapport à la date et à l'heure que vous demandez et à une date-heure de travail`,
    maximumDeliveriesAreScheduled:
      'Le nombre maximum de livraisons est déjà prévu pour cet emplacement',
  },
  loginInfoPageDescriptions: {
    letsGetLoggedIntoStruxHub: `Connectons-nous à ${brandName}`,
    resendMyInvitationLink: "Renvoyer mon lien d'invitation",
    expiredInvitation:
      'Votre invitation a été envoyée il y a plusieurs jours, elle est ancienne et pour des raisons de sécurité, nous devons vous renvoyer un nouveau lien.',
    newInvitationInfo: (hours: number) =>
      `Le nouveau lien sera valide pendant ${xHours(
        hours,
      )}, et vous devriez le recevoir immédiatement dans votre boîte de réception lorsque vous appuyez sur le bouton Renvoyer.`,
    ifYouHaveQuestionsOrNeedSupport:
      "Si vous avez des questions ou avez besoin d'assistance",
    youCanAlwaysReachUsAt: 'Vous pouvez toujours nous joindre à',
    inviteTokenHasInvalidFormat: "Le format du jeton d'invitation est invalide",
    inviteResendingConfirmation:
      'Votre invitation a été renvoyée. Allez vérifier votre email et suivez ce lien',
    sorryForSecurity:
      'Désolé pour les allers-retours, mais vous savez... la sécurité.',
  },
  assignResponsibilitiesConfirmation:
    "Confirmation de l'attribution des responsabilités",
  assignResponsibilities: 'Attribuer des responsabilités',
  assignToActivities: 'Attribuer aux activités',
  groupByTeam: 'Grouper par équipe',
  noTeam: '[aucune équipe]',
  hashActivities: '# Activités',
  rfi: 'RFI',
  flag: 'Drapeau',
  reply: 'Répondre',
  unspecifiedType: '(Type non spécifié)',
  contractsOrRevisions: 'Contrats/Révisions',
  priorWorkNotComplete: 'Travail préalable non achevé',
  informationNotAvailable: 'Informations non disponibles',
  unclearTaskDescription: 'Description de tâche floue',
  staffNotAvailable: 'Personnel non disponible',
  materialsNotAvailable: 'Matériaux non disponibles',
  noInformationAvailableOnThisMonitoring:
    'Aucune information disponible sur ce suivi',
  conditionsOfSatisfactions: 'Conditions de satisfaction',
  taskSequenceChange: 'Changement de séquence des tâches',
  incorrectTimeEstimate: 'Estimation de temps incorrecte',
  offProjectDemands: 'Demandes hors projet',
  unforeseenConditions: 'Conditions imprévues',
  weather: 'Météo',
  elevatedWork: 'Travail en hauteur',
  materialHandling: 'Manutention de matériaux',
  groundDistribution: 'Distribution au sol',
  craneOrHeavyLift: 'Grue / Levage lourd',
  confinedSpace: 'Espace confiné',
  mobileEquipment: 'Équipement mobile',
  deliver: 'Livrer',
  loto: 'LOTO',
  hotWork: 'Travail à chaud',
  hazardousMaterial: 'Matériau dangereux',
  highVoltage: 'Haute tension',
  highDecibels: 'Décibels élevés',
  lifting: 'Levage',
  closeQuarters: 'Quartiers fermés (<6ft)',
  rfiOpened: 'RFI ouvert',
  flagOpened: 'Drapeau ouvert',
  scheduleCommentOpened: "Commentaire de l'agenda ouvert",
  addANote: 'Ajouter une note',
  start_noun: 'Début',
  end_noun: 'Fin',
  forDate: (date: string) => `Pour ${date}`,
  addANoteToThisStatusUpdate: 'Ajouter une note à cette mise à jour de statut',
  chooseADeliveryTime: 'Choisir un horaire de livraison',
  allZones: 'Toutes les zones',
  allEquipment: "Tout l'équipement",
  selectDate: 'Sélectionner la date',
  selectField: 'Sélectionner le champ',
  selectDateAndTime: "Sélectionner la date et l'heure",
  jumpToDate: 'Aller à la date',
  jumpToMap: 'Aller à la carte',
  newDelivery: 'Nouvelle livraison',
  newForm: 'Nouveau formulaire',
  pending: 'En attente',
  confirmChange: 'Confirmer le changement',
  okToProceed: 'OK pour procéder ?',
  proceed: 'Procéder',
  actualStart: 'Début réel',
  updated: 'Mis à jour',
  approved: 'Approuvé',
  missing: 'Manquant',
  actualFinish: 'Fin réelle',
  xDaysUpdated: (x: number) => `${x} jours mis à jour!`,
  copyToMissing: 'Copier vers manquant',
  copy: 'Copier',
  copiedToClipboard: 'Copié dans le presse-papiers',
  copyLinkToDelivery: 'Copier le lien vers la livraison',
  checkInUnscheduledDelivery: "Enregistrement d'une livraison non planifiée",
  paste: 'Coller',
  estimateMissing: 'Estimation manquante',
  activityDetails: "Détails de l'activité",
  assigned: 'Attribué',
  followed: 'Suivi',
  following: 'Suivant',
  notFollowing: 'Ne suit pas',
  predecessors: 'Prédécesseurs',
  successors: 'Successeurs',
  viewTeam: "Voir l'équipe",
  dates: 'Dates',
  plannedStart: 'Début planifié',
  plannedEnd: 'Fin planifiée',
  actualEnd: 'Fin réelle',
  activityLog: "Journal d'activité",
  when: 'Quand',
  date: 'Date',
  time: 'Heure',
  where: 'Où',
  min_shortMinutes: 'min',
  hr_shortHours: 'hr',
  requester: 'Demandeur',
  subscriber: 'Abonné',
  onsiteContactPerson: 'Personne de contact sur site',
  onsiteContactPersons: 'Personnes de contact sur site',
  vehicle: 'Véhicule',
  quantity: 'Quantité',
  inspection: 'Inspection',
  thisDeliveryWasNotScheduledInAdvance:
    "Cette livraison n'était pas planifiée à l'avance",
  thisDeliveryWasScheduledForNonWorkTime:
    'Cette livraison était prévue pour un jour ou une heure non ouvrable',
  sitemap: 'Plan du site',
  flagAnIssue: 'Signaler un problème',
  openAnRfi: 'Ouvrir un RFI',
  requestADelivery: 'Demander une livraison',
  reason: 'Raison',
  addReasonForFlag: 'Ajouter une raison pour le drapeau',
  addReasonForRfi: 'Ajouter une raison pour le RFI',
  addReasonForCategoryOfVariance:
    'Ajouter une raison pour la catégorie de variance',
  addReasonForSafetyHazard: 'Ajouter une raison pour le risque de sécurité',
  addANoteToThisActivityLog: "Ajouter une note à ce journal d'activité",
  uploadingImage: "Téléchargement d'image",
  ganttDescriptions: {
    assignXUsersToYActivities: (x: number, y: number) =>
      `Affectation des ${x} membres d'équipe suivants à ${y} activités sélectionnées`,
  },
  actualDatesDescriptions: {
    newStartDateAfterOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Définir ',
      wrapper(newDate),
      ' comme début réel annulera ',
      wrapper(oldDate),
      ` comme début réel et ignorera toutes les mises à jour avant ${newDate}.`,
    ],
    newStartDateBeforeOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Définir ',
      wrapper(newDate),
      ' comme début réel annulera ',
      wrapper(oldDate),
      ' comme début réel et préservera toutes les données associées.',
    ],
    newEndDateAfterOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Définir ',
      wrapper(newDate),
      ' comme fin réelle annulera ',
      wrapper(oldDate),
      ' comme fin réelle et ignorera sa valeur de % achevé.',
    ],
    newEndDateBeforeOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Définir ',
      wrapper(newDate),
      ' comme fin réelle annulera ',
      wrapper(oldDate),
      ` comme fin réelle et ignorera toutes les mises à jour après ${newDate}.`,
    ],
  },
  offloadingEquipmentMaps: (x: number) =>
    `Plan d'équipement de déchargement${ending(x)}`,
  restrictedXLocations: (x: number) =>
    `Emplacements restreints ${x}${ending(x)}`,
  allLocationObjectsDisplayOnForm:
    'Tous les objets de localisation affichés sur le formulaire',
  restrictLocations: 'Restreindre les emplacements',
  selectRestrictedLocations: 'Sélectionner les emplacements restreints',
  restrictedXEquipments: (x: number) =>
    `Équipement restreints ${x}${ending(x)}`,
  allEquipmentObjectsDisplayOnForm: `Tous les objets d'équipement s'affichent sur le formulaire`,
  restrictEquipments: 'Restreindre les équipements',
  selectRestrictedEquipments: 'Sélectionner les équipements restreints',

  level: 'Niveau',
  zone: 'Zone',
  siteLocation: 'Emplacement du site',
  verticalObject: 'Objet vertical',
  allIcons: 'Tous les icônes',
  manpower: "Main-d'œuvre",
  manPower: "Main-d'œuvre",
  description: 'Description',
  code: 'Code',
  codeVerifying: 'Vérification du code',
  codeResending: 'Renvoi du code',
  resendCode: 'Renvoyer le code',
  contract: 'Contrat',
  plannedValue: 'Valeur prévue',
  bidStatus: "Statut de l'offre",
  street: 'Rue',
  satellite: 'Satellite',
  layers: 'Couches',
  showAllProjectsMarkers: 'Afficher tous les marqueurs des projets',
  useMapAnimations: 'Utiliser des animations cartographiques',
  terrain: 'Terrain',
  transit: 'Transit',
  traffic: 'Trafic',
  more: 'Plus',
  mapType: 'Type de carte',
  mapDetails: 'Détails de la carte',
  showPlan: 'Montrer le plan',
  showWorkflows: 'Montrer les flux de travail',
  version: 'Version',
  revision: 'Révision',
  build: 'Build',
  viewName: 'Nom de la vue',
  viewImage: 'Image de la vue',
  deliveryIdNotProvided: 'ID de livraison non fourni',
  sendingDeliveryTicket: 'Envoi du billet de livraison au conducteur',
  ticketSuccessfullySent: 'Billet envoyé avec succès',
  failedToSendTicket: "Échec de l'envoi du billet",
  invalidDeliveryTicket: 'Billet de livraison invalide',
  deliveryDetails: 'Détails de la livraison',
  onSiteLogistics: 'Logistique sur site',
  showAtEntryGate: "Afficher à l'entrée",
  showAtX: (location: string) => `Afficher à ${location}`,
  deliveryTicket: 'Billet de livraison',
  hasDelivery: 'a une livraison',
  duplicate_verb: 'Dupliquer',
  createFromToDelivery:
    'Créer une livraison de A à Z. La livraison actuelle doit être marquée ≥ [Sur site]',
  duplicateCurrentDelivery: 'Dupliquer la livraison actuelle',
  unsubscribeToDelivery: 'Se désabonner de la livraison',
  subscribeToDelivery: "S'abonner à la livraison",
  requiredFieldCannotBeEmpty: 'Le champ requis ne peut pas être vide',
  fieldShouldBeUnique: 'Le champ doit être unique',
  pleaseCorrectErrors: 'Veuillez corriger les erreurs',
  pleaseFillAllFields: 'Veuillez remplir tous les champs',
  companiesUpdated: 'Entreprises mises à jour',
  companyAdded: 'Entreprise ajoutée',
  forms: 'Formulaires',
  formWorkflows: 'Flux de travail des formulaires',
  formTypes: 'Types de formulaires',
  deliveryWorkflows: 'Flux de travail de livraison',
  deliveryLocations: 'Lieux de livraison',
  deliveryCards: 'Cartes de livraison',
  scheduleUpload: 'Téléversement du planning',
  scheduleColumns: 'Colonnes du planning',
  struxhubLabs: `${brandName} labs`,
  mapSetup: 'Configuration de la carte',
  mobileConfiguration: 'Configuration mobile',
  additionalDataUpload: 'Téléversement de données supplémentaires',
  onTimeThresholds: 'Seuils de ponctualité',
  qrScannersSetup: 'Configuration des scanners QR',
  businessPhone: 'Téléphone professionnel',
  businessEmail: 'Email professionnel',
  responsibles: 'Responsables',
  responsibleContact: 'Contact responsable',
  dba: 'DBA',
  licenseNumber: 'Numéro de licence',
  deliveryId: 'ID de livraison',
  bookingTime: 'Heure de réservation',
  enterVerificationCodeFromSMS: 'Entrer le code de vérification reçu par SMS',
  getDirections: 'Obtenir des directions',
  userInviteMessages: {
    oneUserWasInvitedToProject: (userName: string) =>
      `${userName} a été invité au projet`,
    manyUsersWereInvitedToProject: (count: number) =>
      `${xUsers(count)} ont été invités au projet`,
  },
  userEmailLinking: {
    linkYourEmailAndSetPassword:
      'Liez votre email et définissez un mot de passe',
    weakPassword: 'Mot de passe faible. Vérifiez le format valide',
    emailHasBeenLinkedSuccessfully: "L'email a été lié avec succès",
  },
  thereIsNoProject:
    "Il n'y a aucun projet disponible pour votre compte utilisateur. Veuillez contacter l'administrateur",
  repeatOn: 'Répéter le',
  operatorValues: {
    isNot: "N'EST PAS",
    is: 'EST',
    and: 'ET',
    or: 'OU',
    for: 'POUR',
    assign: 'ATTRIBUER',
    if: 'SI',
  },
  anyLocation: "N'importe quel emplacement",
  anyCompany: "N'importe quelle entreprise",
  selectCompany: "Sélectionner l'entreprise",
  xObjectsSelected: (x: number) => `${x} objet${ending(x)} sélectionné`,
  selectLocation: "Sélectionner l'emplacement",
  approval: 'Approbation',
  firewatch: 'Surveillance du feu',
  noRules: 'Aucune règle',
  rule: 'Règle',
  xOfY: (x: number, y: number) => `${x} sur ${y}`,
  options: 'Options',
  selectActivityGroups: "Sélectionner les groupes d'activités",
  numberOfBands: 'Nombre de bandes',
  lbs: 'LBS', // structure de répartition par emplacement
  workBreakdownStructureWbs: 'Structure de découpage du travail (WBS)',
  locationBreakdownStructureLbs: 'Structure de découpage par emplacement (LBS)',
  groupByOptions: 'Options de regroupement',
  showEmptyBandsLevels: 'Afficher les niveaux de bandes vides',
  customGroupCreation: 'Création de groupe personnalisé',
  bandX: (x: number) => `Bande ${x}`,
  custom: 'Personnalisé',
  groupByX: (x: string) => `Grouper par : ${x}`,
  bookingId: 'ID de réservation',
  responsibleCompany: 'Entreprise responsable',
  bookingDateEnTimeOffset: (offset: string) =>
    `Date et heure de réservation (${offset})`,
  durHmm_durationShort: 'Dur. (h:mm)',
  locationStatus: "Statut de l'emplacement",
  closureStart: 'Début de la fermeture',
  closureFinish: 'Fin de la fermeture',
  permittedCompany: 'Entreprise autorisée',
  closureWorkflow: 'Flux de travail de fermeture',
  updateForm: 'Mettre à jour le formulaire',
  formStatus: 'Statut du formulaire',
  formStart: 'Début du formulaire',
  formFinish: 'Finition du formulaire',
  materialName: 'Nom du matériel',
  productCategory: 'Catégorie de produit',
  deliveryMaterialNotes: 'Notes sur les matériaux de livraison',
  vendor: 'Vendeur',
  quantityOnsite: 'Quantité sur site',
  storageArea: 'Zone de stockage',
  requiredOnsiteDate: 'Date requise sur site',
  nextDelivery: 'Prochaine livraison',
  deliveryBookingDate: 'Date de réservation de la livraison',
  bookedQuantity: 'Quantité réservée',
  recordLastUpdated: 'Dernière mise à jour du dossier',
  numObjects: '# Objets',
  showOn: 'Afficher sur',
  noShow: 'Aucune présentation',
  moved: 'Déplacé',
  otherReason: 'Autre raison',
  mostPertinentDefault: 'Le plus pertinent (par défaut)',
  zones: 'Zones',
  gates: 'Portes',
  routes: 'Itinéraires',
  areas: 'Zones',
  levels: 'Niveaux',
  totalX: (x: number) => `Total ${x}`,
  deliveriesEnEquipmentNeedsPerHour:
    'Livraisons et besoins en équipement par heure',
  resetFilters: 'Réinitialiser les filtres',
  saved_activities: 'Enregistré',
  since: 'Depuis',
  lastP6Update: 'Dernière mise à jour P6',
  lastWeek: 'La semaine dernière',
  lastMonth: 'Le mois dernier',
  last6Week: 'Les 6 dernières semaines',
  dateRange: 'Plage de dates',
  dateRangeForRecentlyUpdatedActivities:
    'Plage de dates pour les activités récemment mises à jour',
  myTasks: 'Mes tâches',
  recentlyUpdated_activities: 'Récemment mis à jour',
  activitiesWithSpecificStatus: 'Activités avec un statut spécifique',
  actualizedFromSchedule_activities: 'Actualisées à partir du planning',
  activitiesWithOpenScheduleComment:
    'Activités avec un commentaire ouvert sur le planning',
  notCompleted_activities: 'Non complétées',
  criticalPathActivities: 'Activités du chemin critique',
  flaggedActivities: 'Activités signalées',
  lateActivities: 'Activités en retard',
  activitiesWithOpenRFI: 'Activités avec un RFI ouvert',
  activitiesWithDeliveries: 'Activités avec des livraisons',
  activityStatusValues: {
    NotStarted: 'Non commencé',
    InProgress: 'En cours',
    Completed: 'Complété',
    DueToday: "Dû aujourd'hui",
    DueTomorrow: 'Dû demain',
    LateFinish: 'Fin en retard',
    LateStart: 'Début en retard',
    StartsToday: "Commence aujourd'hui",
    Done: 'Fait',
    Incomplete: 'Incomplet',
  },
  numberOfActivities: "Nombre d'activités",
  seeXActivities: (x: number) => `Voir ${x} ${activities(x)}`,
  type: 'Type',
  assigners: 'Affectateurs',
  items: 'Articles',
  projectMember: 'Membre du projet',
  disableFilter: 'Désactiver le filtre',
  totalDeliveries: 'Total des livraisons',
  totalSitemaps: 'Total des plans de site',
  totalObjects: 'Total des objets',
  totalForms: 'Total des formulaires',
  totalReports: 'Total des rapports',
  totalClosures: 'Total des fermetures',
  totalSensors: 'Total des capteurs',
  seeXUsers: (x: number) => `Voir ${xUsers(x)}`,
  totalLogistics: 'Total des flux de travail',
  totalMaterials: 'Total des matériaux',
  seeXDeliveries: (x: number) => `Voir ${x} ${deliveries(x)}`,
  xPlans: (x: number) => `${x} ${plans(x)}`,
  seeXObjects: (x: number) => `Voir ${x} Objet${ending(x)}`,
  seeXSitemaps: (x: number) => `Voir ${x} Plan de site${ending(x)}`,
  seeXForms: (x: number) => `Voir ${xForms(x)}`,
  seeXClosures: (x: number) => `Voir ${x} Fermeture${ending(x)}`,
  seeXSensors: (x: number) => `Voir ${x} Capteur${ending(x)}`,
  seeXLogistics: (x: number) => `Voir ${x} Flux de travail`,
  seeXMaterials: (x: number) => `Voir ${x} Matériel${ending(x)}`,
  seeXReports: (x: number) => `Voir ${x} Rapport${ending(x)}`,
  currentLookahead: 'Prévision actuelle',
  fullProject: 'Projet complet',
  numberOfDeliveries: 'Nombre de livraisons',
  numberOfWorkflows: 'Nombre de flux de travail',
  savedFilters: 'Filtres enregistrés',
  saved_deliveries: 'Enregistré',
  xDeliveries: (x: number) => `${x} ${deliveries(x)}`,
  xWorkflows: (x: number) => `${x} ${workflows(x)}`,
  savedActivitySets: "Ensembles d'activités enregistrés",
  thereAreNoSavedActivityPresets:
    "Il n'y a aucun ensemble d'activités enregistré",
  xForms,
  xFilters: (x: number) => `${x} filtre${ending(x)}`,
  thereAreNoSavedFilters: "Il n'y a aucun filtre enregistré",
  makePublic: 'Rendre public',
  workPackageName: 'Nom du paquet de travail',
  selectedFilters: 'Filtres sélectionnés',
  share: 'Partager',
  saveCurrentFilters: 'Enregistrer les filtres actuels',
  saveANewCustomFilter: 'Enregistrer un nouveau filtre personnalisé',
  editACustomFilter: 'Modifier un filtre personnalisé',
  oneDay: 'Un jour',
  oneDayDefaultToday: "Un jour par défaut aujourd'hui",
  week: 'Semaine',
  fixedWeek: 'Semaine fixe',
  fixedTwoWeeks: '2 semaines fixes',
  fixedThreeWeeks: '3 semaines fixes',
  threeWeeks: '3 semaines',
  fourWeeks: '4 semaines',
  fixedFourWeeks: '4 semaines fixes',
  month: 'Mois',
  fixedSixWeeks: '6 semaines fixes',
  sixWeeks: '6 semaines',
  threeMonth: '3 mois',
  year: 'Année',
  other: 'Autre',
  xDateToYDate: (xDate: string, yDate: string) => `${xDate} à ${yDate}`,
  assignUsersOrCategories: 'Attribuer des utilisateurs ou des catégories',
  searchUserCompanyTeamOrRole:
    'Rechercher Utilisateur, Entreprise, Équipe ou Rôle',
  successfullyUpdatedExistingAssignments:
    'Mises à jour des affectations existantes réussies',
  successfullyUpdatedExistingAssignmentsByApp: (app: string) =>
    `Mises à jour des affectations existantes réussies pour ${app}`,
  successfullyDeletedAssignments: 'Suppression des affectations réussie',
  successfullyDeletedAssignmentsByApp: (app: string) =>
    `Suppression des affectations réussie pour ${app}`,
  newAnnouncement: 'Nouvelle annonce',
  editingAnnouncement: "Modification de l'annonce",
  announcements: 'Annonces',
  announcementHasBeenCreated: "L'annonce a été créée",
  announcementDateArrived: "La date de l'annonce est arrivée",
  announcementNotFound: "L'annonce n'a pas été trouvée",
  approve_verb: 'Approuver',
  readyToInspect: 'Prêt à inspecter',
  toInspect: 'À inspecter',
  notInUse: 'Non utilisé',
  everyXDaysInspection: (x: number) =>
    `Inspection tous les ${x} jour${ending(x)}`,
  everyXWeeksInspection: (x: number) =>
    `Inspection toutes les ${x} semaine${ending(x)}`,
  everyXMonthsInspection: (x: number) =>
    `Inspection tous les ${x} mois${ending(x)}`,
  addInspection: 'Ajouter une inspection',
  finish_noun: 'Fin',
  legend: 'Légende',
  startFinishState: 'État de début/fin',
  intermediateState: 'État intermédiaire',
  action: 'Action',
  ballInCourtStep: 'Étape de la balle au tribunal',
  active: 'Actif',
  struxhubActivityX: (code: string) => `Activité ${brandName} ${code}`,
  openActivity: "Ouvrir l'activité",
  openDelivery: 'Ouvrir la livraison',
  loadingPresentationMode: 'Chargement du mode de présentation',
  xOfYSelected: (x: number, y: number) => `${x} sur ${y} sélectionné`,
  xDeliveriesShowing: (x: number) => `${x} ${deliveries(x)} affiché`,
  assign_verb: 'Assigner',
  subscribe_verb: "S'abonner",
  new: 'Nouveau',
  completedTotal: 'Complété/Total',
  searchByBookingIdOrSubscribers: 'Rechercher par ID de réservation ou abonnés',
  my: 'Mon',
  me: 'Moi',
  full_period: 'Complet',
  day: 'Jour',
  calendarView: 'Vue Calendrier',
  ganttView: 'Vue Gantt',
  listView: 'Vue Liste',
  mapView: 'Vue Carte',
  dur_duration: 'Dur',
  loc_locations: 'Loc',
  equip_equipment: 'Équip',
  mat_materials: 'Mat',
  vend_vendor: 'Vend',
  a_actualFlag: 'A',
  unspecified: 'Non spécifié',
  yesUnsubscribe: 'Oui, se désabonner',
  deliveriesListDescriptions: {
    subscribeConfirmMassage: (
      dUnsubscribedCount: number,
      dSelectedCount: number,
    ) =>
      `S'abonner à [${dUnsubscribedCount}] désabonné${ending(
        dUnsubscribedCount,
      )} ${deliveries(
        dUnsubscribedCount,
      )} de [${dSelectedCount}] sélectionné${ending(
        dSelectedCount,
      )} ${deliveries(dSelectedCount)} ?`,
    unsubscribeConfirmMessage: (dSelectedCount: number) =>
      `Vous êtes déjà abonné à tous les [${dSelectedCount}] ${deliveries(
        dSelectedCount,
      )} sélectionné${ending(
        dSelectedCount,
      )}.\\n\\n Peut-être voulez-vous vous désabonner ?`,
  },
  logisticsListDescriptions: {
    subscribeConfirmMassage: (
      dUnsubscribedCount: number,
      dSelectedCount: number,
    ) =>
      `S'abonner à [${dUnsubscribedCount}] désabonné${ending(
        dUnsubscribedCount,
      )} ${workflows(
        dUnsubscribedCount,
      )} de [${dSelectedCount}] sélectionné${ending(
        dSelectedCount,
      )} ${workflows(dSelectedCount)} ?`,
    unsubscribeConfirmMessage: (dSelectedCount: number) =>
      `Vous êtes déjà abonné à tous les [${dSelectedCount}] ${workflows(
        dSelectedCount,
      )} sélectionné${ending(
        dSelectedCount,
      )}.\\n\\n Peut-être voulez-vous vous désabonner ?`,
    areYouWantToDelete:
      'Êtes-vous sûr de vouloir supprimer tous ces flux de travail sélectionnés?',
    deletionConfirmMsg: (selectedCount: number) =>
      `Vous avez sélectionné - ${selectedCount} ${workflows(selectedCount)}`,
  },
  sitemapsDataIsLoading:
    'Les données des plans de site sont en cours de chargement',
  editingConfirmation: "Confirmation de l'édition",
  selectDataColumn: 'Sélectionner la colonne de données',
  addFieldForEditing: "Ajouter un champ pour l'édition",
  deliveryBulkEditorDescriptions: {
    warningMessagePart1:
      'Aucune des livraisons sélectionnées ne peut être modifiée selon vos permissions de compte',
    warningMessagePart2:
      "Faites une autre sélection ou contactez l'administrateur",
  },
  failedToEdit: "Échec de l'édition",
  editedXFieldsOnYDeliveries: (x: number, y: number) =>
    `Édité ${x} Champ${ending(x)} sur ${y} ${deliveries(y)}`,
  editXDeliveries_question: (x: number) => `Éditer ${x} ${deliveries(x)}?`,
  onlyXOfYSelectedDeliveriesCanBeChanged: (x: number, y: number) =>
    `Seulement [${x}] de [${y}] ${deliveries(x)} sélectionné${ending(
      x,
    )} peut être modifié, procéder ?`,
  editXFieldsOnYDeliveries: (x: number, y: number) =>
    `Éditer ${x} Champ${ending(x)} sur ${y} ${deliveries(y)}`,
  updateXDeliveries: (x: number) => `Mettre à jour ${x} ${deliveries(x)}`,
  failedToUpdate: 'Échec de la mise à jour',
  statusUpdated: 'Statut mis à jour',
  statusUpdatedOnXDeliveries: (x: number) =>
    `Statut mis à jour sur ${x} ${deliveries(x)}`,
  updateDeliveryStatus: 'Mettre à jour le statut de la livraison',
  loadingDeliveriesData: 'Chargement des données de livraison',
  loadingFormsData: 'Chargement des données des formulaires',
  loadingLogisticsData: 'Chargement des données logistiques',
  form: 'Formulaire',
  log: 'Journal',
  weekOfEndDate: 'Semaine de la date de fin',
  companySummary: "Résumé de l'entreprise",
  percentDone: '% Fait',
  percentOnTime: "% À l'heure",
  trucks: 'Camions',
  avgTrucksPerDelivery: 'Moy. camions / Livraison',
  deliveryGantt: 'Gantt de livraison',
  projectSummary: 'Résumé du projet',
  completionRatePercent: 'Taux de complétion (%)',
  completionRate: 'Taux de complétion',
  onTimeRatePercent: "Taux à l'heure (%)",
  onTimeRate: "Taux à l'heure",
  unplanned: 'Non planifié',
  unplannedRate: 'Taux non planifié',
  onTimeStart: "Début à l'heure",
  lateOnTime: "Tard/À l'heure",
  allDay: 'Toute la journée',
  weekly: 'Hebdomadaire',
  monthly: 'Mensuel',
  onTimeLate: "À l'heure/En retard",
  earnedDuration: 'Durée acquise',
  show_verb: 'Montrer',
  showXMore: (x: number) => `Afficher encore ${x}`,
  showActive: 'Montrer actif',
  loadingProjectData: 'Chargement des données du projet',
  updateToday: "Mettre à jour aujourd'hui",
  critical: 'Critique',
  deltaDays: '∆ Jours',
  sumSlip: '∑ Glissement',
  openIssues: 'Problèmes ouverts',
  plannedToday: "Planifié aujourd'hui",
  actualToday: "Réel aujourd'hui",
  deltaManpowerToday: "∆ Main-d'œuvre aujourd'hui",
  deltaManpowerTrend: "Tendance ∆ Main-d'œuvre",
  soon: 'Bientôt',
  numberOfPicks: 'Nombre de choix',
  repeatDelivery: 'Répéter la livraison',
  every_plural: 'Chaque',
  endsOn: 'Se termine le',
  thisDelivery: 'Cette livraison',
  thisAndFutureDeliveries: 'Cette livraison et les suivantes',
  allDeliveries: 'Toutes les livraisons',
  everyXDays: (x: number) => `Tous les ${x} jour${ending(x)}`,
  everyXWeeks: (x: number) => `Toutes les ${x} semaine${ending(x)}`,
  everyXMonths: (x: number) => `Tous les ${x} mois${ending(x)}`,
  xDays: (x: number) => `jour${ending(x)}`,
  xWeeks: (x: number) => `semaine${ending(x)}`,
  xMonths: (x: number) => `mois${ending(x)}`,
  xMaterial: (x: number) => `Matériel #${x}`,
  addMaterial: 'Ajouter du matériel',
  forecast: 'Prévision',
  multipleEntries: {
    add_x: (x: string) => `Ajouter ${x}`,
    paste: 'Vous pouvez coller plusieurs entrées',
    typeOrPaste: 'Vous pouvez taper ou coller plusieurs entrées',
    repeatedValue: 'Entrée dupliquée',
  },
  locationControls: 'Contrôles de localisation',
  addDailyClosure: 'Ajouter une fermeture quotidienne',
  searchCompanyOrCompanyType:
    "Rechercher une entreprise ou un type d'entreprise",
  newActivity: 'Nouvelle activité',
  cast: 'Coulage',
  castName: 'Nom du coulage',
  monitoringName: 'Nom du suivi',
  deviceId: "ID de l'appareil",
  device: 'Appareil',
  lastMaturity: 'Maturité dernière',
  lastReading: 'Dernière lecture',
  elapsedTime: 'Temps écoulé (hh:mm)',
  maxElapsedTime: 'Max elapsed time (min)',
  lastTemperature: (temperatureUnit: MaturixTemperatureUnit) =>
    `Dernière température (${FrenchTree.temperatureUnit(temperatureUnit)})`,
  lastStrength: (strengthUnit: MaturixStrengthUnit) =>
    `Dernière résistance (${FrenchTree.strengthUnit(strengthUnit)})`,
  temperatureWithUnit: (temperatureUnit: MaturixTemperatureUnit) =>
    `Température (${FrenchTree.temperatureUnit(temperatureUnit)})`,
  strengthWithUnit: (strengthUnit: MaturixStrengthUnit) =>
    `Résistance (${FrenchTree.strengthUnit(strengthUnit)})`,
  temperatureUnit: (temperatureUnit: MaturixTemperatureUnit): string => {
    switch (temperatureUnit) {
      case MaturixTemperatureUnit.CELCIUS:
        return 'Celsius'
      case MaturixTemperatureUnit.FAHRENHEIT:
        return 'Fahrenheit'
    }
  },
  strengthUnit: (strengthUnit: MaturixStrengthUnit): string => {
    switch (strengthUnit) {
      case MaturixStrengthUnit.KGPERCMSQ:
        return 'kg/cm²'
      case MaturixStrengthUnit.MPA:
        return 'MPa'
      case MaturixStrengthUnit.NPERMMSQ:
        return 'N/mm²'
      case MaturixStrengthUnit.PSI:
        return 'PSI'
    }
  },
  unconfirmed: 'Non confirmé',
  confirmed: 'Confirmé',
  delivering: 'En cours de livraison',
  paused: 'En pause',
  onHold: 'En attente',
  ticketed: 'Billeté',
  recalled: 'Rappelé',
  enRoute: 'En route',
  pouring: 'Coulage',
  delivered: 'Livré',
  orderSummary: 'Résumé de la commande',
  payloadSummary: 'Résumé de la charge utile',
  orderStatus: 'Statut de la commande',
  totalVolume: 'Volume total',
  deliveryLocation: 'Lieu de livraison',
  mixType: 'Type de mélange',
  slump: 'Affaissement',
  spacingOrUnloadTime: 'Espacement ou temps de déchargement',
  pouringMode: 'Mode de coulage',
  elementsToPour: 'Éléments à couler',
  extraCharge: 'Supplément',
  orderedBy: 'Commandé par',
  load: 'Chargement',
  total: 'Total',
  timeOnSite: 'Temps sur site',
  ticket: 'Billet',
  volumeLoaded: 'Volume chargé',
  totalOrderVolume: 'Volume total de la commande',
  additive: 'Additif',
  jobTimes: 'Horaires de travail',
  startLoad: 'Commencer le chargement',
  beginPouring: 'Commencer le coulage',
  washing: 'Lavage',
  totalTimeOnSite: 'Temps total sur site',
  inXHoursAndXMin: (hours: number, minutes: number) =>
    `Dans ${hours}h ${minutes}min`,
  xHoursAndXMinLate: (hours: number, minutes: number) =>
    `${hours}h ${minutes}min en retard`,
  inXMin: (minutes: number) => `Dans ${minutes} min`,
  xMinLate: (minutes: number) => `${minutes} min en retard`,
  isAboutToArrive: "Est sur le point d'arriver",
  projected_time: 'Projeté',
  recurringDelivery: 'Livraison récurrente',
  endingXDate: (date: string) => `Se termine le ${date}`,
  isQuestionMandatory: 'La question est-elle obligatoire?',
  remainingDuration: 'Durée restante',
  baseUrl: 'URL de base',
  siteId: 'ID du site',
  isActive: 'Est actif',
  syncStartDate: 'Date de début de synchronisation',
  notUniqueActivityCodeMessage: (code: string) =>
    `L'activité avec le code [${code}] existe déjà. Essayez une autre valeur`,
  activityWasUpdated: "L'activité suivante a été mise à jour",
  activityUpdated: 'Activité mise à jour',
  editXFieldsOnYActivities: (x: number, y: number) =>
    `Éditer ${x} Champ${ending(x)} sur ${y} ${activities(y)}`,
  editedXFieldsOnYActivities: (x: number, y: number) =>
    `Édité ${x} Champ${ending(x)} sur ${y} ${activities(y)}`,
  editXActivities_question: (x: number) => `Éditer ${x} ${activities(x)}?`,
  onlyXOfYSelectedActivitiesCanBeChanged: (x: number, y: number) =>
    `Seulement [${x}] de [${y}] ${activities(x)} sélectionné${ending(
      x,
    )} peut être modifié, procéder ?`,
  p6Viewer: 'Visionneuse P6',
  scanner: 'Scanner',
  scannerApp: 'Appli Scanner',
  selectScanner: 'Sélectionner le scanner',
  createNewScanner: 'Créer un nouveau scanner',
  activitySetName: "Nom de l'ensemble d'activités",
  editActivitySet: "Modifier un ensemble d'activités",
  includeXFile: (ext: string) => `Inclure un fichier .${ext}`,
  includeDetailedEachForm: 'Inclure une vue détaillée de chaque formulaire',
  usersSubscribedTo: {
    deliveries: (uCount: number, iCount: number) =>
      `Abonné ${uCount} ${users(uCount)} à ${iCount} ${deliveries(iCount)}`,
    activities: (uCount: number, iCount: number) =>
      `Abonné ${uCount} ${users(uCount)} à ${iCount} ${activities(iCount)}`,
    announcements: (uCount: number, iCount: number) =>
      `Abonné ${uCount} ${users(uCount)} à ${iCount} ${announcements(iCount)}`,
    forms: (uCount: number, iCount: number) =>
      `Abonné ${uCount} ${users(uCount)} à ${iCount} ${forms(iCount)}`,
    sensors: (uCount: number, iCount: number) =>
      `Abonné ${uCount} ${users(uCount)} à ${iCount} ${sensors(iCount)}`,
    closures: (uCount: number, iCount: number) =>
      `Abonné ${uCount} ${users(uCount)} à ${iCount} ${closures(iCount)}`,
  },
  usersUnSubscribedFrom: {
    deliveries: (uCount: number, iCount: number) =>
      `Désabonné ${uCount} ${users(uCount)} à ${iCount} ${deliveries(iCount)}`,
    activities: (uCount: number, iCount: number) =>
      `Désabonné ${uCount} ${users(uCount)} à ${iCount} ${activities(iCount)}`,
    announcements: (uCount: number, iCount: number) =>
      `Désabonné ${uCount} ${users(uCount)} à ${iCount} ${announcements(
        iCount,
      )}`,
    forms: (uCount: number, iCount: number) =>
      `Désabonné ${uCount} ${users(uCount)} à ${iCount} ${forms(iCount)}`,
    sensors: (uCount: number, iCount: number) =>
      `Désabonné ${uCount} ${users(uCount)} à ${iCount} ${sensors(iCount)}`,
    closures: (uCount: number, iCount: number) =>
      `Désabonné ${uCount} ${users(uCount)} à ${iCount} ${closures(iCount)}`,
  },
  scannerName: 'Nom du scanner',
  scanMaster: 'Maître du scanner',
  scanStationReport: 'Rapport de la station de scanner',
  materialTrackingReport: 'Rapport de suivi des matériaux',
  truckSize: 'Taille du camion',
  cancelationReason: "Raison de l'annulation",
  responsibleCompanies: 'Entreprises responsables',
  noDataAvailable: 'Aucune donnée disponible',
  last7Days: 'Les 7 derniers jours',
  last30Days: 'Les 30 derniers jours',
  last120Days: 'Les 120 derniers jours',
  last365Days: 'Les 365 derniers jours',
  entireProject: 'Projet complet',
  frequencyAndPeriod: 'Fréquence et période',
  activityDataLinking: {
    disableLinkingQuestion: (field: string) =>
      `Le lien avec le champ externe '${field}' sera désactivé. Voulez-vous continuer ?`,
    enableLinkingQuestion: (fieldValue: string, externalFieldValue: string) =>
      `Voulez-vous lier '${fieldValue}' à l'externe '${externalFieldValue}' ? Cela peut affecter d'autres activités.`,
    linkingIsDisabled: 'Le lien est désactivé',
    linkingIsEnabled: 'Le lien est activé',
    linkingDisabled: `Lien désactivé`,
    linkingEnabled: `Lien activé`,
    editWarning: `Les modifications de lien peuvent affecter d'autres activités`,
    bulkEditWarning: `Le lien sera désactivé pour les champs modifiables`,
  },
  materialsUpload: 'Téléversement des matériaux',
  plannedQuantity: 'Quantité planifiée',
  materialsUploading: {
    uploadMaterials: 'Téléverser des matériaux',
    goToTheMaterialsPage: 'Aller à la page des matériaux',
    uploadYourMaterialsFile:
      'Téléversez votre fichier de matériaux au format Excel (.xlsx, .xlsm).',
    thereAreNoSheetsError:
      "Il n'y a pas de feuilles dans le fichier Excel fourni ou une erreur s'est produite lors de la lecture du fichier",
    materialCategoryLvl1: 'Catégorie de matériel (Niveau 1)',
    materialLvl2: 'Matériel (Niveau 2)',
    materialDescription: 'Description du matériel',
    materialPlannedQuantity: 'Quantité de matériel planifiée',
    startingTheUpload: 'Début du téléversement',
    readingMaterialsFile: 'Lecture du fichier de matériaux',
    extractingMaterialsData: 'Extraction des données des matériaux',
    removingOldData: 'Suppression des anciennes données',
    savingMaterialsData: 'Sauvegarde des données des matériaux',
    finishingUpload: 'Fin du téléversement',
    uploadOfMaterialsFailedAt: 'Échec du téléversement des matériaux à',
    uploadWasSuccessful: 'Le téléversement a été réussi',
    pleaseSelectSheetToUpload: (isMultiple: boolean) =>
      `${
        isMultiple ? 'Le fichier actuel contient plusieurs feuilles. ' : ''
      }Veuillez sélectionner une feuille à importer.`,
    makeSureLocationNamesUnique:
      'Assurez-vous que les noms des emplacements sont uniques dans StruxHub',
  },
  materialsUploadHistory: {
    currentMaterials: 'Matériaux actuels',
    projectUsesDefaultMaterials: 'Le projet utilise les matériaux par défaut',
    latestUpload: 'Dernier téléversement',
    materialsRestoringConfirmation:
      'Confirmation de la restauration des matériaux',
    totalMaterialCategoriesAndMaterials:
      'Total des catégories de matériaux et des matériaux',
    restoreDefaultMaterials: 'Restaurer les matériaux par défaut',
    doYouConfirmRestoreToDefaults:
      'Confirmez-vous la restauration aux valeurs par défaut ?',
    restoredSuccessfully: 'Restauré avec succès',
    failedToRestore: 'Échec de la restauration',
  },
  materialsUploadConfirm: {
    confirmDataMerge: 'Confirmer la fusion des données',
    confirmDescription1:
      'Téléverser ce fichier mettra à jour toutes les valeurs modifiées pour les matériaux existants et créera de nouveaux enregistrements pour les nouveaux matériaux.',
    confirmDescription2:
      'Si vous souhaitez supprimer toutes les données des matériaux existantes et les remplacer par les données du fichier de téléversement,',
    confirmDescription3: 'cliquez ici',
    replaceDescription:
      'Voulez-vous vraiment supprimer toutes les données des matériaux existantes et les remplacer par les données du fichier de téléversement ?',
  },
  collapseAll: 'Réduire tout',
  expandAll: 'Étendre tout',
  myUserProfile: 'Mon profil utilisateur',
  addTeammates: 'Ajouter des coéquipiers',
  addSummaryColumn: 'Ajouter une colonne de résumé',
  configureColumns: 'Configurer les colonnes',
  summaryColumns: 'Colonnes de résumé',
  receipt: 'Reçu',
  summary: 'Résumé',
  remainingQuantity: 'Quantité restante',
  materialsInDeliveryFormWarning: {
    onlyXMaterialsForForm: (x: number) =>
      `Actuellement seulement ${x} matériau${ending(x, '', 'x')} ${ending(
        x,
        'est',
        'sont',
      )} activé${ending(
        x,
        '',
        's',
      )} pour un formulaire de livraison. Si cela représente une limitation, veuillez contacter`,
    andWeCanTalk: 'et nous pourrons discuter',
  },
  deliverySuccessfullyCreated: 'Livraison créée avec succès',
  xDates,
  materialDescription: 'Description du matériau',
  thisGroupAlreadyExists: 'Ce groupe existe déjà',
  upload: 'Téléverser',
  totalBooked: 'Total réservé',
  totalDelivered: 'Total livré',
  doneDeliveries: 'Livraisons terminées',
  xVendors: (x: number) => `${x} Fournisseur${ending(x)}`,
  thisWeek: 'Cette semaine',
  thisMonth: 'Ce mois-ci',
  youCannotChangeDelivery:
    "Vous ne pouvez pas modifier une livraison tant qu'elle est dans cet état",
  plannedInstallLocation: "Emplacement d'installation prévu",
  plannedDeliveryLocation: 'Emplacement de livraison prévu',
  currentLocation: 'Emplacement actuel',
  procurementID: "ID d'approvisionnement",
  id_short: 'ID',
  readyToInstallQuantity: 'Quantité prête à installer',
  totalOnSiteQuantity: 'Quantité totale sur site',
  toMoveQuantity: 'Quantité à déplacer',
  formMaterialSubFields: {
    materialHasQuantityAndLocation:
      'Le matériel a des champs Quantité et Emplacement que vous pouvez également ajouter/modifier ci-dessous.',
    clickToEnableQuantity: 'Cliquez ici si vous souhaitez activer la Quantité.',
    clickToEnableLocation:
      "Cliquez ici si vous souhaitez activer l'Emplacement.",
  },
  newFormType: 'Nouveau type de formulaire',
  materialTransferId: 'ID de transfert de matériel',
  materialTransferDate: 'Date de transfert de matériel',
  newTransfer: 'Nouveau transfert',
  doneTransfers: 'Transferts terminés',
  totalTransferred: 'Total transféré',
  xTransfers: (x: number) => `${x} Transfert${ending(x)}`,
  youCanSelectMultipleOptions: 'Vous pouvez sélectionner plusieurs options',

  updateStatus: 'État de mise à jour',

  review_verb: 'Réviser',
  reviewed: 'Révisé',
  bicInspected: 'BIC Inspecté',
  activated: 'Activé',
  finished: 'Fini',
  submitted: 'Soumis',
  passAndClose: 'Passer et Fermer',
  formIsDoneOrDenied: (isDenied: boolean) =>
    `Le formulaire est ${isDenied ? 'refusé' : 'terminé'}`,
  stopped: 'Arrêté',
  request_noun: 'Demande',
  review_noun: 'Revoir',
  bicInspection: 'BIC Inspection',
  recurringInspection: 'Inspection récurrente',
  notifyUserIfWorkflowHasTags: `Avertir l'utilisateur si le flux de travail comporte les balises suivantes`,
  thisWorkflowStepCanBePerformed:
    'Cette étape du workflow peut être effectuée par les utilisateurs suivants',
  xRules: (x: number) => `${x} règle${ending(x)}`,
  step: 'Étape',
  bicUser: 'BIC utilisateur',
  formSection: 'Section du formulaire',
  actions: 'Actions',
  frequency: 'Fréquence',
  deadline: 'Date limite',
  automaticStart: 'Démarrage automatique',
  addStep: 'Ajouter une étape',

  workflowConfDescr: {
    mustContainApproval: `Le workflow avec une étape de demande doit contenir une étape d'approbation`,
    cannotBeTheLastStep: (stepName: string) =>
      `${stepName} ne peut pas être la dernière étape, ajoutez-en une autre ou supprimez celle-ci.`,
    workflowStartsOnDate:
      'Le workflow démarre automatiquement à la date de début.',
    automaticallyStart:
      'Démarrer automatiquement le flux de travail à la date de début',
    replaceWithStep: (stepType: string): string =>
      `Remplacez par l'étape ${stepType}`,
    replaceWithStart: `Remplacez par l'étape de démarrage`,
    deleteWorkflowStep: 'Supprimez l’étape du flux de travail',
    removeStepDialogMsg: 'Voulez-vous supprimer cette étape du workflow?',
    toastUnsavedMsg:
      'Les modifications que vous avez apportées ont été réinitialisées',
    recurringHasConstr:
      'L’étape d’inspection récurrente comporte des contraintes:',
    toggleDialogMsg: (isManualStart: boolean): string =>
      `Le remplacer par une étape de démarrage ${
        isManualStart ? 'Manuel' : 'Automatique'
      }?`,
    selectStepMsg:
      'Sélectionnez une étape du workflow pour ajouter/modifier des champs',
  },
  workflowConfTooltips: {
    bicBlockFirstLine: `On peut affecter un ou plusieurs utilisateurs aux étapes du workflow Ball-in-Court`,
    bicBlockSecondLine: `Si vous souhaitez affecter ces utilisateurs à des zones
    de travail spécifiques, définissez une règle basée sur l'ensemble de balises souhaité.`,
  },
  workflowTableText: {
    seeDescr: 'Voir les descriptions des étapes',
    stepCategory: `Catégorie d'étape du workflow`,
    workflowStep: 'Étape du flux de travail',
    constraints: 'Contraintes',
    bicText: `Pouvez-vous préciser l'utilisateur du ballon sur le terrain?`,
    multipleText: 'Pouvez-vous avoir plusieurs instances dans le workflow?',
    submissionDescr: `Étape de lancement du flux de travail pour soumettre
      un formulaire qui ne nécessite pas d'approbation ultérieure.`,
    submissionConstr: 'Peut uniquement lancer un flux de travail.',
    requestDescr:
      'Lancement de l’étape du flux de travail pour demander une approbation.',
    requestConstr:
      'Peut uniquement lancer un flux de travail. Doit avoir une étape d’approbation ultérieure.',
    reviewDescr:
      'Étape d’avancement générale d’un workflow pouvant être utilisé pour les pré-approbations.',
    approvalDescr: `Approbation d'une demande de workflow pouvant
      être attribuée à un ou plusieurs utilisateurs spécifiques.`,
    approvalConstr: 'Doit avoir une étape de demande précédente.',
    startDescr:
      'Démarrage d’un workflow initié en cliquant sur un bouton «Démarrer».',
    autoStartDescr: `Début d'un workflow lancé automatiquement à la date de début du workflow.`,
    onSiteDescr: `Utilisé pour indiquer l'arrivée sur place d'un véhicule de livraison.`,
    readyToInspectDescr:
      'Étape intermédiaire du flux de travail utilisée pour informer les inspecteurs.',
    inspectionDescr: 'Étape d’inspection générale.',
    bicInspectionDescr: `Étape d'inspection pouvant être attribuée à un ou plusieurs utilisateurs spécifiques.`,
    recurringDescr: 'Inspections récurrentes à une fréquence spécifiée.',
    recurringConstr:
      'Doit être directement précédé d’une étape de démarrage ou de démarrage automatique.',
    finishDescr: `Achèvement d'un flux de travail marqué en cliquant sur un bouton «Terminé».`,
  },
  workflowSteps: 'Étapes du flux de travail',
  workflowDiagram: 'Diagramme de flux de travail',
  workflowStepConfigurator: `Configurateur d'étape de flux de travail`,
  restoreToLastSavedState: 'Restaurer au dernier état enregistré',
  endOfTheDay: 'Fin de la journée',
  everyX_withoutNum: (x: number) => `Tous les${ending(x, '', '')}`,
  submission: 'Soumission',
  initiation: 'Initiation',

  deleteForm: 'Supprimer le formulaire',
  formDeletionDialogDescr: {
    confirmDelete: `La suppression de ce formulaire supprimera
    le formulaire ainsi que toutes les notes et données associées du projet.`,
    confirmDelete2:
      'Ne faites cela que si la demande de formulaire était une erreur.',
  },
  emailPdfReport: 'Rapport PDF par courrier électronique',
  unsubscribe: 'Se désabonner',
  followFormMenuDescr: {
    toReceive: 'Pour recevoir des notifications de flux de travail',
    toStop: 'Pour ne plus recevoir de notifications de flux de travail',
  },
  formDeleteMenuDescr: {
    isDone: 'Le formulaire est terminé, vous ne pouvez pas le supprimer',
    onlyFor: `Uniquement disponible pour les administrateurs,
      responsables du formulaire, demandeurs, cessionnaires`,
  },
  configureTableColumns: 'Configurer les colonnes du tableau',
  searchProperties: 'Rechercher des propriétés',
  shownInTable: 'Montré dans le tableau',
  hiddenInTable: 'Caché dans le tableau',
  shownInView: 'Montré en vue',
  hiddenInView: 'Caché à la vue',
  freezeColumns: 'Geler les colonnes ci-dessus',
  restoreDefaults: 'Restaurer par défaut',
  announcementFollowed: 'Annonce suivie',
  successfullyDeletedForms: (x: number) =>
    `Supprimé avec succès ${x} ${forms(x)}`,
  failedToDelete: 'Échec de la suppression',
  cannotDeleteDoneForms: 'Impossible de supprimer les formulaires terminés',
  getMobileNativeApp: "Obtenez l'application",
  getMobileNativeApp_long: "Obtenez l'application mobile StruxHub",
  getAppOnGoogle: 'Disponible sur Google Play',
  getAppOnApple: 'Téléchargez dans l’App Store',
  oneTimePopup: {
    title: 'Téléchargez notre nouvelle application mobile!',
    body: 'Recevez des mises à jour en temps réel et accomplissez plus de travail sur le terrain avec StruxHub.',
    dismiss: "D'accord, compris!",
  },

  exitScanner: 'Quitter le scanner',
  deleteScanner: 'Supprimer le scanner',
  editScannerText: 'Modifier le scanner',
  shouldDeleteScanner: 'Devrait supprimer le scanner',
  startScanner: 'Activer le scanner',
  queue: "File D'attente",
  startQueue: "Démarrer La File D'attente",
  addedToQueue: "Ajouté À La File D'attente",
  startSession: 'Démarrer La Session',
  allowedUsers: 'Utilisateurs admis',
  badgeDescription: 'Description du badge',
  makeItTimed:
    "Faites-en un scanner 'Chronométré' pour les utilisateurs. " +
    'Scan-Start : Scan-Stop = Total Time',
  limitAccess:
    "Limiter l'accès à des utilisateurs, équipes ou entreprises 'Badgés' spécifiques",
  endRideConfirm: 'Etes-vous sûr de vouloir terminer le trajet?',
  changeModeConfirm: 'Etes-vous sûr de vouloir changer le mode de conduite?',
  startSessionConfirm: 'Êtes-vous sûr de vouloir commencer la session?',
  isScannerTimedByDay:
    'La minuterie SE TERMINE automatiquement à la fin de la journée de travail',
  endRide: 'Fin du trajet',
  scanTo: 'Scanner vers',
  activeUsers: 'Utilisateurs actifs:',
  scannerActive: 'Scanner actif',
  scannerInactive: 'Scanner inactif',
  pauseScanner: 'Pause du scanner',
  resumeScanner: 'Reprendre un scanner',
  endSessionForAll: 'Fin de la séance pour tous',
  workflowCategory: 'Catégorie de flux de travail',
  workflowName: 'Nom du flux de travail',
  undo: 'Défaire',
  redo: 'Refaire',
  icon: 'Icône',
  showInApp: `Afficher dans l'application`,
  blockRequestsForNonWorkTimes: 'Blocage des demandes de temps non travaillé',
  general: 'Générale',
  steps: 'Étapes',
  views: 'Vues',
  confirmation: 'Confirmation',
  deletionOfDefaultWorkflows:
    'La suppression des flux de travail par défaut ou matériels est interdite',
  theFollowingEntitiesWereUpdated: 'Les entités suivantes ont été mises à jour',
  globeViews: 'Vues du globe',
  whiteboards: 'Tableaux blancs',
  available: 'Disponible',
  useMapLabels: 'Utiliser des étiquettes de carte',
  notifiedUsers: 'Utilisateurs notifiés',
  selectNotificationUsers: `Sélectionnez les utilisateurs qui recevront une notification après l'achèvement de cette étape`,
  wouldYouEnableNotificationsForSteps:
    'Souhaitez-vous activer les notifications pour ce champ dans toutes les étapes du flux de travail?',
}

export default FrenchTree
