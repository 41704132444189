import { action, computed } from 'mobx'

import { ScanStationGroupingOption } from '~/client/src/shared/enums/ScanStationGroupingOption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IScannerHistoryPair from '~/client/src/shared/models/IScannerHistoryPair'
import InitialState from '~/client/src/shared/stores/InitialState'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScanHistoriesStore from '~/client/src/shared/stores/domain/ScanHistories.store'
import {
  CATEGORY_ROW_HEIGHT,
  ITreeNodeObj,
} from '~/client/src/shared/stores/ui/BaseList.store'
import BaseMultiBandListStore from '~/client/src/shared/stores/ui/BaseMultiBandList.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import ProjectDateStore, {
  differenceInMinutes,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { groupingCommonMapper } from '~/client/src/shared/utils/mappers'
import { EMPTY_STRING, NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import {
  BasicDataKeys,
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '../../../ListWithFixedColumns/GroupedListWithFixedColumns'
import QrCodesStore from '../../QRCodes.store'

const FILTER_KEYS = ['id']

enum SpecificDataKeys {
  USER = 'user',
  START_TIME = 'startTime',
  FINISH_TIME = 'finishTime',
  ELAPSED_TIME = 'elapsedTime',
  QUEUE_TIME = 'queueTime',
}

export const DataKeys = { ...BasicDataKeys, ...SpecificDataKeys }

const unassignedValues = [UNASSIGNED, NO_VALUE]
const DEFAULT_SORT_KEY = 'startTime'

export default class QRCodesScannerLogListStore extends BaseMultiBandListStore<IScannerHistoryPair> {
  private readonly collator = new Intl.Collator([], {
    numeric: true,
    sensitivity: 'accent',
  })

  public constructor(
    private readonly store: QrCodesStore,
    private readonly common: CommonStore,
    private readonly state: InitialState,
    private readonly scanHistoriesStore: ScanHistoriesStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly projectMembersStore: ProjectMembersStore,
  ) {
    super(
      {
        searchKey: '',
        groupingKey: ScanStationGroupingOption.DAY,
        fieldsMap: {},
      },
      () => store.historiesBySelectedScannerPairs,
      FILTER_KEYS,
    )
  }

  public columnsWidthState = new Map<string, number>([
    [DataKeys.USER, 220],
    [DataKeys.START_TIME, 76],
    [DataKeys.FINISH_TIME, 76],
    [DataKeys.QUEUE_TIME, 76],
    [DataKeys.ELAPSED_TIME, 80],
  ])

  @computed
  public get columns(): ILWFCColumn[] {
    const { scanner } = this.store

    const columns = [
      {
        label: Localization.translator.user,
        dataKey: DataKeys.USER,
        isFixed: true,
        flexGrow: 4,
      },
      ...(scanner.isQueueScanner
        ? [
            {
              label: Localization.translator.queue,
              dataKey: DataKeys.QUEUE_TIME,
              flexGrow: 1,
            },
          ]
        : []),
      {
        label: Localization.translator.start_noun,
        dataKey: DataKeys.START_TIME,
        flexGrow: 1,
      },
      ...(scanner?.isTimedScanner
        ? [
            {
              label: Localization.translator.finish_noun,
              dataKey: DataKeys.FINISH_TIME,
              flexGrow: 1,
            },
            {
              label: Localization.translator.duration,
              dataKey: DataKeys.ELAPSED_TIME,
              flexGrow: 1,
            },
          ]
        : []),
    ]

    return columns.map(column => ({
      ...column,
      ...(this.columnsWidthState.has(column.dataKey) && {
        width: this.columnsWidthState.get(column.dataKey),
      }),
    }))
  }

  public get minTableWidth(): number {
    return this.columns.reduce(
      (overallWidth, column) => (overallWidth += column.width),
      0,
    )
  }

  @computed
  public get rows(): ILWFCRow[] {
    const keys = []
    keys.push(ScanStationGroupingOption.IS_ACTIVE)
    keys.push(ScanStationGroupingOption.DAY)

    return this.toBandTreeNodeRows(keys, null, '', 0, false, this.sortTreeObjs)
  }

  protected getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    const { getMonthDayAndYearToDisplay, isToday } = this.projectDateStore

    switch (currentBand) {
      case ScanStationGroupingOption.NONE:
        return [groupingCommonMapper(ScanStationGroupingOption.NONE)]
      case ScanStationGroupingOption.DAY:
        return this.filteredCollection
          .map(
            scanHistory =>
              getMonthDayAndYearToDisplay(scanHistory.history.startDate) ||
              UNASSIGNED,
          )
          .map(groupingCommonMapper)
      case ScanStationGroupingOption.IS_ACTIVE:
        return [
          ...this.filteredCollection.map(scanHistory => {
            const isTodayDate = isToday(scanHistory.history.startDate)
            const isActiveSession = scanHistory.scanner.isTimedScanner
              ? scanHistory.scanner.isActive && isTodayDate
              : isTodayDate
            return {
              name: isActiveSession ? 'Active session' : 'Previous sessions',
              id: isActiveSession.toString(),
            }
          }),
          groupingCommonMapper(UNASSIGNED),
        ]
      default:
        return []
    }
  }

  protected get categoryToInstancesMap(): {
    [key: string]: IScannerHistoryPair[]
  } {
    const map = {}

    this.filteredCollection.forEach(pair => {
      const categoryIds = this.getCategoryIds(pair, this.groupingKey) || [
        UNASSIGNED,
      ]

      categoryIds.forEach(id => {
        const formattedCategoryId = id || UNASSIGNED

        if (map[formattedCategoryId]) {
          map[formattedCategoryId].push(pair)
        } else {
          map[formattedCategoryId] = [pair]
        }
      })
    })

    return map
  }

  public getCategoryIds(pair: IScannerHistoryPair, band: string): string[] {
    const startDate = pair.history?.startDate
    const { getMonthDayAndYearToDisplay, isToday } = this.projectDateStore
    switch (band) {
      case ScanStationGroupingOption.DAY:
        return [getMonthDayAndYearToDisplay(startDate) || UNASSIGNED]
      case ScanStationGroupingOption.IS_ACTIVE:
        const isTodayDate = isToday(startDate)
        const isActiveSession = pair.scanner.isTimedScanner
          ? pair.scanner.isActive && isTodayDate
          : isTodayDate
        return [isActiveSession.toString()]
      case ScanStationGroupingOption.NONE:
        return [ScanStationGroupingOption.NONE]
      default:
        return []
    }
  }

  protected createCategoryRow(categoryId: string, count: number): ILWFCRow {
    const categoryInstances = this.categoryToInstancesMap[categoryId]

    const category: ILWFCCategory = {
      categoryId,
      shortCategoryLabel: count.toString(),
      categoryLabel: this.getCategoryLabelById(categoryId),
      isChecked: categoryInstances.every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
    }

    return { category, data: {}, height: CATEGORY_ROW_HEIGHT, level: 0 }
  }

  protected createTreeNodeCategoryRow(
    categoryId: string,
    categoryName: string,
    categoryObject: any,
    level: number,
    objects: IScannerHistoryPair[],
  ): ILWFCRow {
    const category: ILWFCCategory = {
      categoryId,
      shortCategoryLabel: objects.length.toString(),
      categoryLabel: categoryName,
      isChecked: objects.every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
      idsToSelect: objects.map(d => d[this.idKey]),
    }

    return {
      category,
      data: categoryObject,
      level,
    }
  }

  @computed
  protected get bandObjectMap(): {
    [bandType: string]: { [groupName: string]: IScannerHistoryPair[] }
  } {
    const map = {}

    const groupingOptions = Object.values(ScanStationGroupingOption)

    groupingOptions.forEach(band => {
      const bandMap = (map[band] = {})

      this.filteredCollection.forEach(scanHistory => {
        const groupNames: string[] = this.getCategoryIds(scanHistory, band) || [
          UNASSIGNED,
        ]

        groupNames.forEach(group => {
          const groupName = group ? group : UNASSIGNED

          if (!bandMap[groupName]) {
            bandMap[groupName] = []
          }
          bandMap[groupName].push(scanHistory)
        })
      })
    })

    return map
  }

  protected toRows(histories: IScannerHistoryPair[]): ILWFCRow[] {
    return histories.map(scanHistory => {
      const { date, endDate, queueDate } = scanHistory.history

      const { getTimeToDisplay } = this.projectDateStore
      const data = {
        [DataKeys.ID]: scanHistory.id,
        [DataKeys.USER]: scanHistory,
        [DataKeys.START_TIME]: date ? getTimeToDisplay(date) : EMPTY_STRING,
        [DataKeys.FINISH_TIME]: endDate
          ? getTimeToDisplay(endDate)
          : EMPTY_STRING,
        [DataKeys.ELAPSED_TIME]:
          date && endDate
            ? differenceInMinutes(new Date(endDate), new Date(date))
            : EMPTY_STRING,
        [DataKeys.QUEUE_TIME]: queueDate
          ? getTimeToDisplay(queueDate)
          : EMPTY_STRING,
      }

      return { data }
    })
  }

  private sortTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    const map = new Map<string, ITreeNodeObj>()

    treeNodeObjs.forEach(obj => map.set(obj.id, obj))

    return Array.from(map.values()).sort((a, b) => {
      if (unassignedValues.includes(a.id)) {
        return 1
      }
      if (unassignedValues.includes(b.id)) {
        return -1
      }

      if (this.groupingKey === ScanStationGroupingOption.DAY) {
        return parseInt(b.id, 10) - parseInt(a.id, 10)
      }

      return this.collator.compare(a.name, b.name)
    })
  }

  protected compareRows = (
    { data: aRowData }: ILWFCRow,
    { data: bRowData }: ILWFCRow,
  ): number => {
    const { columnKey, order } = this.sortState

    const v1 = this.getSortingValue(aRowData, columnKey)
    const v2 = this.getSortingValue(bRowData, columnKey)

    if (typeof v1 === 'string' && typeof v2 === 'string') {
      return this.collator.compare(v1, v2) * order
    }

    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return (v1 - v2) * order
    }

    return 0
  }

  private getSortingValue(rowData: LWFCRowData, columnKey: string) {
    const scanHistory = this.filteredCollection.find(
      _ => _.id === rowData[DataKeys.ID],
    )
    switch (columnKey) {
      case DataKeys.START_TIME:
        return scanHistory.history.startDate
      case DataKeys.FINISH_TIME:
        return scanHistory.history.endDate || scanHistory.history.date
      case DataKeys.QUEUE_TIME:
        return scanHistory.history.queueDate

      default:
        const value = rowData[columnKey]
        return value === NO_VALUE ? EMPTY_STRING : value
    }
  }

  @action.bound
  public initiateCollapsedCategories() {
    this.rows.forEach(
      ({ category, level }) =>
        category?.categoryId &&
        level !== 0 &&
        category?.categoryId.startsWith('false') &&
        this.collapsedCategories.set(category.categoryId, true),
    )
  }

  public getCategoryDate = (category: ILWFCCategory) => {
    const date = new Date(category.categoryLabel)
    return this.projectDateStore.convertToProjectDate(date)
  }

  public reportData = (idsToSelect?: string[]) => {
    const {
      user: { id: userId },
    } = this.state
    const scanHistories = this.store.historiesBySelectedScannerPairs
      .filter(_ => idsToSelect.includes(_.id))
      .map(_ => _.history)

    return {
      userId,
      projectTimeZone: this.projectDateStore.getClientTimezoneId(),
      filteredIds: {
        scanHistories: scanHistories.map(history => history.id),
      },
      sorting: {
        scanHistories: [
          {
            ascending: false,
            key: DEFAULT_SORT_KEY,
          },
        ],
      },
    }
  }
}
