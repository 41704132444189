import MaturixStrengthUnit from '../../enums/MaturixStrengthUnit'
import MaturixTemperatureUnit from '../../enums/MaturixTemperatureUnit'
import { TagType } from '../../enums/TagType'
import { getBandTitleByTagType } from '../../utils/TagHelper'
import LanguageTreeType from '../LanguageTreeType'
import { brandName, brandShortName } from '../brand'
import countries from './countries'
import timezones from './timezones'

const ending = (x: number, single: string = '', plural: string = 's') =>
  x === 1 ? single : plural

const users = (x: number) => `Usuario${ending(x, '', 's')}`
const forms = (x: number) => `Formulario${ending(x, '', 's')}`
const sensors = (x: number) => `Sensor${ending(x, '', 'es')}`
const closures = (x: number) => `Cierre${ending(x, '', 's')}`

const xUsers = (x: number) => `${x} usuario${ending(x)}`
const xFiles = (x: number) => `${x} archivo${ending(x)}`
const activities = (x: number) => `Actividad${ending(x, '', 'es')}`
const xHours = (x: number) => `${x} hora${ending(x, '', 's')}`
const deliveries = (x: number) => `Entrega${ending(x, '', 's')}`
const plans = (x: number) => `Plan${ending(x, '', 'es')}`
const workflows = (x: number) => `Flujo${ending(x)} de trabajo`
const announcements = (x: number) => `Anuncio${ending(x, '', 's')}`
const xDates = (x: number) => `${x} Fecha${ending(x)}`
const xForms = (x: number) => `${x} Formulario${ending(x)}`

const SpanishTree: LanguageTreeType = {
  _brandName: brandName,
  _brandShortName: brandShortName,

  avatar: 'Avatar',
  ok: 'OK',
  cancel: 'Cancelar',
  open: 'Abrir',
  opened: 'Abierto',
  close: 'Cerrar',
  closed: 'Cerrado',
  contacts: 'Contactos',
  loading: 'Cargando',
  language: 'Idioma',
  notSpecified: 'No especificado',
  default: 'Predeterminado',
  done: 'Hecho',
  save: 'Guardar',
  apply: 'Aplicar',
  edit_verb: 'Editar',
  merge_verb: 'Unir',
  link_verb: 'Vincular',
  re_link_verb: 'Volver a vincular',
  unlink_verb: 'Desconectar',
  unlinking: 'Desvinculación',
  delete: 'Eliminar',
  initials: 'Iniciales',
  invite: 'Invitar',
  search: 'Buscar',
  selectAll_people: 'Seleccionar todo',
  selectAll_items: 'Seleccionar todo',
  selectNone_people: 'Seleccionar ninguno',
  selectNone_items: 'Seleccionar ninguno',
  clearAll_items: 'Borrar todo',
  clear: 'Borrar',
  uploading: 'Subiendo',
  noResults: 'No hay resultados',
  add: 'Agregar',
  update_verb: 'Actualizar',
  updating: 'Actualizando',
  error: 'Error',
  goBack: 'Volver',
  confirm: 'Confirmar',
  select: 'Seleccionar',
  selected: 'Seleccionado',
  start_verb: 'Comenzar',
  finish_verb: 'Finalizar',
  followers: 'Seguidores',
  view_verb: 'Ver',
  download: 'Descargar',
  downloadingStatus: {
    starting: 'Comenzando',
    fetching: 'Obteniendo',
    compiling: 'Compilando',
    generating: 'Generando',
    downloading: 'Descargando',
  },
  searchCompany: 'Buscar empresa',
  permit: 'Permiso',
  permitAll: 'Permitir todo',
  thereIsNoCompanies: 'No hay empresas',
  thereIsNoObjectOfSelectedType: 'No hay objeto del tipo seleccionado',
  restrict: 'Restringir',
  restrictAccess: 'Restringir acceso',
  allCompanies: 'Todas las empresas',
  addCompany: 'Agregar empresa',
  addHours: 'Agregar horas',
  addNumber: 'Agregar número',
  addDuration: 'Agregar duración',
  closureHistory: 'Historial de cierres',
  addClosureDate: 'Agregar fecha de cierre',
  addSiteAnnouncement: 'Agregar anuncio del sitio',
  locationClosure: 'Cierre de ubicación',
  ifDeliveryHasTheFollowingTags: 'Si la entrega tiene las siguientes etiquetas',
  ifEntityHasTheFollowingTags: (entityName: string) =>
    `Si ${entityName} tiene las siguientes etiquetas`,
  thenDoAction: 'Entonces hacer acción',
  toUserTeamRoleOrCompany: 'A usuario, equipo, rol o empresa',
  addRule: 'Agregar regla',
  shouldDeleteRule: 'Debe eliminar regla',
  tag: 'Etiqueta',
  searchForOrSelectTag: 'Buscar o seleccionar etiqueta',
  addTag: 'Agregar etiqueta',
  addUserTeamCompany: 'Agregar Usuario, Equipo, Empresa',
  tagCreation: 'Creación de etiquetas',
  deliveryStatus: 'Estado de la entrega',
  sendNotification: 'Enviar notificación',
  notificationRules: 'Reglas de notificación',
  mention: 'Mencionar',
  gotToDirectory: 'Ir al directorio',
  goToProjectHome: 'Ir a la página principal del proyecto',

  daily: 'Diario',
  monSat: 'Lun-Sáb',
  d_daysShort: 'd',
  yesterday: 'Ayer',
  today: 'Hoy',
  tomorrow: 'Mañana',

  loggingIn: 'Iniciando sesión',
  loggingOut: 'Cerrando sesión',
  loginFailed: 'Error al iniciar sesión',
  login: 'Iniciar sesión',
  logInWith: 'Iniciar sesión con',
  logo: 'Logotipo',
  procoreLogin: 'Inicio de sesión en Procore',
  changePassword: 'Cambiar contraseña',
  forgotPassword: '¿Olvidaste tu contraseña?',
  reset: 'Restablecer',
  resetPassword: 'Restablecer contraseña',
  logOut: 'Cerrar sesión',
  common: 'Común',

  hideAll: 'Ocultar todo',
  hideGroup: 'Ocultar grupo',
  hideObject: 'Ocultar objeto',

  showAll: 'Mostrar todo',
  showGroup: 'Mostrar grupo',
  showObject: 'Mostrar objeto',

  contactHelpAtMail: (email: string) => 'Contactar ayuda en ' + email,
  contactHelp: 'Contactar ayuda',

  userNotFound: 'Usuario no encontrado',
  noUserMatchingYourSearch: 'Ningún usuario coincide con tu búsqueda',
  allUsersAlreadyAssigned: 'Todos los usuarios ya asignados',
  noTagMatchingYourSearch: 'Ninguna etiqueta coincide con tu búsqueda',

  emailCannotBeEmpty: 'El e-mail no puede estar vacío',
  enterYourEmailBelowAndStruxHubWillSendAResetLink: `Ingrese su e-mail a continuación y ${brandName} enviará un enlace para restablecer`,
  pleaseOpenTheLinkFromTheLetterAgain:
    'Por favor, abra el enlace de la carta otra vez',
  passwordResetLinkIsSentToMailbox:
    'El enlace para restablecer la contraseña se ha enviado al buzón',
  invalidEmailError: 'La dirección de e-mail está mal formateada.',
  userNotFoundError:
    'No hay un registro de usuario correspondiente a este identificador. El usuario puede haber sido eliminado.',
  enterANewPasswordBelow: 'Ingrese una nueva contraseña a continuación',
  setYourPassword: 'Establezca su contraseña',
  tooManyUnsuccessfulLoginAttempts:
    'Demasiados intentos de inicio de sesión fallidos.',
  passwordErrors: {
    weakPassword:
      'Las contraseñas deben tener al menos 8 caracteres, mayúsculas y minúsculas' +
      ', un número y un carácter especial (!@#$%&)',
    doNotMatch: 'Las contraseñas no coinciden',
    empty: 'La contraseña no puede estar vacía',
    invalid: 'La contraseña no es válida o el usuario no tiene una contraseña',
  },
  chat: 'Charlar',
  activities: 'Actividades',
  deliveries: 'Entregas',
  closures: 'Cierres',
  sensors: 'Sensores',
  logistics: 'Logística',
  logisticObject: 'Objeto logístico',
  site: 'Sitio',
  siteNews: 'Noticias de la obra',
  home: 'Inicio',
  siteLogistics: 'Logística del sitio',
  materials: 'Materiales',
  photos: 'Fotos',
  analytics: 'Analíticas',
  analyticsSetting: 'Configuración de analíticas',
  showInReports: 'Mostrar en informes',
  dashboards: 'Tableros',
  reports: 'Informes',
  filedToGenerateReport: 'No se pudo generar un informe',
  objectProperties: 'Propiedades del objeto',
  notifications: 'Notificaciones',
  userSettings: 'Configuración del usuario',
  projectSettings: 'Configuración del proyecto',
  settings: 'Configuraciones',
  messages: 'Mensajes',

  signIn: 'Iniciar sesión',
  signUp: 'Registrarse',
  signUpTo: 'Registrarse en',
  signedInAs: 'Conectado como',
  account: 'Cuenta',
  profile: 'Perfil',
  editProfile: 'Editar perfil',
  notificationPreferences: 'Preferencias de notificación',
  administration: 'Administración',
  projectDetails: 'Detalles del proyecto',
  integrations: 'Integraciones',
  userDirectory: 'Directorio de usuarios',
  team: 'Equipo',
  teams: 'Equipos',
  defaultTeams: 'Equipos predeterminados',
  presentations: 'Presentaciones',
  tracker: 'Rastreador',
  uploadSchedule: 'Programar carga',
  filterBy: 'Filtrar por',
  filter: 'Filtro',
  filters: 'Filtros',
  createNewFilter: 'Crear nuevo filtro',
  addScheduleData: 'Agregar datos de programación',
  editScheduleData: 'Editar datos de programación',
  struxhubObject: `Objeto ${brandName}`,
  selectObject: 'Seleccionar objeto',
  selectWorkForm: 'Seleccionar formulario de trabajo',
  hierarchies: 'Jerarquías',
  dataToSchedule: 'Datos para programar',
  sitemaps: 'Mapas del sitio',
  searchUserOrGroup: 'Buscar usuario o grupo',
  tool: 'Herramienta',
  workflow: 'Flujo de trabajo',
  workflows: 'Flujos de trabajo',
  sitemapSetup: 'Configuración del mapa del sitio',
  templateUpdating: 'Actualización de la plantilla',

  publishTo: 'Publicar en',
  geoReferencing: 'Georreferenciación',
  tags: 'Etiquetas',
  permittedCompanies: 'Compañías permitidas',
  associatedForms: 'Formularios asociados',
  associatedAnnouncements: 'Anuncios asociados',
  hoursOfOperation: 'Horas de operación',
  dateClosures: 'Cierres de fechas',
  maxBookingDuration: 'Duración máxima de la reserva',
  maxOverlappingBookings: 'Máximas reservas superpuestas',
  object: 'Objeto',
  objects: 'Objetos',
  objectName: 'Nombre del objeto',
  objectNestedUnder: 'Objeto anidado bajo',
  iconColor: 'Color del icono',
  image: 'Imagen',
  groupBy: 'Agrupar por',
  basemap: 'Mapa base',
  createNewView: 'Crear nueva vista',
  createNewTag: 'Crear nueva etiqueta',
  viewBasemap: 'Ver mapa base',
  viewBookings: 'Ver reservas',
  thereIsNoInstanceInTheSelectedCategory:
    'No hay ninguna instancia en la categoría seleccionada',

  name: 'Nombre',
  firstName: 'Nombre de pila',
  lastName: 'Apellido',
  phoneNumber: 'Teléfono',
  globalRole: 'Rol global',
  employeeIDNumber: 'Número de identificación del empleado',
  company: 'Compañía',
  companyDirectory: 'Directorio de la compañía',
  myCompany: 'Mi compañía',
  companyType: 'Tipo de compañía',
  companyName: 'Nombre de la compañía',
  user: 'Usuario',
  users: 'Usuarios',
  userTags: 'Etiquetas de usuario',
  allUsers: 'Todos los usuarios',
  role: 'Rol',
  projectRole: 'Rol del proyecto',
  projectRoles: 'Roles del proyecto',
  projectTrades: 'Oficios del proyecto',
  address: 'Dirección',
  email_noun: 'E-mail',
  phone: 'Teléfono',
  phoneVerification: 'Verificación de teléfono',
  call_verb: 'Llamar',
  email_verb: 'E-mail',
  sendDirectMessageInStruxHub: `Enviar mensaje directo en ${brandName}`,
  password: 'Contraseña',
  currentPassword: 'Contraseña actual',
  newPassword: 'Nueva contraseña',
  confirmNewPassword: 'Confirmar nueva contraseña',
  group: 'Grupo',
  reportsTo: 'Reporta a',
  reportOf: (type: string) => `Informe de ${type}`,
  createReport: 'Crear informe',
  deliveryReport: 'Informe de entrega',
  formReport: 'Informe de formulario',
  materialReport: 'Informe de material',
  aReportWillBeGeneratedWithTheAboveFilters:
    'Se generará un informe con los filtros anteriores',
  aReportWillBeSentToYourEmailShortly:
    'Se enviará un informe a su e-mail en breve',
  reportDetails: 'Detalles del informe',
  officeField: 'Oficina/Campo',
  accountType: 'Tipo de cuenta',
  status: 'Estado',
  monitoringStatus: 'Estado de monitoreo',
  castStatus: 'Estado de lanzamiento',
  levelsToClose: 'Niveles para cerrar',
  isDockMaster: 'Es Dock Master',
  isInspector: 'Es inspector',
  invalidUser: 'Usuario inválido',
  unknownUser: 'Usuario desconocido',
  unknownDeletedObject: 'Desconocido (Objeto eliminado)',
  inviteStatus: 'Estado de la invitación',
  inviteStatusValues: {
    Not_Invited: 'No invitado',
    Pending: 'Pendiente',
    Accepted: 'Aceptado',
    // service
    Not_Added: 'No agregado',
    Added: 'Agregado',
    Requested: 'Solicitado',
    Not_Requested: 'No solicitado',
  },
  accountPosition: 'Posición de la cuenta',
  accountPositions: {
    office: 'Oficina',
    field: 'Campo',
    management: 'Gestión',
  },
  projectAccessTypeValues: {
    admin: 'Administrador',
    member: 'Miembro',
    owner: 'Propietario',
  },
  modalInfoSections: {
    personalInformation: 'Información personal',
    companyInformation: 'Información de la empresa',
    projectInformation: 'Información del proyecto',
    additionalInformation: 'Información adicional',
    workflowPermissions: 'Permisos de flujo de trabajo',
    companyContactInfo: 'Información de contacto de la empresa',
    legalInfo: 'Información legal',
    companyAddress: 'Dirección de la empresa',
    users: 'Usuarios',
    qrCodeAccessAndBadges: 'Acceso a códigos QR y credenciales',
    globalInfo: `Información global (nivel ${brandShortName})`,
  },
  projectWorkflows: {
    deliveryBooking: 'Reserva de entrega',
    deliveryInspection: 'Inspección de entrega',
    scheduleComment: 'Comentario del horario',
    formsMaster: 'Maestro de formularios',
    scanMaster: 'Maestro de escaneo',
    viewSchedule: 'Ver horario',
    scheduleUpdating: 'Actualizar horario',
    scheduleUploading: 'Subir horario',
    scheduleNotes: 'Notas sobre el horario',
  },
  permissions: {
    none: 'Ninguno',
    readOnly: 'Solo lectura',
    standard: 'Standard',
    master: 'Master',
  },
  userProfileRoleNames: {
    admin: 'Administrador del sitio',
    owner: 'Propietario',
    docMaster: 'Maestro de documentos',
    inspector: 'Inspector',
    presentationUser: 'Usuario de presentación',
    formsMaster: 'Maestro de formularios',
    scheduleViewing: 'Ver horario',
    scheduleUpdating: 'Actualizar horario',
    scheduleUploading: 'Subir horario',
    scheduleNotes: 'Notas sobre el horario',
  },
  companyTypes: {
    GeneralContractorPrimary: 'Contratista general (Primario)',
    GeneralContractorSecondary: 'Contratista general (Secundario)',
    Owner: 'Propietario',
    SubContractor: 'Subcontratista',
    TieredSubContractor: 'Subcontratista escalonado',
  },
  saveProfile: 'Guardar perfil',
  notificationSettings: 'Configuración de notificaciones',
  announcementNotifications: 'Notificaciones de anuncios',
  deliveryNotifications: 'Notificaciones de entrega',
  scheduleNotifications: 'Notificaciones de horario',
  trackerNotifications: 'Notificaciones de seguimiento',
  logisticsNotifications: 'Notificaciones de logística',
  formsNotifications: 'Notificaciones de formularios',
  formNotifications: 'Notificaciones de formulario',
  scannerNotifications: 'Notificaciones del escáner',
  iWouldLikeToReceiveDeliveryTickets:
    'Me gustaría recibir boletos de entrega programados',
  iWouldLikeReceiveMy: 'Me gustaría recibir mi',
  inApp: 'En la aplicación',
  sms: 'SMS',
  bySms: 'Por SMS',
  byEmail: 'Por e-mail',
  successfullyUpdated: 'Actualizado con éxito',
  wrongPhoneNumberFormat: 'Formato de número de teléfono incorrecto',
  userProfileDescriptions: {
    name: `Su nombre se utilizará cuando se comunique con otros miembros del equipo en ${brandName}.`,
    projectRoles:
      'Su función en el proyecto se utiliza para asegurar que las notificaciones y los flujos de trabajo en los que está involucrado sean pertinentes. ' +
      'Es posible una mayor personalización en Preferencias en Configuración.',
    notifications:
      `Las aplicaciones ${brandName} le enviarán notificaciones para el trabajo del que es miembro del equipo. ` +
      'Estas notificaciones aparecen en la aplicación junto al icono de la campana en la parte superior derecha de la página, ' +
      'si ha ingresado su número de teléfono en su perfil a través de mensajes de SMS enviados a su teléfono, ' +
      'y algunos resúmenes de notificaciones pueden ser enviados a su dirección de e-mail. ' +
      'Puede desmarcar cualquiera de estas opciones a continuación para dejar de recibir notificaciones por ese método.',
  },
  whitelistedDomains: 'Dominios en la lista blanca',
  projectAdminSummary: 'Resumen del administrador del proyecto',
  lastUpdated: 'Última actualización',
  dateByName: (date: string, name: string) => date + ' por ' + name,
  uploadProjectSchedule: 'Subir horario del proyecto',
  fileUploaded: 'Archivo subido: ',
  totalActivities: 'Actividades totales',
  totalUsers: 'Usuarios totales',
  uploadProcurementPlan: 'Subir plan de adquisiciones',
  bpAssignedToCompanies: (amount: number, totalAmount: number) =>
    amount +
    ' de ' +
    totalAmount +
    ' paquetes de licitación asignados a empresas.',
  downloadCurrentValuesAsCsv: (toLink: (aText: string) => any) => [
    toLink('Descargar'),
    ' los valores actuales como archivo csv.',
  ],
  uploadTeamList: 'Subir la lista del equipo',
  currentTeamMembers: (amount: number) =>
    amount + ' miembros actuales del equipo.',
  emptyTagTypes: (tags: TagType[]) =>
    `Algunas de las etiquetas de tipo necesarias (${tags
      .map(tag => getBandTitleByTagType(tag))
      .toString()}) están vacías para algunas empresas. Se establecerán valores por defecto para ellas.`,
  adminSummaryDescriptions: {
    projectDetails:
      'Defina el nombre y la ubicación de su proyecto para que pueda encontrarlo fácilmente en su selector de proyectos',
    projectSchedule: `Suba su Programa Maestro actualizado desde P6 tan frecuentemente como desee. No se preocupe, no perderá ningún dato en ${brandName}.`,
    procurementPlan:
      'Suba su plan de adquisiciones actualizado a medida que incorpore más subcontratistas al Proyecto y refine sus valores esperados para cada paquete de licitación.',
    teamList:
      'Suba la lista del equipo de su Proyecto a medida que se unan nuevos miembros al Proyecto. Incluir información opcional como la afiliación interna del Departamento/Equipo facilita las asignaciones de recursos a las Actividades.',
  },
  project: 'Proyecto',
  notificationsRulesSetupDescriptions: {
    helperText1: (entityType: string) =>
      'Una regla enviará notificaciones a un conjunto de usuarios para todos los futuros ' +
      entityType +
      ' con un conjunto de etiquetas designadas',
    helperText2:
      'Las etiquetas se pueden usar solas o combinadas con operadores booleanos. Por ejemplo:',
  },
  selectProject: 'Seleccionar Proyecto',
  selectBookingTime: 'Seleccionar hora de reserva',
  newProject: 'Nuevo Proyecto',
  projectName: 'Nombre del Proyecto',
  projectCode: 'Código del Proyecto',
  projectLocation: 'Ubicación del Proyecto',
  projectLogo: 'Logotipo del Proyecto',
  streetAddress: 'Dirección',
  city: 'Ciudad',
  state_region: 'Estado',
  zipCode: 'Código Postal',
  country: 'País',
  projectTimeSettingsAndCalendar:
    'Configuración de tiempo y calendario del proyecto',
  QRCodes: 'Códigos QR',
  scanStations: 'Estaciones de escaneo',
  projectAdmins: 'Administradores del Proyecto',
  setTheTimeZoneForThisProject: 'Establecer la zona horaria para este proyecto',
  projectTimeZone: 'Zona horaria del proyecto',
  dateFormat: 'Formato de fecha',
  maturixProjectName: 'Nombre del Proyecto Maturix',
  maturixTemperatureUnit: 'Unidad de temperatura Maturix',
  maturixStrengthUnit: 'Unidad de resistencia Maturix',
  maturixStations: 'Estaciones Maturix',
  undefinedSection: 'Sección indefinida',
  setTheDateTimeFormatForThisProject:
    'Establecer el formato de fecha y hora para este proyecto',
  localTimezone: 'Zona horaria local',
  us: 'EE. UU.',
  international: 'Internacional',
  defaultWorkingDays: 'Días laborables predeterminados',
  customProjectClosures: 'Cierres de Proyecto personalizados',
  holidaysAndSiteClosures: 'Días festivos y cierres de sitio',
  addCustomProjectClosure: 'Agregar cierre de proyecto personalizado',
  forTheFuture: 'Para el Futuro',
  projectType: 'Tipo de Proyecto',
  apps: 'Aplicaciones',
  admins: 'Administradores',
  theListOfProjectsAdministrators: 'La lista de administradores del proyecto',
  addNew: 'Agregar nuevo',
  addNewAdmin: 'Agregar nuevo Administrador',
  addNewContact: 'Agregar nuevo Contacto',
  defaultWorkingHours: 'Horas de trabajo predeterminadas',

  struxHubHome: `Inicio de ${brandName}`,
  struxHubForms: `Formularios de ${brandName}`,
  bookDemo: 'Reservar demo',
  app: 'Aplicación',
  myAndAppWithEmptySpaces: 'Mi    Aplicación',
  showInNavigation: 'Mostrar en la navegación',
  setAsDefaultLandingPage: 'Establecer como página de inicio predeterminada',
  projectDetailsDescriptions: {
    projectNameError:
      'El nombre no debe estar vacío y debe contener solo letras, números, espacios, _, -',
    projectCodeError:
      'El código del proyecto no puede estar vacío y no debe contener caracteres especiales. La longitud máxima para el código del proyecto es de 5 caracteres',

    changeProjectLocationWarning:
      'ADVERTENCIA: La Zona Horaria del Proyecto ha sido configurada de manera diferente ' +
      'a la zona horaria en la Ubicación de su Proyecto',
  },
  integrationsSetupDescriptions: {
    invalidToken: 'Token Inválido',
  },
  countryValues: countries,
  timezoneValues: timezones,
  timezoneWithOffsets: (name: string, offsets: string[]) =>
    `${name} (GMT ${offsets.join('/')})`,

  addCustomProjectClosures: 'Añadir Cierres de Proyecto Personalizados',
  closureName: 'Nombre del Cierre',
  closureStartDate: 'Fecha de Inicio del Cierre',
  closureEndDate: 'Fecha de Finalización del Cierre',

  loadingTeamMembers: 'Cargando Miembros del Equipo',
  loadingMonitoringData: 'Cargando Datos de Monitoreo',
  xTeamMembersSelected: (x: number) =>
    `${x} miembro${ending(x)} del equipo seleccionado${ending(x)}`,
  xCompaniesSelected: (x: number) =>
    `${x} compan${ending(x, 'ía', 'ías')} seleccionada${ending(x)}`,
  importExportTeamMembers: 'Importar/Exportar miembros del equipo',
  teamMembersDropdownStates: {
    downloadTeamMembers: 'Descargar miembros del equipo .csv',
    uploadTeamMembers: 'Subir miembros del equipo .csv',
    viewCompanyDirectory: 'Ver directorio de la empresa',
    connectToProcore: 'Conectar al directorio del equipo Procore',
  },
  selectNotInvited: 'Seleccionar No Invitado',
  selectStatusRequested: 'Seleccionar ESTADO Solicitado',
  setNotInvited: 'Establecer como No Invitado',
  addNewProjectMember: 'Añadir nuevo miembro del proyecto',
  uploadYourUpdatedProjectMemberListCsvFile:
    'Suba su lista actualizada de miembros del proyecto en formato csv.',
  orDragYourFileHere: 'O arrastre su archivo aquí.',
  uploadYourScheduleFileInP6XerOrExcelXlsxXlsmFormat:
    'Suba su archivo de Programación en P6 (.xer) o Excel (.xlsx, .xlsm).',
  xIsUploaded: (file: string) => `${file} ha sido subido`,
  sendInviteLinkToUser: 'Enviar Enlace de Invitación al Usuario',
  addUser: 'Añadir Usuario',
  editUsers: (x: number) => `Editar Usuario${ending(x)}`,
  userValidationErrors: {
    firstNameRequired: 'Se requiere el primer nombre',
    lastNameRequired: 'Se requiere el apellido',
    emailRequired: 'Se requiere e-mail',
    invalidEmail: 'E-mail no válido',
    invalidPhone: 'Número de teléfono no válido',
    emailIsTaken: 'El e-mail ya ha sido tomado por otro miembro del proyecto',
    phoneIsTaken: 'El teléfono ya ha sido tomado por otro miembro del proyecto',
    memberWithSuchEmailIsAlreadyAdded:
      'Un usuario con este e-mail ya ha sido añadido al proyecto',
    memberWithSuchPhoneNumberIsAlreadyAdded:
      'Un usuario con este número de teléfono ya ha sido añadido al proyecto',
    companyRequired: 'Se requiere la compañía',
  },

  companyValidationErrors: {
    nameRequired: 'Se requiere el nombre de la compañía',
    emailRequired: 'Se requiere el e-mail de la empresa',
    invalidEmail: 'E-mail no válido',
    invalidPhone: 'Número de teléfono de la empresa no válido',
    emailIsTaken: 'El e-mail ya ha sido tomado por otra compañía',
    somethingFilledIncorrectly: 'Algo está llenado incorrectamente',
    companyTypeIsRequired: 'Se requiere el tipo de compañía',
    codeRequired: 'Se requiere el código',
    codeIncorrectLength: 'La longitud del código debe ser entre 3 y 5 símbolos',
    codeIsNotUnique:
      'Una compañía con este código ya existe, cambie el valor del campo de código para hacerlo único',
  },
  companyLinkingErrors: {
    anErrorOccurredWhileLinking: 'Se produjo un error al vincular',
    anErrorOccurredWhileUnlinking: 'Se produjo un error al desvincular',
    anErrorOccurredWhileFetchingGlobalStorage:
      'Se produjo un error al obtener el almacenamiento global',
  },
  companyMerging: {
    confirmation: (sourceCompaniesText: string, targetCompanyText: string) =>
      `¿Confirma la fusión de ${sourceCompaniesText} en ${targetCompanyText}`,
  },

  somethingWentWrongDuringAPIInteraction:
    'Algo salió mal durante la interacción con la API',

  confirmDeactivationOfTheFollowingXCompanies: (x: number) =>
    `Confirmar la desactivación de la${ending(x)} siguiente${ending(
      x,
    )} ${x} compan${ending(x, 'ía', 'ías')}?`,
  confirmActivationOfTheFollowingXCompanies: (x: number) =>
    `Confirmar la activación de la${ending(x)} siguiente${ending(
      x,
    )} ${x} compan${ending(x, 'ía', 'ías')}?`,
  confirmDeletionOfTheFollowingXAnnouncements: (x: number) =>
    `Confirmar la eliminación de${(ending(x), 'l', ' los')} siguiente${ending(
      x,
    )} ${x} anuncio${ending(x)}?`,
  confirmDeletionOfTheFollowingXProjectMembers: (x: number) =>
    `Confirmar la eliminación de${(ending(x), 'l', ' los')} siguiente${ending(
      x,
    )} ${x} miembro${ending(x)} del proyecto?`,
  confirmInvitationOfTheFollowingXProjectMembers: (x: number) =>
    `Confirmar la invitación de${(ending(x), 'l', ' los')} siguiente${ending(
      x,
    )} ${x} miembro${ending(x)} del proyecto?`,
  confirmSettingAsNotInvitedOfTheFollowingXProjectMembers: (x: number) =>
    `Confirmar la configuración como no invitado de${
      (ending(x), 'l', ' los')
    } siguiente${ending(x)} ${x} miembro${ending(x)} del proyecto?`,
  confirmInvitation: 'Confirmar Invitación',
  confirmSettingUsersAsNotInvited:
    'Confirmar configuración de usuarios como no invitados',
  confirmDeletion: 'Confirmar eliminación',
  confirmActivation: 'Confirmar activación',
  confirmDeactivation: 'Confirmar Desactivación',
  confirmDeletionOfTheFollowingXPlannedManpower: (x: number) =>
    `Confirmar la eliminación de la${ending(
      x,
    )} siguiente ${x} mano de obra planificada?`,
  confirmDeletionOfTheFollowingXBidPackages: (x: number) =>
    `Confirmar la eliminación de${ending(x, 'l', ' los')} siguiente${ending(
      x,
    )} ${x} paquete${ending(x)} de licitación?`,
  confirmDeletionOfActivities: (x: number) =>
    `¿Está seguro de que desea eliminar ${x} ${activities(x)}?`,
  selectProcoreProject: 'Seleccionar proyecto Procore',
  selectProcoreCompany: 'Seleccionar empresa Procore',
  noUnit: 'Sin unidad',
  noCompanies: 'Sin empresas',
  noDirectoryAccess: 'Sin acceso al directorio',
  xUsers,
  xUsersShowing: (x: number) => `${x} usuario${ending(x)} mostrando`,
  xTeams: (x: number) => `${x} equipo${ending(x)}`,
  addXUsers: (x: number) => {
    if (!x) {
      return 'Añadir'
    }
    return `Añadir ${x} usuario${ending(x)}`
  },
  xFiles,

  multiLevelScreen: 'Pantalla Multi-Nivel',
  levelsViewsWithZoneSummary: 'Vistas de niveles con resumen de zona',
  levelsViewsWithCompanySummary: 'Vistas de niveles con resumen de empresa',
  gantt: 'Gantt',
  presentationMode: 'Modo de presentación',
  play: 'Reproducir',
  stop: 'Detener',
  turnOn: 'Encender',
  presentationScreens: 'Pantallas de presentación',
  xMinutes: (x: number) => `${x} minuto${ending(x)}`,
  order_noun: 'Orden',
  order_number: (x: number) => {
    return `${x}º`
  },
  display: 'Mostrar',
  presentationModeDescriptions: {
    logisticsEntity: 'Abrir Anuncios',
    multiLevelScreen:
      'Muestra la actividad y el rendimiento de la mano de obra real frente a lo planificado ' +
      'para todos los niveles del edificio. Se desplaza para mostrar todos los niveles',
    levelsViewsWithZoneSummary:
      'Recorre cada nivel del edificio mostrando ' +
      'el rendimiento agregado de la actividad y la mano de obra para cada zona. ' +
      'Se desplaza para mostrar los datos de cada zona.',
    levelsViewsWithCompanySummary:
      'Recorre cada nivel del edificio mostrando ' +
      'el rendimiento agregado de la empresa. Se desplaza para mostrar los datos de cada empresa.',
    gantt:
      'Muestra la vista gantt del proyecto para la anticipación actual. ' +
      'Se desplaza para mostrar todas las actividades.',
    deliveries:
      'Muestra el calendario de entregas semanal para la semana actual.',
    logistics: 'Muestra el mapa logístico.',
    confirmText:
      'El modo de presentación te cerrará la sesión y configurará este dispositivo para la reproducción. Para ' +
      'salir del modo de presentación, vaya a Configuración. Deberá volver a iniciar sesión una vez que ' +
      'salga del modo de presentación.',
    header:
      'Activar el modo de presentación cerrará tu sesión y registrará este dispositivo en ' +
      'un modo de presentación solamente.',
    periodOfInactivity:
      'La presentación se ejecutará automáticamente después de un período de inactividad de',
    stop:
      'Detener el modo de presentación te llevará de vuelta a una pantalla de inicio de sesión ' +
      'donde podrás acceder a tus proyectos.',
  },
  include: 'Incluir',
  formConfiguration: 'Configuración de formulario',
  saveConfiguration: 'Guardar configuración',
  preTaskChecklist: 'Lista de verificación previa a la tarea',
  requireDailyInspections: 'Requerir inspecciones diarias',
  inspectionFrequency: 'Frecuencia de inspección',
  deadLineForRecordingAnInspection:
    'Fecha límite para registrar una inspección',
  checklist: 'Lista de verificación',
  instructions: 'Instrucciones',
  xOptions: (x: number) => `${x} opción${ending(x, '', 'es')}`,
  NA: 'N/A',
  remove: 'Eliminar',
  typeOfList: 'Tipo de lista',
  addAdditionalItem: 'Agregar ítem adicional',
  enterInstruction: 'Ingresar instrucción',
  enterTheXDigitCodeSentTo: (x: number) =>
    `Ingrese el código de ${x} dígitos enviado a`,
  areYouSureWantToDeleteInstructionItem:
    '¿Está seguro de que desea eliminar el ítem de instrucción?',
  instructionListTypes: {
    enumerated: 'Enumerado',
    bulleted: 'Con viñetas',
    none: 'Ninguno',
  },
  fieldStates: {
    mandatory: 'Obligatorio',
    optional: 'Opcional',
    hidden: 'Oculto',
  },

  revertingProjectData: 'Revertir datos del proyecto',
  loadingUpdatingProject: 'Cargando/Actualizando proyecto',
  downloadProjectDataCsv: 'Descargar datos del proyecto .csv',
  exploreYourScheduleInStruxHub: `Explora tu calendario en ${brandName}`,
  scheduleIsNotUploaded: 'El calendario no está subido',
  gettingXerProjects: 'Obteniendo proyectos xer',
  gettingExcelSheets: 'Obteniendo hojas de Excel',
  parsingTheScheduleFileStarted:
    'Comenzó el análisis del archivo de calendario',
  readingScheduleFile: 'Leyendo archivo de calendario',
  extractingScheduleData: 'Extrayendo datos del calendario',
  xEntriesInTheFile: (x: number) =>
    `${x} entr${ending(x, 'ada', 'adas')} en el archivo`,
  savingScheduleDataCollections:
    'Guardando colecciones de datos del calendario',
  xOfYEntriesSaved: (x: number, y: number) =>
    `${x} de ${y} entrada${ending(y)} guardada${ending(x)}`,
  recalculatingStatusUpdates: 'Recalculando actualizaciones de estado',
  savingSchedule: 'Guardando calendario',
  uploadingScheduleFailedAt: 'La subida del calendario falló en',
  uploadingScheduleDone: 'Subida del calendario realizada',
  seeGanttInStruxHub: `Ver Gantt en ${brandName}`,
  noActivitiesFound: 'No se encontraron actividades',
  continueToNextStep: 'Continuar al siguiente paso',
  thereAreNoProjectsInProvidedFile:
    'No hay proyectos en el archivo proporcionado',
  thereAreNoInstancesToDisplay: (instancesName: string = 'Instancias') =>
    `No hay ${instancesName.toLowerCase()} para mostrar`,
  scheduleUpdateSummary: 'Resumen de actualización de calendario',
  current: 'Actual',
  upload_noun: 'Subida',
  fileName: 'Nombre del archivo',
  dateUploaded: 'Fecha de subida',
  uploadScheduleDescriptions: {
    warning: (delta: number) =>
      `Las ${delta} ${activities(delta)} ` +
      `que ${ending(delta, 'está', 'están')} ausente${ending(
        delta,
      )} en el calendario actualizado ` +
      `serán eliminada${ending(
        delta,
      )} de la vista. Todo el contenido asociado con ` +
      `est${ending(delta, 'a', 'as')} ${delta} ` +
      `${activities(delta)} será eliminado ` +
      `de ${brandName} también.`,
    chooseProject:
      'El archivo P6 xer actual contiene datos de múltiples proyectos. ' +
      'Por favor, seleccione el proyecto para importar. Los datos en este proyecto deben ' +
      'corresponder a los datos contenidos en otras fuentes ' +
      '(por ejemplo, Plan de Compras, lista de miembros del equipo).',
    chooseSheet:
      'Los archivos actuales contienen múltiples hojas. ' +
      'Por favor, seleccione la hoja para importar.',
    mergeData: 'Actualizar calendario actual.',
    mapFields: 'Por favor, seleccione qué campo se utilizará para cada columna',
  },
  activityId: 'ID de Actividad',
  activityUniqueId: 'ID Único de Actividad',
  activityName: 'Nombre de la Actividad',
  activityCode: 'Código de la Actividad',
  activityStatus: 'Estado de la Actividad',
  activityPercentComplete: 'Porcentaje de la Actividad Completada',
  plannedActivityStart: 'Inicio Planeado de la Actividad',
  plannedActivityFinish: 'Finalización Planeada de la Actividad',
  actualActivityStart: 'Inicio Real de la Actividad',
  actualActivityFinish: 'Finalización Real de la Actividad',
  resourceId: 'ID del Recurso',
  resourceName: 'Nombre del Recurso',
  trade: 'Oficio',
  assignee: 'Asignado',
  primarySafetyHazard: 'Peligro Principal de Seguridad',
  secondarySafetyHazard: 'Peligro Secundario de Seguridad',
  categoryOfVariance: 'Categoría de Variación',
  categoryOfVarianceOpened: 'Categoría de Variación Abierta',
  activityCodeTypes: 'Tipos de Códigos de Actividad',
  resources: 'Recursos',
  uploadAnother: 'Subir Otro',
  next: 'Siguiente',
  saveAndViewUpdatedGantt: 'Guardar y Ver Gantt Actualizado',
  lastUpload: 'Última subida',
  companies: 'Empresas',
  targetCompany: 'Empresa objetivo',
  activitiesWithTheFollowingActivityCodesAreExcluded:
    'Las actividades con los siguientes códigos de actividad están excluidas',
  activityCodesSelected: 'Códigos de actividad seleccionados',
  scheduleRestoringConfirmation: 'Confirmación de Restauración de Calendario',
  doYouConfirmRestoringFileFromDate: (file: string, wrappedDate) => [
    `¿Confirma la restauración de ${file} desde `,
    wrappedDate,
    '?',
  ],
  doYouWantToSaveChanges: '¿Quieres guardar los cambios?',
  doYouAlreadyHaveAnAccount: '¿Ya tienes una cuenta de usuario?',
  uploadHistory: 'Historial de Subidas',
  restore: 'Restaurar',
  chooseProject: 'Elegir Proyecto',
  chooseSheet: 'Elegir Hoja',
  mapFields: 'Mapear Campos',
  xActivities: (x: number) => `${x} ${activities(x)}`,
  xHours,
  xLocations: (x: number) => `${x} Ubicación${ending(x, '', 'es')}`,
  xColumns: (x: number) => `${x} columna${ending(x)}`,
  cancelUpload: 'Cancelar Subida',
  selectProjectMembersLinkedToX: (x: string, isPlural: boolean) =>
    `Seleccionar miembro${isPlural ? 's' : ''} del proyecto vinculado${
      isPlural ? 's' : ''
    } a ${x}`,
  selectActivityCodesLinkedToX: (x: string, isPlural: boolean) =>
    `Seleccionar códigos de actividad vinculado${isPlural ? 's' : ''} a ${x}`,
  selectionOf1ProjectMemberIsPermitted:
    'Se permite la selección de 1 miembro del proyecto.',
  selectionOf1ActivityCodeIsPermitted:
    'Se permite la selección de 1 código de actividad.',
  multipleSelectionsArePermitted: 'Se permiten múltiples selecciones.',
  excludeSectionsOfTheScheduleOptional:
    'Excluir secciones del calendario (opcional)',
  activityFilterDialogDescriptions: {
    hiddenCodes:
      'Si desea ocultar ciertas secciones de su calendario, ' +
      'marque los códigos de actividad que correspondan a las actividades que desea ocultar.',
  },
  only: 'Solo',
  noCompany: 'Sin empresa',
  noMessage: 'Sin mensaje',
  submit_verb: 'Enviar',
  showAllXActivities: (x: number) => `Mostrar todas las ${x} ${activities(x)}`,
  showXOfYActivities: (x: number, y: number) =>
    `Mostrar ${x} de ${y} ${activities(x)}`,
  verification: 'Verificación',
  xActivitiesAdded: (x: number, wrappedX) => [
    wrappedX,
    ` ${activities(x)} añadidas.`,
  ],
  seeDetails: 'Ver detalles',
  xTypeActivityCodesAdded: (x: number, wrappedX, type: string) => [
    wrappedX,
    `Código${ending(x)} de actividad ${type} añadido${ending(x)}.`,
  ],

  activityFilters: 'Filtros de actividad',
  scheduleFilters: 'Filtros de horario',
  customFilter: 'Filtro personalizado',
  filterNameCannotBeEmpty: 'El nombre del filtro no puede estar vacío',
  suchFilterNameIsAlreadyTaken: 'Ese nombre de filtro ya está en uso',
  struxHubData: `Datos de ${brandName}`,
  scheduleData: 'Datos de horario',
  members: 'Miembros',
  projectMembers: 'Miembros del proyecto',
  activityCodes: 'Códigos de actividad',
  projectMembersSelected: 'Miembros del proyecto seleccionados',
  renameFilterOptional: 'Renombrar filtro (opcional)',
  filterName: 'Nombre del filtro',
  xCompaniesIdentified: (x: number) =>
    `${x} Compañía${ending(x)} identificada${ending(x)}`,
  xCompaniesShowing: (x: number) => `${x} Compañía${ending(x)} mostrando`,
  xCompanies: (x: number) => `${x} compañía${ending(x)}`,
  activityCodeSelected: 'Código de actividad seleccionado',
  responsibleUser: 'Usuario responsable',
  responsibilityCode: 'Código de responsabilidad',
  responsibilityDescription: 'Descripción de la responsabilidad',
  contractN: 'Contrato #',
  plannedValueS: 'Valor planificado ($)',
  uploadAnUpdatedXlsxFile: 'Subir un archivo xlsx actualizado',
  downloadCurrentListAsXlsxFile: 'Descargar la lista actual como archivo xlsx',
  addNewEntry: 'Añadir nueva entrada',
  addNewUser: 'Añadir nuevo usuario',
  previous: 'Anterior',
  activityFiltersDescriptions: {
    confirmDeletionOfX: (x: string) =>
      `¿Está seguro de que desea eliminar el filtro ${x}?`,
    title: `${brandName} transforma sus códigos de actividad y datos P6 en filtros convenientes para que sus equipos encuentren fácilmente sus actividades en la web y en dispositivos móviles. Puede configurar estos filtros a continuación y actualizarlos en cualquier momento.`,
    selectProjectMembers:
      'Seleccione el (los) miembro(s) del proyecto que desea incluir en este filtro. Se permiten selecciones múltiples.',
    selectActivityCodes:
      'Seleccione el (los) código(s) de actividad que desea incluir en este filtro. Se permiten selecciones múltiples.',
    company:
      'Este filtro permite a los usuarios seleccionar las compañías responsables de las actividades en su proyecto.',
    level:
      'Este filtro permite a los usuarios seleccionar los niveles de elevación de su edificio.',
    zone: 'Este filtro permite a los usuarios seleccionar las diferentes zonas de cada nivel del edificio.',
    activityCode:
      'Este filtro permite a los usuarios seleccionar códigos adicionales de actividades que desea rastrear.',
    projectMember:
      'Este filtro permite a los usuarios seleccionar los miembros del proyecto que desea rastrear.',
    associateCompanies:
      'Por favor indique cómo está asociando Compañías a sus actividades en el horario.',
    resourceCompanies:
      'Los nombres de las compañías están en el campo Recurso en el archivo .xer. Nota: este es el valor predeterminado si tiene un horario cargado de recursos.',
    bpCompanies:
      'Los nombres de las compañías están en un archivo xlsx separado y asociados con Actividades a través de un código de actividad.',
    bpStep1:
      'Seleccione el tipo de código de actividad en su archivo .xer que esté asociado con las compañías responsables.',
    bpStep2:
      'Suba un archivo de excel que contenga al menos los siguientes 2 encabezados de columna: Código de responsabilidad y Compañía.',
  },
  trackingHierarchy: 'Jerarquía de seguimiento',
  defaultHierarchies: 'Jerarquías predeterminadas',
  defineBandsEnPermissionsForYourTrackingHierarchy:
    'Defina las bandas y permisos para su Jerarquía de seguimiento',
  setupDefaultHierarchiesForDifferentViews:
    'Configure las jerarquías predeterminadas para diferentes vistas',
  selectBands: 'Seleccionar bandas',
  selectControlBand: 'Seleccionar banda de control',
  band: 'Banda',
  addBand: 'Añadir Banda',
  activityUpdatesOn: 'Actualizaciones de actividad activadas',
  activityUpdatesOff: 'Actualizaciones de actividad desactivadas',
  view_noun: 'Vista',
  wbs: 'WBS', // work breakdown structure
  webGantt: 'Web Gantt',
  webSitemap: 'Mapa del sitio web',
  mobile: 'Móvil',
  tablet: 'Tableta',
  hierarchyPreview: 'Vista previa de jerarquía',
  unassigned: 'No asignado',
  photo: 'Foto',
  completed: 'Completado',
  startEarly: 'Comenzar temprano',
  PComplete: '% Completado',
  unassignedActivity: 'Actividad no asignada',
  trackingHierarchyDescriptions: {
    title:
      'Configure su Jerarquía de seguimiento a continuación para comenzar a seguir su cronograma por parte de sus Equipos de campo, por parte de sus Subcontratistas o por ambos.',
  },

  editCompany: 'Editar compañía',
  addDataToSchedule: 'Agregar datos al cronograma',
  plannedCompanyManpower: 'Mano de obra planificada de la compañía',
  dataToScheduleDescriptions: {
    title: `Con ${brandName}, puede agregar rápidamente datos a su cronograma importando datos en archivos de Excel`,
  },

  formField: 'Campo del formulario',
  showField_question: '¿Mostrar campo?',
  mandatory_question: '¿Obligatorio?',
  fieldShown: 'Campo mostrado',
  mandatory: 'Obligatorio',
  addNewListElement: 'Añadir nuevo elemento de la lista',
  formPreview: 'Vista previa del formulario',

  setThresholdForDDays: 'Establecer umbral para ∆ Días',
  setThresholdForDManpower: 'Establecer umbral para ∆ Mano de obra',
  onTrack: 'En curso',
  watch: 'Vigilar',
  behind: 'Atrasado',
  dDays: '∆ Días',
  days: 'Días',
  percent: 'Por ciento',
  number: 'Número',
  daysBehind: 'Días Atrasados',
  daysAhead: 'Días Adelantados',
  XBehind: '% Atrasados',
  XAhead: '% Adelantados',
  under: 'Bajo',
  over: 'Sobre',
  XUnder: '% Bajo',
  XOver: '% Sobre',
  dManpower: '∆ Mano de Obra',
  sitemapsDescriptions: {
    title:
      `Con ${brandName}, puedes vincular fácilmente tu Programación con tus mapas del sitio. ` +
      'A continuación, puedes configurar los valores umbral para las métricas de ∆ Días y ' +
      '∆ Mano de Obra que se utilizan para visualizar el progreso en la Programación y la Mano de Obra actual, respectivamente.',
    configure:
      'Configurar Umbrales de Estado para la Programación y Mano de Obra',
  },

  inbox: 'Buzón',
  unread_2form: 'No Leído',
  read_2form: 'Leído',
  archived: 'Archivado',
  requested: 'Solicitado',
  changed: 'Cambiado',
  scheduled: 'Programado',
  onSite: 'En Sitio',
  denied: 'Denegado',
  passedInspection: 'Inspección Aprobada',
  failedInspection: 'Inspección Fallida',
  done_plural: 'Hecho',
  flags: 'Banderas',
  formSubmitted: 'Formulario enviado',
  formAccepted: 'Formulario aceptado',
  formFinished: 'Formulario finalizado',
  formClosed: 'Formulario cerrado',
  formChanged: 'Formulario cambiado',
  formDenied: 'Formulario denegado',
  formReadyToInspect: 'Formulario listo para inspeccionar',
  formActivated: 'Formulario activado',
  formReviewed: 'Formulario revisado',
  formOnSite: 'Formulario en sitio',
  formFailed: 'Formulario fallido',
  formPassed: 'Formulario pasado',
  formBicInspected: 'Formulario BIC inspeccionado',
  formDeleted: 'Formulario eliminado',
  formFollowed: 'Formulario Seguido',
  rfis: 'RFIs',
  scheduleComments: 'Comentarios de la Programación',
  onTime: 'A Tiempo',
  late: 'Atrasado',
  performance: 'Rendimiento',
  parameters: 'Parámetros',
  onTimeWindowHr: 'Ventana de A Tiempo (hr)',
  showLess: 'Mostrar Menos',
  showMore: 'Mostrar Más',
  all_items: 'Todo',
  none: 'Ninguno',
  unArchive: 'Desarchivar',
  archive: 'Archivar',
  read: 'Leer',
  unread: 'No Leído',
  wwpColoring: 'Coloreado WWP',
  useCSIMaterialCategories: 'Usar Categorías de Material CSI', // Construction Specifications Institute
  noNotificationsPassedFilters:
    'No hay Notificaciones que hayan pasado los filtros',
  replyToActivityFlag: 'Responder a la Bandera de Actividad',
  activityFlag: 'Bandera de Actividad',
  replyToActivityRfi: 'Responder al RFI de Actividad',
  activityRfi: 'RFI de Actividad',
  replyToScheduleComment: 'Responder al Comentario de Programación',
  replyToCategoryOfVariance: 'Responder a la Categoría de Varianza',
  replyToSafetyHazard: 'Responder a Riesgo de Seguridad',
  scheduleComment: 'Comentario de Programación',
  activityUpdate: 'Actualización de Actividad',
  scheduleUpdate: 'Actualización de Programación',
  safetyHazard: 'Riesgo de Seguridad',
  safetyHazardOpened: 'Riesgo de Seguridad Abierto',
  bulkEdit: 'Edición en Masa',
  bulkUpdate: 'Actualización en Masa',
  replyToActivityUpdate: 'Responder a Actualización de Actividad',
  activityFollowed: 'Actividad Seguida',
  deliveryFollowed: 'Entrega Seguida',
  replyToScheduleUpdate: 'Responder a Actualización de Programación',
  replyToBulkUpdate: 'Responder a Actualización en Masa',
  deliveryRequested: 'Entrega solicitada',
  deliveryRequestedByLocationClosure: (id: string) =>
    `La Entrega (${id}) ha sido afectada por un Cierre de Ubicación y debe ser reprogramada`,
  deliveryScheduled: 'Entrega programada',
  deliveryOnSite: 'Entrega en sitio',
  deliveryPassedInspection: 'Entrega aprobada en inspección',
  deliveryFailedInspection: 'Entrega fallida en inspección',
  deliveryInspected: 'Entrega Inspeccionada',
  deliveryDone: 'Entrega realizada',
  deliveryIncompletelyDone: 'Entrega incompletamente realizada',
  deliveryUpdated: 'Entrega actualizada',
  deliveryDeleted: 'Entrega eliminada',
  deliveryDeletedBy: (id: string, user: string, company: string) =>
    `La Entrega (${id}) ha sido eliminada por ${user} en ${company}`,
  deliveryDenied: 'Entrega denegada',
  deliveryCanceled: 'Entrega cancelada',
  deliveryOnHold: 'Entrega en espera',
  deliveryPaused: 'Entrega pausada',
  updateMaterials: 'Actualizar materiales',
  materialsLoading: 'Los materiales se están cargando',
  xMaterialsShowing: (count: number) =>
    `${count} Materiales se están mostrando`,
  planned: 'Planificado',
  actual: 'Real',
  scannerDeactivated: 'Escáner desactivado',
  scheduleUpdated: 'Horario actualizado',
  passed: 'Aprobado',
  failed: 'Fallido',
  scheduling: 'Programación',
  schedule: 'Horario',
  schedule_noun: 'Horario',
  pass_verb: 'Aprobar',
  deny_verb: 'Denegar',
  change_verb: 'Cambiar',
  accept: 'Aceptar',
  fail: 'Fallar',
  incompletelyDone: 'Incompletamente hecho',
  deleted: 'Eliminado',
  deactivated: 'Desactivado',
  activate: 'Activar',
  deactivate: 'Desactivar',
  canceled: 'Cancelado',
  siteNotReady: 'Sitio no listo',
  materialProblem: 'Problema con el material',
  laborIssue: 'Problema de trabajo',
  qualityIssue: 'Problema de calidad',
  clarificationNeeded: 'Se necesita aclaración',
  incorrectSpecification: 'Especificación incorrecta',
  materialQuestion: 'Pregunta sobre el material',
  superintendentNeeded: 'Se necesita superintendente',
  plusXMore: (x: number) => `+ ${x} más`,
  submittalInReview: 'Envío en revisión',
  submittalComplete: 'Envío completo',
  inFabrication: 'En fabricación',
  fabricationComplete: 'Fabricación completa',
  inStorage: 'En almacenamiento',
  installed: 'Instalado',
  delivery: 'Entrega',
  newRequest: 'Nueva solicitud',
  deleteDelivery: 'Eliminar entrega',
  yesDeleteDelivery: 'Sí, eliminar entrega',
  cancelDelivery: 'Cancelar entrega',
  yesCancelDelivery: 'Sí, cancelar entrega',
  changeDate: 'Cambiar fecha',
  yesMoveTheDate: 'Sí, mover la fecha',
  deliveryRequestIsNotValid: 'La solicitud de entrega no es válida',
  datePassedDeadline: 'Fecha límite pasada',
  requestAnyway: 'Solicitar de todos modos',
  requestAccessTo: 'Solicitar acceso a',
  thisRequestHasPassedTheDeadline: 'Esta solicitud ha pasado la fecha límite',
  noDeadline: 'Sin fecha límite',
  twentyFourHours: '24 horas',
  fortyEightHours: '48 horas',
  seventyTwoHours: '72 horas',
  fourDays: '4 días',
  fiveDays: '5 días',
  oneWeek: '1 semana',
  twoWeeks: '2 semanas',
  dateAndTime: 'Fecha y hora',
  startDate: 'Fecha de inicio',
  finishDate: 'Fecha de finalización',
  addFinishDate: 'Agregar fecha de finalización',
  removeFinish: 'Eliminar final',
  startTime: 'Hora de inicio',
  endTime: 'Hora de finalización',
  selectTime: 'Seleccionar hora',
  location: 'Ubicación',
  locationFrom: 'Ubicación Desde',
  locationTo: 'Ubicación Hasta',
  formType: 'Tipo de formulario',
  contact: 'Contacto',
  offloadingEquipment: 'Equipamiento de descarga',
  vehicles: 'Vehículos',
  material: 'Material',
  materialDeliveryLocation: 'Lugar de entrega del material',
  materialsCategory: 'Categoría de materiales',
  inspectionRequired: 'Inspección requerida',
  supplierAndDriver: 'Proveedor y conductor',
  anySpecialInstructions: '¿Algún instrucción especial?',
  deliverySitemap: 'Mapa del sitio de entrega',
  gate: 'Puerta',
  area: 'Área',
  fromToDelivery: 'Entrega desde hasta',
  workArea: 'Área de trabajo',
  installationZone: 'Zona de instalación',
  deliveryVehicleType: 'Tipo de vehículo de entrega',
  numberOfVehicles: 'Número de vehículos',
  numberOfVehicleLicensePlate: 'Modelo de vehículo y Lic.#',
  equipment: 'Equipamiento',
  sharedResources: 'Recursos compartidos',
  units: 'Unidades',
  inspector: 'Inspector',
  vendorEmails: 'Correos electrónicos del proveedor',
  driverMobilePhoneNumbers: 'Número(s) de teléfono móvil del conductor(es)',
  specialInstructions: 'Instrucciones especiales',
  linkedActivity: 'Actividad vinculada',
  customActionButton: 'Botón de acción personalizada',
  building: 'Edificio',
  route: 'Ruta',
  staging: 'Preparación',
  interiorPath: 'Camino interno',
  interiorDoor: 'Puerta interna',
  bookingDate: 'Fecha de reserva',
  requesterCompany: 'Empresa Solicitante',
  duration: 'Duración',
  onsiteContact: 'Contacto en el Sitio',
  onsiteContacts: 'Contactos en el Sitio',
  category: 'Categoría',
  materialNote: 'Nota de Material',
  unnamedMaterial: 'Material sin Nombre',
  required: 'Requerido',
  optional: 'Opcional',
  multipleSelection: 'Selección Múltiple',
  adding: 'Añadiendo',
  findOrAddNewEntry: 'Buscar o Añadir una Nueva Entrada',
  findEntry: 'Buscar Entrada',
  yes: 'Sí',
  no: 'No',
  unscheduledDelivery: 'Entrega no Programada',
  uploadASitemapToShowGatesAndAreas:
    'Subir un mapa del sitio para mostrar Puertas y Áreas',
  uploadAViewToShowGatesAndAreas:
    'Subir una vista del sitio para mostrar Puertas y Áreas',
  uploadASitemapToShowBuilding: 'Subir un mapa del sitio para mostrar Edificio',
  buildingAccessMap: 'Mapa de Acceso al Edificio',
  uploadASitemapToShowGates: 'Subir un mapa del sitio para mostrar Puertas',
  gateAccessMap: 'Mapa de Acceso a la Puerta',
  uploadASitemapToShowEquipment: 'Subir un mapa del sitio para mostrar Equipos',
  equipmentAccessMap: 'Mapa de Acceso a los Equipos',
  zoneAccessMap: 'Mapa de Acceso a la Zona',
  uploadASitemapToShowAreas: 'Subir un mapa del sitio para mostrar Áreas',
  routeAccessMap: 'Mapa de Acceso a la Ruta',
  uploadASitemapToShowRoute: 'Subir un mapa del sitio para mostrar Ruta',
  deliveryWorkflow: 'Flujo de Trabajo de Entrega',
  workflowMenu: 'Menú de Flujo de Trabajo',
  addANoteToThisDelivery: 'Añadir una nota a esta Entrega',
  send: 'Enviar',
  deliveryDescriptions: {
    confirmDelete:
      'Eliminar esta entrega eliminará la entrega y todas las notas y datos asociados al proyecto.',
    confirmDelete2: 'Haz esto solo si la Solicitud de Entrega fue un error.',
    confirmCancel: `Las entregas canceladas permanecerán en el calendario pero se marcarán como 'Canceladas'.`,
    confirmCancel2: '¿Quieres Cancelar esta entrega?',
    dateChange: (days: number) =>
      `Las solicitudes deben ser ${days} día${ending(days)} laborable${ending(
        days,
      )} con Antelación a la fecha y hora solicitadas`,
    nonWorkTimeDateChange: (days: number) =>
      `Por favor, mueve esta Entrega. Las solicitudes deben ser ${days} día${ending(
        days,
      )} laborable${ending(
        days,
      )} con Antelación a la fecha y hora solicitadas y en una fecha-hora laborable`,
    maximumDeliveriesAreScheduled:
      'El número máximo de entregas ya está programado para esta ubicación',
  },
  loginInfoPageDescriptions: {
    letsGetLoggedIntoStruxHub: `Vamos a iniciar sesión en ${brandName}`,
    resendMyInvitationLink: 'Reenviar mi enlace de invitación',
    expiredInvitation:
      'Tu invitación fue enviada hace varios días, es antigua y por razones de seguridad, necesitamos reenviarte un nuevo enlace.',
    newInvitationInfo: (hours: number) =>
      `El nuevo enlace será válido durante ${xHours(
        hours,
      )}, y deberías recibirlo en tu bandeja de entrada inmediatamente después de pulsar el botón de Reenviar.`,
    ifYouHaveQuestionsOrNeedSupport: 'Si tienes preguntas o necesitas ayuda',
    youCanAlwaysReachUsAt: 'Siempre puedes contactarnos en',
    inviteTokenHasInvalidFormat:
      'El token de la invitación tiene un formato inválido',
    inviteResendingConfirmation:
      'Tu invitación ha sido reenviada. Revisa tu e-mail y sigue el enlace',
    sorryForSecurity: 'Lo siento por las vueltas, pero ya sabes... seguridad.',
  },

  assignResponsibilitiesConfirmation:
    'Confirmación de Asignación de Responsabilidades',
  assignResponsibilities: 'Asignar Responsabilidades',
  assignToActivities: 'Asignar a Actividades',
  groupByTeam: 'Agrupar por Equipo',
  noTeam: '[sin equipo]',
  hashActivities: '# Actividades',
  rfi: 'RFI',
  flag: 'Marca',
  reply: 'Responder',
  unspecifiedType: '(Tipo no Especificado)',
  contractsOrRevisions: 'Contratos/Revisiones',
  priorWorkNotComplete: 'Trabajo Anterior No Completado',
  informationNotAvailable: 'Información No Disponible',
  unclearTaskDescription: 'Descripción de la Tarea No Clara',
  staffNotAvailable: 'Personal No Disponible',
  materialsNotAvailable: 'Materiales No Disponibles',
  noInformationAvailableOnThisMonitoring:
    'No hay información disponible sobre esta monitorización',
  conditionsOfSatisfactions: 'Condiciones de Satisfacción',
  taskSequenceChange: 'Cambio de Secuencia de Tarea',
  incorrectTimeEstimate: 'Estimación de Tiempo Incorrecta',
  offProjectDemands: 'Demandas Fuera del Proyecto',
  unforeseenConditions: 'Condiciones Imprevistas',
  weather: 'Clima',
  elevatedWork: 'Trabajo Elevado',
  materialHandling: 'Manejo de Materiales',
  groundDistribution: 'Distribución en Tierra',
  craneOrHeavyLift: 'Grúa / Elevación Pesada',
  confinedSpace: 'Espacio Confinado',
  mobileEquipment: 'Equipo Móvil',
  deliver: 'Entregar',
  loto: 'LOTO',
  hotWork: 'Trabajo en Caliente',
  hazardousMaterial: 'Material Peligroso',
  highVoltage: 'Alta Tensión',
  highDecibels: 'Altos Decibelios',
  lifting: 'Levantamiento',
  closeQuarters: 'Espacios Reducidos (<1.83m)',
  rfiOpened: 'RFI Abierto',
  flagOpened: 'Bandera Abierta',
  scheduleCommentOpened: 'Comentario de programación abierto',
  addANote: 'Agregar una nota',
  start_noun: 'Inicio',
  end_noun: 'Fin',
  forDate: (date: string) => `Para ${date}`,
  addANoteToThisStatusUpdate: 'Agregue una nota a esta Actualización de Estado',
  chooseADeliveryTime: 'Elija una hora de entrega',
  allZones: 'Todas las Zonas',
  allEquipment: 'Todo el Equipo',
  selectDate: 'Seleccionar fecha',
  selectField: 'Seleccionar campo',
  selectDateAndTime: 'Seleccionar fecha y hora',
  jumpToDate: 'Saltar a la fecha',
  jumpToMap: 'Saltar al mapa',
  newDelivery: 'Nueva Entrega',
  newForm: 'Nuevo formulario',
  pending: 'Pendiente',
  confirmChange: 'Confirmar Cambio',
  okToProceed: '¿OK para continuar?',
  proceed: 'Proceder',
  actualStart: 'Inicio Real',
  updated: 'Actualizado',
  approved: 'Aprobado',
  missing: 'Faltante',
  actualFinish: 'Finalización Real',
  xDaysUpdated: (x: number) => `¡${x} Días Actualizados!`,
  copyToMissing: 'Copiar a Faltante(s)',
  copy: 'Copiar',
  copiedToClipboard: 'Copiado al portapapeles',
  copyLinkToDelivery: 'Copiar enlace a entrega',
  checkInUnscheduledDelivery: 'Registrar entrega no programada',
  paste: 'Pegar',
  estimateMissing: 'Estimación Faltante(s)',
  activityDetails: 'Detalles de la Actividad',
  assigned: 'Asignado',
  followed: 'Seguido',
  following: 'Siguiendo',
  notFollowing: 'No siguiendo',
  predecessors: 'Predecesores',
  successors: 'Sucesores',
  viewTeam: 'Ver equipo',
  dates: 'Fechas',
  plannedStart: 'Inicio Planificado',
  plannedEnd: 'Fin Planificado',
  actualEnd: 'Fin Real',
  activityLog: 'Registro de Actividad',
  when: 'Cuando',
  date: 'Fecha',
  time: 'Hora',
  where: 'Dónde',
  min_shortMinutes: 'min',
  hr_shortHours: 'hr',
  requester: 'Solicitante',
  subscriber: 'Suscriptor',
  onsiteContactPerson: 'Persona de Contacto en el Sitio',
  onsiteContactPersons: 'Personas de Contacto en el Sitio',
  vehicle: 'Vehículo',
  quantity: 'Cantidad',
  inspection: 'Inspección',
  thisDeliveryWasNotScheduledInAdvance:
    'Esta entrega no fue programada con antelación',
  thisDeliveryWasScheduledForNonWorkTime:
    'Esta entrega fue programada para un día o hora no laborable',
  sitemap: 'Mapa del sitio',
  flagAnIssue: 'Marcar un problema',
  openAnRfi: 'Abrir un RFI',
  requestADelivery: 'Solicitar una Entrega',
  reason: 'Razón',
  addReasonForFlag: 'Agregar razón para la bandera',
  addReasonForRfi: 'Agregar razón para el RFI',
  addReasonForCategoryOfVariance:
    'Agregar razón para la Categoría de Variación',
  addReasonForSafetyHazard: 'Agregar razón para el Riesgo de Seguridad',
  addANoteToThisActivityLog: 'Agregar una nota a este Registro de Actividad',
  uploadingImage: 'Subiendo Imagen',
  ganttDescriptions: {
    assignXUsersToYActivities: (x: number, y: number) =>
      `Asignando ${ending(x, 'el', 'los')} siguiente${ending(x)} ${
        x != 1 ? x : ''
      } miembro${ending(x)} del equipo a ` +
      `${y} ${activities(y)} seleccionada${ending(y)}`,
  },
  actualDatesDescriptions: {
    newStartDateAfterOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Haciendo ',
      wrapper(newDate),
      ' el Inicio Real desmarcará ',
      wrapper(oldDate),
      ` como el Inicio Real e ignorará todas las actualizaciones antes de ${newDate}.`,
    ],
    newStartDateBeforeOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Haciendo ',
      wrapper(newDate),
      ' el Inicio Real desmarcará ',
      wrapper(oldDate),
      ' como el Inicio Real y conservará todos los datos asociados.',
    ],
    newEndDateAfterOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Haciendo ',
      wrapper(newDate),
      ' la Finalización Real desmarcará ',
      wrapper(oldDate),
      ' como la Finalización Real e ignorará su valor de % Completo.',
    ],
    newEndDateBeforeOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Haciendo ',
      wrapper(newDate),
      ' la Finalización Real desmarcará ',
      wrapper(oldDate),
      ` como la Finalización Real e ignorará todas las actualizaciones después de ${newDate}.`,
    ],
  },

  offloadingEquipmentMaps: (x: number) =>
    `Mapa${ending(x)} de equipo de descarga`,

  restrictedXLocations: (x: number) =>
    `Restringido ${x} ubicación${ending(x, '', 'es')}`,
  allLocationObjectsDisplayOnForm:
    'Todos los objetos de ubicación se muestran en el formulario',
  restrictLocations: 'Restringir ubicaciones',
  selectRestrictedLocations: 'Seleccionar Ubicaciones Restringidas',
  restrictedXEquipments: (x: number) =>
    `Restringido ${x} equipo${ending(x, '', 's')}`,
  allEquipmentObjectsDisplayOnForm:
    'Todos los objetos del equipo se muestran en el formulario',
  restrictEquipments: 'Restringir equipos',
  selectRestrictedEquipments: 'Seleccionar Equipos Restringidos',

  level: 'Nivel',
  zone: 'Zona',
  siteLocation: 'Ubicación del sitio',
  verticalObject: 'Objeto vertical',
  allIcons: 'Todos los iconos',
  manpower: 'Mano de obra',
  manPower: 'Mano de obra',
  description: 'Descripción',
  code: 'Código',
  codeVerifying: 'El código se está verificando',
  codeResending: 'El código se está reenviando',
  resendCode: 'Reenviar Código',
  contract: 'Contrato',
  plannedValue: 'Valor Planificado',
  bidStatus: 'Estado de la Oferta',

  street: 'Calle',
  satellite: 'Satélite',
  layers: 'Capas',
  showAllProjectsMarkers: 'Mostrar todos los marcadores de proyectos',
  useMapAnimations: 'Utilice animaciones de mapas',
  terrain: 'Terreno',
  transit: 'Tránsito',
  traffic: 'Tráfico',
  more: 'Más',
  mapType: 'Tipo de mapa',
  mapDetails: 'Detalles del mapa',
  showPlan: 'Mostrar Plan',
  showWorkflows: 'Mostrar Flujos de Trabajo',

  version: 'Versión',
  revision: 'Revisión',
  build: 'Construcción',

  viewName: 'Nombre de la vista',
  viewImage: 'Ver imagen',

  deliveryIdNotProvided: 'ID de entrega no proporcionado',
  sendingDeliveryTicket: 'Enviando boleto de entrega al conductor',
  ticketSuccessfullySent: 'Boleto enviado con éxito',
  failedToSendTicket: 'Fallo al enviar el boleto',
  invalidDeliveryTicket: 'Boleto de entrega no válido',
  deliveryDetails: 'Detalles de la Entrega',
  onSiteLogistics: 'Logística en el sitio',

  showAtEntryGate: 'Mostrar en la Puerta de Entrada',
  showAtX: (location: string) => `Mostrar en ${location}`,
  deliveryTicket: 'Boleto de Entrega',
  hasDelivery: 'tiene entrega',
  duplicate_verb: 'Duplicar',
  createFromToDelivery:
    'Cree la entrega De-a. La entrega actual debe estar marcada ≥ [Onsite].',
  duplicateCurrentDelivery: 'Duplicar entrega actual',
  unsubscribeToDelivery: 'Cancelar suscripción a la entrega',
  subscribeToDelivery: 'Suscribirse a la entrega',

  requiredFieldCannotBeEmpty: 'El campo requerido no puede estar vacío',
  fieldShouldBeUnique: 'El campo debe ser único',
  pleaseCorrectErrors: 'Por favor, corrija los errores',
  pleaseFillAllFields: 'Por favor llena todos los espacios',

  companiesUpdated: 'Empresas actualizadas',
  companyAdded: 'Empresa añadida',

  forms: 'Formularios',
  formWorkflows: 'Flujos de trabajo de formularios',
  formTypes: 'Tipos de formularios',
  deliveryWorkflows: 'Flujos de trabajo de entrega',
  deliveryLocations: 'Ubicaciones de entrega',
  deliveryCards: 'Tarjetas de entrega',

  scheduleUpload: 'Subida de horario',
  scheduleColumns: 'Columnas de horario',
  struxhubLabs: `Laboratorios ${brandName}`,

  mapSetup: 'Configuración del mapa',

  mobileConfiguration: 'Configuración móvil',
  additionalDataUpload: 'Subida de datos adicionales',
  onTimeThresholds: 'Umbrales a tiempo',
  qrScannersSetup: 'Configuración de escáneres de código QR',

  businessPhone: 'Teléfono de negocios',
  businessEmail: 'E-mail de negocios',
  responsibles: 'Responsables',
  responsibleContact: 'Contacto responsable',
  dba: 'DBA',
  licenseNumber: 'Número de licencia',

  deliveryId: 'ID de entrega',
  bookingTime: 'Hora de reserva',
  enterVerificationCodeFromSMS: 'Ingrese el código de verificación del SMS',
  getDirections: 'Obtener direcciones',

  userInviteMessages: {
    oneUserWasInvitedToProject: (userName: string) =>
      `${userName} fue invitado al proyecto`,
    manyUsersWereInvitedToProject: (count: number) =>
      `${xUsers(count)} fueron invitados al proyecto`,
  },

  userEmailLinking: {
    linkYourEmailAndSetPassword: 'Vincule su e-mail y establezca la contraseña',
    weakPassword: 'Contraseña débil. Verifique el formato válido',
    emailHasBeenLinkedSuccessfully: 'El e-mail ha sido vinculado con éxito',
  },

  thereIsNoProject:
    'No hay proyecto disponible para su cuenta de usuario. Por favor, contacte al administrador',

  repeatOn: 'Repetir en',

  operatorValues: {
    isNot: 'NO ES',
    is: 'ES',
    and: 'Y',
    or: 'O',
    for: 'PARA',
    assign: 'ASIGNAR',
    if: 'SI',
  },

  anyLocation: 'Cualquier ubicación',
  anyCompany: 'Cualquier empresa',
  selectCompany: 'Seleccionar empresa',
  xObjectsSelected: (x: number) =>
    `${x} objeto${ending(x)} seleccionado${ending(x)}`,
  selectLocation: 'Seleccionar ubicación',
  approval: 'Aprobación',
  firewatch: 'Vigilancia de fuego',
  noRules: 'No hay reglas',
  rule: 'Regla',

  xOfY: (x: number, y: number) => `${x} de ${y}`,
  options: 'Opciones',
  selectActivityGroups: 'Seleccionar grupos de actividad',
  numberOfBands: 'Número de bandas',
  lbs: 'LBS', // estructura de desglose de ubicación
  workBreakdownStructureWbs: 'Estructura de desglose de trabajo (WBS)',
  locationBreakdownStructureLbs: 'Estructura de desglose de ubicación (LBS)',
  groupByOptions: 'Agrupar por opciones',
  showEmptyBandsLevels: 'Mostrar niveles de bandas vacías',
  customGroupCreation: 'Creación de grupo personalizado',
  bandX: (x: number) => `Banda ${x}`,
  custom: 'Personalizado',
  groupByX: (x: string) => `Agrupar por: ${x}`,

  bookingId: 'ID de reserva',
  responsibleCompany: 'Empresa responsable',
  bookingDateEnTimeOffset: (offset: string) =>
    `Fecha y hora de reserva (${offset})`,
  durHmm_durationShort: 'Dur. (h:mm)',
  locationStatus: 'Estado de ubicación',
  closureStart: 'Inicio del cierre',
  closureFinish: 'Finalización del cierre',
  permittedCompany: 'Empresa permitida',
  closureWorkflow: 'Flujo de trabajo de cierre',
  updateForm: 'Formulario de actualización',
  formStatus: 'Estado del formulario',
  formStart: 'Inicio del formulario',
  formFinish: 'Finalización del formulario',
  materialName: 'Nombre del material',
  productCategory: 'Categoría del producto',
  deliveryMaterialNotes: 'Notas de material de entrega',
  vendor: 'Proveedor',
  quantityOnsite: 'Cantidad en el sitio',
  storageArea: 'Área de almacenamiento',
  requiredOnsiteDate: 'Fecha requerida en el sitio',
  nextDelivery: 'Próxima entrega',
  deliveryBookingDate: 'Fecha de reserva de entrega',
  bookedQuantity: 'Cantidad reservada',
  recordLastUpdated: 'Registro Actualizado por Última Vez',
  numObjects: 'Nº de Objetos',
  showOn: 'Mostrar en',
  noShow: 'No Mostrar',
  moved: 'Movido',
  otherReason: 'Otra Razón',
  mostPertinentDefault: 'Más pertinente (predeterminado)',
  zones: 'Zonas',
  gates: 'Puertas',
  routes: 'Rutas',
  areas: 'Áreas',
  levels: 'Niveles',
  totalX: (x: number) => `Total ${x}`,
  deliveriesEnEquipmentNeedsPerHour:
    'Entregas y Necesidades de Equipo por hora',
  resetFilters: 'Restablecer Filtros',
  saved_activities: 'Guardado',
  since: 'Desde',
  lastP6Update: 'Última actualización de P6',
  lastWeek: 'La semana pasada',
  lastMonth: 'El mes pasado',
  last6Week: 'Últimas 6 semanas',
  dateRange: 'Rango de fechas',
  dateRangeForRecentlyUpdatedActivities:
    'Rango de fechas para actividades recientemente actualizadas',
  myTasks: 'Mis Tareas',
  recentlyUpdated_activities: 'Recientemente Actualizado',
  activitiesWithSpecificStatus: 'Actividades con estado específico',
  actualizedFromSchedule_activities: 'Actualizado desde el horario',
  activitiesWithOpenScheduleComment:
    'Actividades con comentario abierto en el horario',
  notCompleted_activities: 'No Completado',
  criticalPathActivities: 'Actividades de Ruta Crítica',
  flaggedActivities: 'Actividades Marcadas',
  lateActivities: 'Actividades Atrasadas',
  activitiesWithOpenRFI: 'Actividades con RFI abierto',
  activitiesWithDeliveries: 'Actividades con Entregas',
  activityStatusValues: {
    NotStarted: 'No iniciado',
    InProgress: 'En progreso',
    Completed: 'Completado',
    DueToday: 'Para hoy',
    DueTomorrow: 'Para mañana',
    LateFinish: 'Finalización tardía',
    LateStart: 'Inicio tardío',
    StartsToday: 'Comienza hoy',
    Done: 'Hecho',
    Incomplete: 'Incompleto',
  },
  numberOfActivities: 'Número de Actividades',
  seeXActivities: (x: number) => `Ver ${x} ${activities(x)}`,
  type: 'Tipo',
  assigners: 'Asignadores',
  items: 'Artículos',
  projectMember: 'Miembro del Proyecto',
  disableFilter: 'Desactivar filtro',
  totalDeliveries: 'Entregas Totales',
  totalSitemaps: 'Mapas del sitio Totales',
  totalObjects: 'Objetos Totales',
  totalForms: 'Formularios Totales',
  totalReports: 'Informes Totales',
  totalClosures: 'Cierres Totales',
  totalSensors: 'Sensores Totales',
  seeXUsers: (x: number) => `Ver ${xUsers(x)}`,
  totalLogistics: 'Flujos de trabajo Totales',
  totalMaterials: 'Materiales Totales',
  seeXDeliveries: (x: number) => `Ver ${x} ${deliveries(x)}`,
  xPlans: (x: number) => `${x} ${plans(x)}`,
  seeXObjects: (x: number) => `Ver ${x} Objeto${ending(x)}`,
  seeXSitemaps: (x: number) => `Ver ${x} Mapa${ending(x)} del sitio}`,
  seeXForms: (x: number) => `Ver ${xForms(x)}`,
  seeXClosures: (x: number) => `Ver ${x} Cierre${ending(x)}`,
  seeXSensors: (x: number) => `Ver ${x} Sensor${ending(x, '', 'es')}`,
  seeXLogistics: (x: number) => `Ver ${x} Flujo${ending(x)} de trabajo}`,
  seeXMaterials: (x: number) => `Ver ${x} Material${ending(x, '', 'es')}`,
  seeXReports: (x: number) => `Ver ${x} Informe${ending(x)}`,
  currentLookahead: 'Previsión actual',
  fullProject: 'Proyecto Completo',
  numberOfDeliveries: 'Número de Entregas',
  numberOfWorkflows: 'Número de flujos de trabajo',
  savedFilters: 'Filtros Guardados',
  saved_deliveries: 'Guardado',
  xDeliveries: (x: number) => `${x} ${deliveries(x)}`,
  xWorkflows: (x: number) => `${x} ${workflows(x)}`,
  savedActivitySets: 'Conjuntos de Actividades Guardados',
  thereAreNoSavedActivityPresets:
    'No hay ajustes preestablecidos de actividades guardados',
  xForms,
  xFilters: (x: number) => `${x} filtro${ending(x)}`,
  thereAreNoSavedFilters: 'No hay filtros guardados',
  makePublic: 'Hacer público',
  workPackageName: 'Nombre del paquete de trabajo',
  selectedFilters: 'Filtros seleccionados',
  share: 'Compartir',
  saveCurrentFilters: 'Guardar los filtros actuales',
  saveANewCustomFilter: 'Guardar un filtro personalizado nuevo',
  editACustomFilter: 'Editar un filtro personalizado',

  oneDay: 'Un Día',
  oneDayDefaultToday: 'Un Día Predeterminado Hoy',
  week: 'Semana',
  fixedWeek: 'Semana Fija',
  fixedTwoWeeks: '2 Semanas Fijas',
  fixedThreeWeeks: '3 Semanas Fijas',
  threeWeeks: '3 Semanas',
  fourWeeks: '4 Semanas',
  fixedFourWeeks: '4 Semanas Fijas',
  month: 'Mes',
  fixedSixWeeks: '6 Semanas Fijas',
  sixWeeks: '6 Semanas',
  threeMonth: '3 Meses',
  year: 'Año',
  other: 'Otro',

  xDateToYDate: (xDate: string, yDate: string) => `${xDate} a ${yDate}`,
  assignUsersOrCategories: 'Asignar usuarios o categorías',
  searchUserCompanyTeamOrRole: 'Buscar Usuario, Compañía, Equipo o Rol',
  successfullyUpdatedExistingAssignments:
    'Asignaciones existentes actualizadas con éxito',
  successfullyUpdatedExistingAssignmentsByApp: (app: string) =>
    `Asignaciones existentes de ${app} actualizadas con éxito`,
  successfullyDeletedAssignments: 'Asignaciones eliminadas con éxito',
  successfullyDeletedAssignmentsByApp: (app: string) =>
    `Asignaciones de ${app} eliminadas con éxito`,
  newAnnouncement: 'Nuevo anuncio',
  editingAnnouncement: 'Editando anuncio',
  announcements: 'Anuncios',
  announcementHasBeenCreated: 'El anuncio ha sido creado',
  announcementDateArrived: 'La fecha del anuncio ha llegado',
  announcementNotFound: 'Anuncio no encontrado',

  approve_verb: 'Aprobar',
  readyToInspect: 'Listo para inspeccionar',
  toInspect: 'Para inspeccionar',
  notInUse: 'No en uso',
  everyXDaysInspection: (x: number) =>
    x > 1 ? `Inspección cada ${x} días` : 'Inspección todos los días',
  everyXWeeksInspection: (x: number) =>
    x > 1 ? `Inspección cada ${x} semanas` : 'Inspección todas las semanas',
  everyXMonthsInspection: (x: number) =>
    x > 1 ? `Inspección cada ${x} meses` : 'Inspección todos los meses',
  addInspection: 'Agregar Inspección',
  finish_noun: 'Finalizar',
  legend: 'Leyenda',
  startFinishState: 'Estado de Inicio/Finalización',
  intermediateState: 'Estado Intermedio',
  action: 'Acción',
  ballInCourtStep: 'Paso en juego',
  active: 'Activo',
  struxhubActivityX: (code: string) => `Actividad ${brandName} ${code}`,
  openActivity: 'Abrir Actividad',
  openDelivery: 'Abrir Entrega',
  loadingPresentationMode: 'Cargando Modo de Presentación',

  xOfYSelected: (x: number, y: number) =>
    `${x} de ${y} seleccionado${ending(x)}`,
  xDeliveriesShowing: (x: number) => `${x} ${deliveries(x)} mostrando`,
  assign_verb: 'Asignar',
  subscribe_verb: 'Suscribir',
  new: 'Nuevo',
  completedTotal: 'Completado/Total',
  searchByBookingIdOrSubscribers: 'Buscar por ID de Reserva o Suscriptores',
  my: 'Mis',
  me: 'Mis',
  full_period: 'Completo',
  day: 'Día',
  calendarView: 'Vista de Calendario',
  ganttView: 'Vista de Gantt',
  listView: 'Vista de Lista',
  mapView: 'Vista de Mapa',
  dur_duration: 'Dur',
  loc_locations: 'Ubi',
  equip_equipment: 'Equip',
  mat_materials: 'Mat',
  vend_vendor: 'Prov',
  a_actualFlag: 'A',
  unspecified: 'No especificado',

  yesUnsubscribe: 'Sí, darse de baja',
  deliveriesListDescriptions: {
    subscribeConfirmMassage: (
      dUnsubscribedCount: number,
      dSelectedCount: number,
    ) =>
      `Suscribirse a [${dUnsubscribedCount}] ${deliveries(
        dUnsubscribedCount,
      )} ` +
      `no suscrita${ending(dUnsubscribedCount)} ` +
      `de [${dSelectedCount}] ${deliveries(dSelectedCount)} ` +
      `seleccionada${ending(dSelectedCount)}?`,

    unsubscribeConfirmMessage: (dSelectedCount: number) =>
      `Ya está suscrito a todas las [${dSelectedCount}] ` +
      `${deliveries(dSelectedCount)} seleccionadas .\n\n` +
      '¿Quizás, desea darse de baja?',
  },
  logisticsListDescriptions: {
    subscribeConfirmMassage: (
      dUnsubscribedCount: number,
      dSelectedCount: number,
    ) =>
      `Suscribirse a [${dUnsubscribedCount}] ${workflows(
        dUnsubscribedCount,
      )} ` +
      `no suscrito${ending(dUnsubscribedCount)} de [${dSelectedCount}] ` +
      `${workflows(dSelectedCount)} seleccionado${ending(dSelectedCount)}?`,
    unsubscribeConfirmMessage: (dSelectedCount: number) =>
      `Ya está suscrito a todos los [${dSelectedCount}] ` +
      `${workflows(dSelectedCount)} seleccionados.\n\n` +
      '¿Quizás, desea darse de baja?',
    areYouWantToDelete:
      '¿Está seguro de que desea eliminar todos estos flujos de trabajo seleccionados?',
    deletionConfirmMsg: (selectedCount: number) =>
      `Seleccionaste - ${selectedCount} ${workflows(selectedCount)}`,
  },
  sitemapsDataIsLoading: 'Los datos de los mapas del sitio se están cargando',
  editingConfirmation: 'Confirmación de Edición',
  selectDataColumn: 'Seleccionar columna de datos',
  addFieldForEditing: 'Agregar campo para editar',
  deliveryBulkEditorDescriptions: {
    warningMessagePart1:
      'Ninguna de las entregas seleccionadas puede ser ' +
      'cambiada de acuerdo con los permisos de su cuenta',
    warningMessagePart2: 'Haga otra selección o contacte al administrador',
  },
  failedToEdit: 'Fallo al editar',
  editedXFieldsOnYDeliveries: (x: number, y: number) =>
    `${x} Campo${ending(x)} editado${ending(x)} en ${y} ${deliveries(y)}`,
  editXDeliveries_question: (x: number) => `¿Editar ${x} ${deliveries(x)}?`,
  onlyXOfYSelectedDeliveriesCanBeChanged: (x: number, y: number) =>
    `Solo [${x}] de [${y}] ${deliveries(y)} seleccionada${ending(x)} ` +
    `puede${ending(x, '', 'n')} ser cambiada${ending(x)}, ¿proceder?`,
  editXFieldsOnYDeliveries: (x: number, y: number) =>
    `Editar ${x} Campo${ending(x)} en ${y} ${deliveries(y)}`,
  updateXDeliveries: (x: number) => `Actualizar ${x} ${deliveries(x)}`,
  failedToUpdate: 'Fallo al actualizar',
  statusUpdated: 'Estado actualizado',
  statusUpdatedOnXDeliveries: (x: number) =>
    `Estado actualizado en ${x} ${deliveries(x)}`,
  updateDeliveryStatus: 'Actualizar estado de entrega',
  loadingDeliveriesData: 'Cargando datos de entregas',
  loadingFormsData: 'Cargando datos de formularios',
  loadingLogisticsData: 'Cargando datos de logística',
  form: 'Formulario',
  log: 'Registro',

  weekOfEndDate: 'Semana de fecha de finalización',
  companySummary: 'Resumen de la empresa',
  percentDone: '% Realizado',
  percentOnTime: '% A tiempo',
  trucks: 'Camiones',
  avgTrucksPerDelivery: 'Prom. Camiones / Entrega',
  deliveryGantt: 'Gantt de entrega',
  projectSummary: 'Resumen del proyecto',
  completionRatePercent: 'Porcentaje de finalización',
  completionRate: 'Tasa de finalización',
  onTimeRatePercent: 'Porcentaje de puntualidad',
  onTimeRate: 'Tasa de puntualidad',
  unplanned: 'No planificado',
  unplannedRate: 'Tasa no planificada',
  onTimeStart: 'Inicio puntual',
  lateOnTime: 'Atrasado/A tiempo',
  allDay: 'Todo el día',
  weekly: 'Semanal',
  monthly: 'Mensual',
  onTimeLate: 'A tiempo/Atrasado',
  earnedDuration: 'Duración ganada',
  show_verb: 'Mostrar',
  showXMore: (x: number) => `Mostrar ${x} más`,
  showActive: 'Mostrar activas',
  loadingProjectData: 'Cargando datos del proyecto',
  updateToday: 'Actualizar hoy',
  critical: 'Crítico',
  deltaDays: '∆ Días',
  sumSlip: '∑ Atraso',
  openIssues: 'Problemas abiertos',
  plannedToday: 'Planificado hoy',
  actualToday: 'Actual hoy',
  deltaManpowerToday: '∆ Man. hoy',
  deltaManpowerTrend: 'Tendencia ∆ Man.',
  soon: 'Pronto',
  numberOfPicks: 'Número de selecciones',
  repeatDelivery: 'Repetir entrega',
  every_plural: 'Cada',
  endsOn: 'Termina en',
  thisDelivery: 'Esta entrega',
  thisAndFutureDeliveries: 'Esta y futuras entregas',
  allDeliveries: 'Todas las entregas',
  everyXDays: (x: number) => (x == 1 ? 'Todos los días' : `Cada ${x} días`),
  everyXWeeks: (x: number) =>
    x == 1 ? 'Todas las semanas' : `Cada ${x} semanas`,
  everyXMonths: (x: number) => (x == 1 ? 'Todos los meses' : `Cada ${x} meses`),
  xDays: (x: number) => `día${ending(x)}`,
  xWeeks: (x: number) => `semana${ending(x)}`,
  xMonths: (x: number) => `mes${ending(x)}`,
  xMaterial: (x: number) => `Material Nº ${x}`,
  addMaterial: 'Agregar material',
  forecast: 'Pronóstico',
  multipleEntries: {
    add_x: (x: string) => `Agregar ${x}`,
    paste: 'Puede pegar múltiples entradas',
    typeOrPaste: 'Puede escribir o pegar múltiples entradas',
    repeatedValue: 'Entrada duplicada',
  },

  locationControls: 'Controles de ubicación',
  addDailyClosure: 'Agregar cierre diario',

  searchCompanyOrCompanyType: 'Buscar empresa o tipo de empresa',

  newActivity: 'Nueva actividad',
  cast: 'Hormigonado',
  castName: 'Nombre del hormigonado',
  monitoringName: 'Nombre de monitoreo',
  deviceId: 'ID del dispositivo',
  device: 'Dispositivo',
  lastMaturity: 'Última madurez',
  lastReading: 'Última lectura',
  elapsedTime: 'Tiempo transcurrido (hh:mm)',
  maxElapsedTime: 'Tiempo máximo transcurrido (min)',
  lastTemperature: (temperatureUnit: MaturixTemperatureUnit) =>
    `Última temperatura (${SpanishTree.temperatureUnit(temperatureUnit)})`,
  lastStrength: (strengthUnit: MaturixStrengthUnit) =>
    `Última resistencia (${SpanishTree.strengthUnit(strengthUnit)})`,
  temperatureWithUnit: (temperatureUnit: MaturixTemperatureUnit) =>
    `Temperatura (${SpanishTree.temperatureUnit(temperatureUnit)})`,
  strengthWithUnit: (strengthUnit: MaturixStrengthUnit) =>
    `Resistencia (${SpanishTree.strengthUnit(strengthUnit)})`,
  temperatureUnit: (temperatureUnit: MaturixTemperatureUnit): string => {
    switch (temperatureUnit) {
      case MaturixTemperatureUnit.CELCIUS:
        return 'Celsius'
      case MaturixTemperatureUnit.FAHRENHEIT:
        return 'Fahrenheit'
    }
  },
  strengthUnit: (strengthUnit: MaturixStrengthUnit): string => {
    switch (strengthUnit) {
      case MaturixStrengthUnit.KGPERCMSQ:
        return 'kg/cm²'
      case MaturixStrengthUnit.MPA:
        return 'MPa'
      case MaturixStrengthUnit.NPERMMSQ:
        return 'N/mm²'
      case MaturixStrengthUnit.PSI:
        return 'PSI'
    }
  },

  unconfirmed: 'No confirmado',
  confirmed: 'Confirmado',
  delivering: 'En entrega',
  paused: 'Pausado',
  onHold: 'En espera',

  ticketed: 'Tiquetado',
  recalled: 'Retirado',
  enRoute: 'En ruta',
  pouring: 'Vertiendo',
  delivered: 'Entregado',

  orderSummary: 'Resumen del pedido',
  payloadSummary: 'Resumen de la carga',

  orderStatus: 'Estado del pedido',
  totalVolume: 'Volumen total',
  deliveryLocation: 'Ubicación de entrega',
  mixType: 'Tipo de mezcla',
  slump: 'Asentamiento',
  spacingOrUnloadTime: 'Espaciado o tiempo de descarga',
  pouringMode: 'Modo de vertido',
  elementsToPour: 'Elementos a verter',
  extraCharge: 'Cargo extra',
  orderedBy: 'Ordenado por',

  load: 'Cargar',
  total: 'Total',
  timeOnSite: 'Tiempo en el sitio',
  ticket: 'Ticket',
  volumeLoaded: 'Volumen cargado',
  totalOrderVolume: 'Volumen total del pedido',
  additive: 'Aditivo',
  jobTimes: 'Tiempos de trabajo',
  startLoad: 'Inicio de carga',
  beginPouring: 'Comenzar a verter',
  washing: 'Lavando',
  totalTimeOnSite: 'Tiempo total en el sitio',
  inXHoursAndXMin: (hours: number, minutes: number) =>
    `En ${hours}h ${minutes}min`,
  xHoursAndXMinLate: (hours: number, minutes: number) =>
    `${hours}h ${minutes}min atrasado`,
  inXMin: (minutes: number) => `En ${minutes} min`,
  xMinLate: (minutes: number) => `${minutes} min atrasado`,
  isAboutToArrive: 'Está a punto de llegar',

  projected_time: 'Proyectado',
  recurringDelivery: 'Entrega recurrente',
  endingXDate: (date: string) => `Finalizando ${date}`,
  isQuestionMandatory: '¿Es obligatoria la pregunta?',
  remainingDuration: 'Duración restante',
  baseUrl: 'URL base',
  siteId: 'ID del sitio',
  isActive: 'Está activo',
  syncStartDate: 'Fecha de inicio de sincronización',
  notUniqueActivityCodeMessage: (code: string) =>
    `La actividad con el código [${code}] ya existe. Prueba otro valor`,
  activityWasUpdated: 'La siguiente actividad fue actualizada',
  activityUpdated: 'Actividad actualizada',
  editXFieldsOnYActivities: (x: number, y: number) =>
    `Editar ${x} Campo${ending(x)} en ${y} ${activities(y)}`,
  editedXFieldsOnYActivities: (x: number, y: number) =>
    `${x} Campo${ending(x)} editado${ending(x)} en ${y} ${activities(y)}`,
  editXActivities_question: (x: number) => `¿Editar ${x} ${activities(x)}?`,
  onlyXOfYSelectedActivitiesCanBeChanged: (x: number, y: number) =>
    `Solo [${x}] de [${y}] ${activities(y)} seleccionada${ending(y)}  ` +
    `se puede${ending(x, '', 'n')} cambiar, ¿proceder?`,
  p6Viewer: 'Visor P6',
  scanner: 'Escáner',
  scannerApp: 'Aplicación de escáner',
  selectScanner: 'Seleccionar escáner',
  createNewScanner: 'Crear nuevo escáner',
  activitySetName: 'Nombre del conjunto de actividades',
  editActivitySet: 'Editar un conjunto de actividades',
  includeXFile: (ext: string) => `Incluir un archivo .${ext}`,
  includeDetailedEachForm: 'Incluir una vista detallada de cada formulario',
  usersSubscribedTo: {
    deliveries: (uCount: number, iCount: number) =>
      `Suscrito ${uCount} ${users(uCount)} a ${iCount} ${deliveries(iCount)}`,

    activities: (uCount: number, iCount: number) =>
      `Suscrito ${uCount} ${users(uCount)} a ${iCount} ${activities(iCount)}`,
    announcements: (uCount: number, iCount: number) =>
      `Suscrito ${uCount} ${users(uCount)} a ${iCount} ${announcements(
        iCount,
      )}`,
    forms: (uCount: number, iCount: number) =>
      `Suscrito ${uCount} ${users(uCount)} a ${iCount} ${forms(iCount)}`,
    sensors: (uCount: number, iCount: number) =>
      `Suscrito ${uCount} ${users(uCount)} a ${iCount} ${sensors(iCount)}`,
    closures: (uCount: number, iCount: number) =>
      `Suscrito ${uCount} ${users(uCount)} a ${iCount} ${closures(iCount)}`,
  },
  usersUnSubscribedFrom: {
    deliveries: (uCount: number, iCount: number) =>
      `Dado de baja ${uCount} ${users(uCount)} a ${iCount} ${deliveries(
        iCount,
      )}`,
    activities: (uCount: number, iCount: number) =>
      `Dado de baja ${uCount} ${users(uCount)} a ${iCount} ${activities(
        iCount,
      )}`,
    announcements: (uCount: number, iCount: number) =>
      `Dado de baja ${uCount} ${users(uCount)} a ${iCount} ${announcements(
        iCount,
      )}`,
    forms: (uCount: number, iCount: number) =>
      `Dado de baja ${uCount} ${users(uCount)} a ${iCount} ${forms(iCount)}`,
    sensors: (uCount: number, iCount: number) =>
      `Dado de baja ${uCount} ${users(uCount)} a ${iCount} ${sensors(iCount)}`,
    closures: (uCount: number, iCount: number) =>
      `Dado de baja ${uCount} ${users(uCount)} a ${iCount} ${closures(iCount)}`,
  },
  scannerName: 'Nombre del escáner',
  scanMaster: 'Maestro de escaneo',
  scanStationReport: 'Informe de la estación de escaneo',
  materialTrackingReport: 'Informe de seguimiento de material',
  truckSize: 'Tamaño del camión',
  cancelationReason: 'Razón de cancelación',
  responsibleCompanies: 'Empresas responsables',
  noDataAvailable: 'Datos no disponibles',
  last7Days: 'Los últimos 7 días',
  last30Days: 'últimos 30 días',
  last120Days: 'Últimos 120 días',
  last365Days: 'Últimos 365 días',
  entireProject: 'Proyecto entero',
  frequencyAndPeriod: 'Frecuencia y periodo',
  activityDataLinking: {
    disableLinkingQuestion: (field: string) =>
      `Se deshabilitará el enlace a '${field}' externo. ¿Desea continuar?`,
    enableLinkingQuestion: (fieldValue: string, externalFieldValue: string) =>
      `¿Desea vincular '${fieldValue}' a '${externalFieldValue}' externo? Esto puede afectar a otras actividades.`,
    linkingIsDisabled: 'La vinculación de datos está desactivada',
    linkingIsEnabled: 'La vinculación de datos está activada',
    linkingDisabled: `Enlace de datos desactivado`,
    linkingEnabled: `Vinculación de datos activada`,
    editWarning: `Los cambios en la vinculación podrían afectar a otras actividades`,
    bulkEditWarning: `La vinculación de datos se desactivará para los campos editables`,
  },

  materialsUpload: 'Carga de materiales',
  plannedQuantity: 'Cantidad planificada',

  materialsUploading: {
    uploadMaterials: 'Subir materiales',
    goToTheMaterialsPage: 'Ir a la página de Materiales',
    uploadYourMaterialsFile:
      'Cargue su archivo de Materiales en formato Excel (.xlsx, .xlsm).',
    thereAreNoSheetsError:
      'No hay hojas en el archivo Excel proporcionado o se produjo un error al leer el archivo',
    materialCategoryLvl1: 'Categoría de material (Nivel 1)',
    materialLvl2: 'Materiales (Nivel 2)',
    materialDescription: 'Descripción del material',
    materialPlannedQuantity: 'Cantidad planificada de material',
    startingTheUpload: 'Iniciando la descarga',
    readingMaterialsFile: 'Leyendo el archivo de materiales',
    extractingMaterialsData: 'Extracción de materiales de datos',
    removingOldData: 'Eliminar datos antiguos',
    savingMaterialsData: 'Guardar datos de materiales',
    finishingUpload: 'Finalizando la carga',
    uploadOfMaterialsFailedAt: 'La carga de material ha fallado',
    uploadWasSuccessful: 'La carga fue exitosa',
    pleaseSelectSheetToUpload: (isMultiple: boolean) =>
      `${
        isMultiple ? 'El archivo actual contiene varias hojas. ' : ''
      }Seleccione una hoja del archivo para importar.`,
    makeSureLocationNamesUnique:
      'Asegúrese de que los nombres de las ubicaciones sean únicos en StruxHub',
  },

  materialsUploadHistory: {
    currentMaterials: 'Materiales actuales',
    projectUsesDefaultMaterials: 'El proyecto utiliza materiales estándar',
    latestUpload: 'Última carga',
    materialsRestoringConfirmation:
      'Confirmación de restauración de materiales',
    totalMaterialCategoriesAndMaterials:
      'Material total y categorías de materiales',
    restoreDefaultMaterials: 'Restaurar materiales predeterminados',
    doYouConfirmRestoreToDefaults:
      '¿Confirmar la restauración a los materiales predeterminados?',
    restoredSuccessfully: 'Restaurado con éxito',
    failedToRestore: 'No se pudo restaurar',
  },

  materialsUploadConfirm: {
    confirmDataMerge: 'Confirmar unión de datos',
    confirmDescription1: `Al cargar este archivo se actualizarán todos los valores modificados para
      los materiales existentes y se crearán nuevos registros para los materiales nuevos.`,
    confirmDescription2:
      'Si desea eliminar todos los datos de material existentes y reemplazarlos con datos de archivo,',
    confirmDescription3: 'haga clic aquí',
    replaceDescription: `¿Realmente desea eliminar todos los datos
      del material existente y reemplazarlos con datos del archivo cargado?`,
  },

  collapseAll: 'Colapsar todo',
  expandAll: 'Expandir todo',

  myUserProfile: 'Somos señor perfil',
  addTeammates: 'Añadir compañeros',
  addSummaryColumn: 'Agregar columna de resumen',
  configureColumns: 'Configurar columnas',
  summaryColumns: 'Columnas de resumen',
  receipt: 'Recibo',
  summary: 'Resumen',

  remainingQuantity: 'Cantidad restante',

  materialsInDeliveryFormWarning: {
    onlyXMaterialsForForm: (x: number) =>
      `Actualmente solo están habilitados ${x} material${ending(
        x,
        '',
        'es',
      )} para un Formulario de Entrega. Si esto es una limitación, comuníquese con`,
    andWeCanTalk: 'y nosotras podemos hablar',
  },

  deliverySuccessfullyCreated: 'Entrega creada exitosamente',

  xDates,

  materialDescription: 'Descripción del material',
  thisGroupAlreadyExists: 'Este grupo ya existe',

  upload: 'Subir',
  totalBooked: 'Total reservado',
  totalDelivered: 'Total entregado',
  doneDeliveries: 'Entregas finalizadas',

  xVendors: (x: number) => `${x} Proveedor${ending(x, '', 'es')}`,
  thisWeek: 'Esta semana',
  thisMonth: 'Este mes',

  youCannotChangeDelivery:
    'No puedes cambiar la entrega mientras esté en este estado',
  plannedInstallLocation: 'Ubicación de instalación planificada',
  plannedDeliveryLocation: 'Lugar de entrega planificado',
  currentLocation: 'Ubicación actual',
  procurementID: 'ID de adquisición',
  id_short: 'ID',
  readyToInstallQuantity: 'Listo para instalar cantidad',
  totalOnSiteQuantity: 'Cantidad total en el sitio',
  toMoveQuantity: 'Para mover cantidad',

  formMaterialSubFields: {
    materialHasQuantityAndLocation:
      'El material tiene campos Cantidad y Ubicación que también puede agregar/editar a continuación.',
    clickToEnableQuantity: 'Haga clic aquí si desea habilitar Cantidad.',
    clickToEnableLocation: 'Haga clic aquí si desea habilitar la Ubicación.',
  },
  newFormType: 'Nuevo tipo de Formulario',
  materialTransferId: 'ID de transferencia de material',
  materialTransferDate: 'Fecha de transferencia de material',
  newTransfer: 'Nueva transferencia',
  doneTransfers: 'Transferencias hechas',
  totalTransferred: 'Total transferido',
  xTransfers: (x: number) => `${x} Transfer${ending(x, 'ir', 'encias')}`,

  youCanSelectMultipleOptions: 'Puedes seleccionar múltiples opciones',
  updateStatus: 'Estado de actualización',

  review_verb: 'Revisar',
  reviewed: 'Revisado',
  bicInspected: 'BIC inspeccionado',
  activated: 'Activado',
  finished: 'Finalizado',
  submitted: 'Enviado',
  passAndClose: 'Pasar y cerrar',
  formIsDoneOrDenied: (isDenied: boolean) =>
    `El formulario ${isDenied ? 'es negado' : 'está hecho'}`,
  stopped: 'Interrumpida',
  request_noun: 'Solicitud',
  review_noun: 'Revisar',
  bicInspection: 'BIC Inspección',
  recurringInspection: 'Inspección recurrente',
  notifyUserIfWorkflowHasTags:
    'Notificar al usuario si el flujo de trabajo tiene las siguientes etiquetas',
  thisWorkflowStepCanBePerformed:
    'Este paso del flujo de trabajo lo pueden realizar los siguientes usuarios',
  xRules: (x: number) => `${x} regla${ending(x)}`,
  step: 'Paso',
  bicUser: 'BIC usuario',
  formSection: 'Sección de formulario',
  actions: 'Acciones',
  frequency: 'Frecuencia',
  deadline: 'Fecha límite',
  automaticStart: 'Inicio automático',
  addStep: 'Agregar paso',

  workflowConfDescr: {
    mustContainApproval:
      'El flujo de trabajo con un paso de Solicitud debe contener un paso de Aprobación',
    cannotBeTheLastStep: (stepName: string) =>
      `${stepName} no puede ser el último paso, agregue otro o elimine este.`,
    workflowStartsOnDate:
      'El flujo de trabajo comienza automáticamente en la fecha de inicio.',
    automaticallyStart:
      'Iniciar automáticamente el flujo de trabajo en la fecha de inicio',
    replaceWithStep: (stepType: string): string =>
      `Reemplace con el paso ${stepType}`,
    replaceWithStart: 'Reemplace con el paso Iniciar',
    deleteWorkflowStep: 'Eliminar paso del flujo de trabajo',
    removeStepDialogMsg: '¿Quieres eliminar este paso del flujo de trabajo?',
    toastUnsavedMsg: 'Los cambios realizados se han restablecido',
    recurringHasConstr: 'El paso de inspección recurrente tiene limitaciones:',
    toggleDialogMsg: (isManualStart: boolean): string =>
      `¿Cambiarlo a un paso de inicio ${
        isManualStart ? 'Manual' : 'Automático'
      }?`,
    selectStepMsg:
      'Seleccione un paso del flujo de trabajo para agregar/modificar campos',
  },
  workflowConfTooltips: {
    bicBlockFirstLine: `Se pueden asignar uno o más usuarios a los pasos del flujo de trabajo de Pelota en la cancha.`,
    bicBlockSecondLine: `Si desea asignar estos usuarios a ámbitos
    de trabajo específicos, defina una regla basada en el conjunto de etiquetas deseado.`,
  },
  workflowTableText: {
    seeDescr: 'Ver descripciones de los pasos',
    stepCategory: 'Categoría de paso del flujo de trabajo',
    workflowStep: 'Paso del flujo de trabajo',
    constraints: 'Restricciones',
    bicText: '¿Se puede especificar el usuario de la pelota en la cancha?',
    multipleText: '¿Se pueden tener varias instancias en el flujo de trabajo?',
    submissionDescr:
      'Iniciando el paso del flujo de trabajo para enviar un formulario que no requiere aprobación posterior.',
    submissionConstr: 'Solo puede iniciar un flujo de trabajo.',
    requestDescr:
      'Iniciando paso del flujo de trabajo para solicitar una aprobación.',
    requestConstr:
      'Solo puede iniciar un flujo de trabajo. Debe tener un paso de Aprobación posterior.',
    reviewDescr:
      'Paso de avance general de un flujo de trabajo que se puede utilizar para aprobaciones previas.',
    approvalDescr:
      'Aprobación de una solicitud de flujo de trabajo que se puede asignar a usuarios específicos.',
    approvalConstr: 'Debe tener un paso de Solicitud anterior.',
    startDescr:
      'Inicio de un flujo de trabajo iniciado al hacer clic en el botón «Iniciar».',
    autoStartDescr:
      'Inicio de un flujo de trabajo que se inicia automáticamente en la fecha de inicio del flujo de trabajo.',
    onSiteDescr:
      'Se utiliza para indicar la llegada de un vehículo de reparto a la ubicación.',
    readyToInspectDescr:
      'Paso intermedio del flujo de trabajo utilizado para notificar a los inspectores.',
    inspectionDescr: 'Paso de inspección general.',
    bicInspectionDescr:
      'Paso de inspección que se puede asignar a un usuario específico.',
    recurringDescr: 'Inspecciones recurrentes con una frecuencia específica.',
    recurringConstr:
      'Debe estar precedido directamente por un paso de Inicio o de Inicio automático.',
    finishDescr:
      'Finalización de un flujo de trabajo marcado al hacer clic en el botón «Listo».',
  },
  workflowSteps: 'Pasos del flujo de trabajo',
  workflowDiagram: 'Diagrama de flujo de trabajo',
  workflowStepConfigurator: 'Configurador de pasos del flujo de trabajo',
  restoreToLastSavedState: 'Restaurar al último estado guardado',
  endOfTheDay: 'Fin del día',
  everyX_withoutNum: (x: number) => `Cada${ending(x, '', '')}`,
  submission: 'Envío',
  initiation: 'Iniciación',

  deleteForm: 'Eliminar formulario',
  formDeletionDialogDescr: {
    confirmDelete: `Al eliminar este formulario, se eliminarán
    el formulario y todas las notas y datos asociados del proyecto.`,
    confirmDelete2:
      'Haga esto sólo si la solicitud de formulario fue un error.',
  },
  emailPdfReport: 'Informe PDF por correo electrónico',
  unsubscribe: 'Cancelar suscripción',
  followFormMenuDescr: {
    toReceive: 'Para recibir notificaciones de flujo de trabajo',
    toStop: 'Para dejar de recibir notificaciones de flujo de trabajo',
  },
  formDeleteMenuDescr: {
    isDone: 'El formulario está listo, no puedes eliminarlo.',
    onlyFor: `Solo disponible para administradores,
      maestros de formularios, solicitantes, asignados`,
  },
  configureTableColumns: 'Configurar columnas de tabla',
  searchProperties: 'Buscar propiedades',
  shownInTable: 'Se muestra en la tabla',
  hiddenInTable: 'Escondido en la tabla',
  shownInView: 'A la vista',
  hiddenInView: 'Oculto a la vista',
  freezeColumns: 'Congelar columnas arriba',
  restoreDefaults: 'Restaurar los valores predeterminados',
  announcementFollowed: 'Anuncio seguido',
  successfullyDeletedForms: (x: number) =>
    `Se eliminaron con éxito ${x} ${forms(x)}`,
  failedToDelete: 'No se pudo eliminar',
  cannotDeleteDoneForms: 'No se pueden eliminar los formularios listos',
  getMobileNativeApp: 'Descarga la app',
  getMobileNativeApp_long: 'Descarga la aplicación móvil de StruxHub',
  getAppOnGoogle: 'Disponible en Google Play',
  getAppOnApple: 'Descárgalo en el App Store',
  oneTimePopup: {
    title: '¡Descarga nuestra nueva aplicación móvil!',
    body: 'Recibe actualizaciones en tiempo real y realiza más trabajo en el campo con StruxHub.',
    dismiss: '¡Ok, entendido!',
  },

  exitScanner: 'Salir del escáner',
  deleteScanner: 'Eliminar escáner',
  editScannerText: 'Editar escáner',
  shouldDeleteScanner: 'Debe eliminar el escáner',
  startScanner: 'Activar escáner',
  queue: 'Cola',
  startQueue: 'Iniciar La Cola',
  addedToQueue: 'Agregado A La Cola',
  startSession: 'Iniciar Sesión',
  allowedUsers: 'Usuarios permitidos',
  badgeDescription: 'Descripción de la insignia',
  makeItTimed:
    "Haga de este un escáner 'Cronometrado' para los usuarios. " +
    'Scan-Start : Scan-Stop = Total Time',
  limitAccess:
    "Limitar el acceso a usuarios, equipos o empresas con 'Insignias' específicas",
  endRideConfirm: '¿Estás seguro de que quieres terminar el viaje?',
  changeModeConfirm:
    '¿Estás seguro de que quieres cambiar el modo de conducción?',
  startSessionConfirm: '¿Estás seguro de que quieres comenzar la sesión?',
  isScannerTimedByDay:
    'El temporizador FINALIZA automáticamente al finalizar la jornada laboral.',
  endRide: 'Fin del viaje',
  scanTo: 'Escanear a',
  activeUsers: 'Usuarios activos:',
  scannerActive: 'Escáner activo',
  scannerInactive: 'Escáner inactivo',
  pauseScanner: 'Pausa el escáner',
  resumeScanner: 'Reanudar escáner',
  endSessionForAll: 'Fin de sesión para todos',
  workflowCategory: 'Categoría de flujo de trabajo',
  workflowName: 'Nombre del flujo de trabajo',
  undo: 'Deshacer',
  redo: 'Rehacer',
  icon: 'Icono',
  showInApp: 'Mostrar en la Aplicación',
  blockRequestsForNonWorkTimes: 'Bloquear solicitudes de tiempo no laboral',
  general: 'General',
  steps: 'Pasos',
  views: 'Vistas',
  confirmation: 'Confirmación',
  deletionOfDefaultWorkflows:
    'Se prohíbe la eliminación de flujos de trabajo predeterminados o materiales',
  theFollowingEntitiesWereUpdated: 'Se actualizaron las siguientes entidades',
  globeViews: 'Vistas Globo',
  whiteboards: 'Pizarras',
  available: 'Disponible',
  useMapLabels: 'Utilizar etiquetas de mapa',
  notifiedUsers: 'Usuarios notificados',
  selectNotificationUsers:
    'Seleccione los usuarios que recibirán una notificación después de completar este paso',
  wouldYouEnableNotificationsForSteps:
    '¿Le gustaría habilitar las notificaciones para este campo en todos los pasos del flujo de trabajo?',
}

export default SpanishTree
